import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import InputGeneral from "../../components/InputText";
import DefaultButton from "../Button/DefaultButton";

export function LoginBox({ onLoginPressed }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onKeyUp = (event) => {
    event.nativeEvent.keyCode == 13 && onLoginPressed({ username, password });
  };

  const { t } = useTranslation();

  return (
    <div
      className="h-screen"
      style={{
        backgroundImage: "url('/bg.jpg')",
        backgroundSize: "cover",
        paddingTop: "25vh",
      }}
    >
      <div className="p-6 flex justify-center w-full" id="login-name">
        <InputGeneral
          onChange={setUsername}
          onKeyUp={onKeyUp}
          value={username}
          placeholder={t("loginName")}
          className="w-2/3 md:w-1/4 text-gray relative bg-lightGray text-sm border-none "
        />
      </div>
      <div className="p-6 flex justify-center w-full" id="login-password">
        <InputGeneral
          onChange={setPassword}
          onKeyUp={onKeyUp}
          value={password}
          type="password"
          placeholder={t("loginPassword")}
          className="w-2/3 md:w-1/4 text-gray relative bg-lightGray text-sm border-none "
        />
      </div>
      <div className="p-6 flex justify-center w-full">
        <div className="w-2/3 md:w-1/4 cursor-pointer">
          <DefaultButton
            text={t("loginButton")}
            onButtonClicked={() => {
              onLoginPressed({
                username,
                password,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}

import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tabbar from "../../components/Header/Tabbar";
import AddButton from "../../components/Button/AddButton";
import { React, useEffect, useState } from "react";
import { getCollection } from "../../services/CollectionService";
import { WhitePage } from "../../components/Page/WhitePage";
import { ListRow } from "../../components/List/ListRow";
import { ListRowMenu } from "../../components/List/ListRowMenu";
import { List } from "../../components/List/List";
import {
  addCollectible,
  deleteCollectible,
  saveCollectible,
  moveOrCopyCollectible,
} from "../../services/CollectibleService";
import { REACT_APP_API_SERVER } from "../../services/APIService";
import { sortData } from "../../utils/sort";
import InputCheckBox from "../../components/InputCheckBox";
import UploadForm from "../../components/Form/UploadForm";
import CopyMoveCollectibleForm from "../../components/Form/CopyMoveCollectibleForm";
import Modal from "../../components/Modal/Modal";
import { ImEye, ImEyeBlocked } from "react-icons/im";
import LazyLoad from "react-lazyload";
import log from "../../utils/Logger";
import { WorksExhibitionImpressum } from "../../utils/Tabs";
import ClickableMedia from "../../components/Image/ClickableMedia";
import { ModalErrorAlertInfo } from "../../components/Modal/ModalContent";

export default function Artworks({
  loggedInUser,
  translationInfo,
  setTranslationInfo,
}) {
  const { t } = useTranslation();
  let { id } = useParams();
  let navigate = useNavigate();
  const [collection, setCollection] = useState(undefined);
  const [menuOpen, setMenuOpen] = useState(undefined);
  const [accountAccess, setAccountAccess] = useState(undefined);
  const [usedCollectibles, setUsedCollectibles] = useState(undefined);
  const [changesSaved, setChangesSaved] = useState(undefined);
  const [openAddModal, setOpenAddModal] = useState(undefined);
  const [openModal, setOpenModal] = useState(undefined);
  const [message, setMessage] = useState(false);
  const [accept, setAccept] = useState(undefined);

  // 0 = closed, 1 = copy, 2 = move
  const [copyCollectibleOpen, setCopyCollectibleOpen] = useState(undefined);
  const [selectedCollectible, setSelectedCollectible] = useState(undefined);

  const [items, setItems] = useState([]);
  const [sortListBy, setSortListBy] = useState(0);
  const [sortListByDir, setSortListByDir] = useState(1);
  const [result, setResult] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  function updateArtworks(index, fieldName, value) {
    const newArtworks = [...items];
    newArtworks[index][fieldName] = value;
    setItems(newArtworks);
  }

  const onSave = async (selectedFiles) => {
    setUploading(true);
    const formData = new FormData();
    selectedFiles.dataColumnMap &&
      selectedFiles.dataColumnMap.forEach((d) => {
        formData.append("dataColumnMap[]", parseInt(d));
      });
    log.debug(
      "selectedFiles",
      selectedFiles.selectedFiles,
      selectedFiles.selectedInfoFile
    );
    selectedFiles &&
      selectedFiles.selectedFiles &&
      selectedFiles.selectedFiles.forEach((file) => {
        formData.append("images", file);
      });
    log.debug(
      "formdata collectibles and infofile",
      formData,
      selectedFiles.selectedFiles
    );
    selectedFiles.selectedInfoFile &&
      selectedFiles.selectedInfoFile.forEach((file) => {
        formData.append("infofile", file);
      });

    try {
      const res = await addCollectible(id, formData);
      log.debug("this is the result from the upload", res);
      setResult(res);
      setChangesSaved("");
    } catch (error) {
      setChangesSaved(
        "Nicht alle Daten konnten erfolgreich verarbeitet werden."
      );
      log.error("addCollectible error: ", error);
    }
    loadData && loadData();
    setUploading(false);
  };

  const columnsOfDataToShow = [
    "thumbnailMedia",
    "title",
    "date",
    "artist",
    "arOverlay",
    "isActive",
  ];

  const modules = loggedInUser.user.user.customerModules;
  const columnWidths = !modules.includes("VR")
    ? [2, 3, 2, 2, 1, 1]
    : [2, 4, 2, 1, 1];
  const headerNames = !modules.includes("VR")
    ? [
        t("artworksWork"),
        t("artworksTitle"),
        t("artworksYear"),
        t("artworksArtist"),
        "Overlays",
        t("artworksActive"),
        "",
      ]
    : [
        t("artworksWork"),
        t("artworksTitle"),
        t("artworksYear"),
        t("artworksArtist"),
        t("artworksActive"),
        "",
      ];
  const visibility = !modules.includes("VR")
    ? ["visible", "visible", "invisible", "invisible", "visible", "visible"]
    : ["visible", "visible", "invisible", "visible", "visible", "visible"];

  const updateCheckbox = async (event, index, collectible) => {
    updateArtworks(index, [columnsOfDataToShow[5]], event.target.checked);
    try {
      await saveCollectible(collection.id, collectible.id, {
        ...collectible,
        [columnsOfDataToShow[5]]: event.target.checked,
      });
      setChangesSaved("");
    } catch (error) {
      setChangesSaved("Speichern fehlgeschlagen");
      log.error("saveCollectible active checkbox error: ", error);
    }
  };

  function OverlaysOverview({ collectible }) {
    return !modules.includes("VR") ? (
      <div
        key={collectible.id}
        className="cursor-pointer text-freisicht transition transform hover:scale-105 hover:text-darkGreen"
        title="Anzahl aktive und inaktive Overlays"
        onClick={async (event) => {
          event.stopPropagation();
          navigate(
            "/artWorkDetail/" +
              collection.id +
              "/" +
              collectible.id +
              "/overlay"
          );
        }}
      >
        <div className="text-sm ">
          <ImEye className="inline"></ImEye>
          {" " +
            collectible[columnsOfDataToShow[4]].filter(
              (x) => x.isActive[translationInfo.collectionLanguage]
            ).length}
        </div>
        <div className="text-sm ">
          <ImEyeBlocked className="inline"></ImEyeBlocked>
          {" " +
            collectible[columnsOfDataToShow[4]].filter(
              (x) => !x.isActive[translationInfo.collectionLanguage]
            ).length}
        </div>
      </div>
    ) : (
      ""
    );
  }

  const makeElementsFromData = (data, collection) => {
    return data.map((collectible, index) => {
      return [
        "/artWorkDetail/" + collection.id + "/" + collectible.id + "/details",
        <div key={collectible.id}>
          <LazyLoad>
            {collectible?.[columnsOfDataToShow[0]] ? (
              <ClickableMedia
                menuOpen={openModal === collectible.id}
                setMenuOpen={(d) => {
                  setOpenModal(d && collectible.id);
                }}
                mediaSource={
                  REACT_APP_API_SERVER +
                  (collectible &&
                    collectible[columnsOfDataToShow[0]] &&
                    collectible[columnsOfDataToShow[0]].url)
                }
                className=""
                imgClassName="max-h-20 max-w-full -m-2 h-auto w-auto"
              ></ClickableMedia>
            ) : (
              ""
            )}
          </LazyLoad>
        </div>,
        <div key={collectible.id}>
          <div className="text-sm">
            {
              collectible[columnsOfDataToShow[1]][
                translationInfo.collectionLanguage
              ]
            }
          </div>{" "}
        </div>,
        <div key={collectible.id}>
          <div className="text-sm">
            {
              collectible[columnsOfDataToShow[2]][
                translationInfo.collectionLanguage
              ]
            }
          </div>
        </div>,
        <div key={collectible.id}>
          <div className="text-sm">
            {" "}
            {
              collectible[columnsOfDataToShow[3]][
                translationInfo.collectionLanguage
              ]
            }
          </div>
        </div>,
        <OverlaysOverview
          key={collectible.id}
          collectible={collectible}
        ></OverlaysOverview>,
        <div key={collectible.id}>
          <InputCheckBox
            checkedValue={collectible[columnsOfDataToShow[5]]}
            hasWriteaccess={accountAccess > 1}
            clicked={async (event) => {
              updateCheckbox(event, index, collectible);
            }}
          />
        </div>,
        <div key={collectible.id}>
          {accountAccess > 1 ? (
            <ListRowMenu
              data={[
                {
                  title: t("artworksCopy"),
                  onClick: async () => {
                    setSelectedCollectible(collectible.id);
                    log.debug("copy here");
                    setCopyCollectibleOpen(1);
                  },
                },
                {
                  title: t("artworksMove"),
                  onClick: async () => {
                    setSelectedCollectible(collectible.id);
                    log.debug("copy here");
                    setCopyCollectibleOpen(2);
                  },
                },
                {
                  title: t("artworksDelete"),
                  onClick: async () => {
                    event.stopPropagation();
                    if (usedCollectibles.includes(collectible.id)) {
                      setMessage(t("artworksDeleteNotPossible"));
                      setAccept(undefined);
                    } else {
                      setMessage(t("artworksDeleteConfirm"));
                      setAccept(collectible.id);
                    }
                  },
                },
              ]}
              isOpen={menuOpen === collectible.id}
              setIsOpen={(close) => {
                setMenuOpen(close && collectible.id);
              }}
              classAddon=" -left-28"
            ></ListRowMenu>
          ) : (
            ""
          )}
        </div>,
      ];
    });
  };

  async function loadData() {
    setLoading(true);
    const res = await getCollection(id);
    log.debug("res status,", res);
    setCollection(res.collection);
    setAccountAccess(res.accountAccess);
    setUsedCollectibles(res.collectiblesUsedInExhibitions);
    let multilangField =
      sortListBy &&
      translationInfo &&
      res.collection.collectible &&
      columnsOfDataToShow[sortListBy] &&
      res.collection.collectible?.[columnsOfDataToShow[sortListBy]]?.[
        translationInfo?.collectionLanguage
      ]
        ? translationInfo.collectionLanguage
        : null;
    setItems(
      sortData(
        res.collection.collectible,
        sortListBy && columnsOfDataToShow[sortListBy],
        sortListByDir,
        multilangField
      )
    );
    const languagesToShow = translationInfo.languagesAvailable?.filter(
      (l) => res.collection.name[l.code]
    );
    setTranslationInfo({
      ...translationInfo,
      languagesToShow,
    });
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);
  // console.log("getCollection Artworks", collection);

  const displayItems =
    (collection && makeElementsFromData(items, collection)) || [];

  const subMenu = (
    <Tabbar
      onFilteredElements={(elements) => {
        // console.log("setting new filtered elements", elements);
        log.debug("elements ", elements);
        let multilangField =
          sortListBy &&
          translationInfo &&
          elements.collectible &&
          columnsOfDataToShow[sortListBy] &&
          elements.collectible[columnsOfDataToShow[sortListBy]][
            translationInfo.collectionLanguage
          ]
            ? translationInfo.collectionLanguage
            : null;
        setItems(
          sortData(
            elements,
            sortListBy && columnsOfDataToShow[sortListBy],
            sortListByDir,
            multilangField
          )
        );
      }}
      elementsToFilter={(collection && collection.collectible) || []}
      locationToGo="/projects"
      showSearch={true}
      hiddenOnMobile={true}
      items={WorksExhibitionImpressum(modules)}
      showMultilang={modules.includes("MULTILANG")}
      translationInfo={translationInfo}
      setTranslationInfo={setTranslationInfo}
    ></Tabbar>
  );

  const moveOrCopyCollectibleLocal = async (destination, move) => {
    setLoading(true);
    const res = await moveOrCopyCollectible(
      collection.id,
      selectedCollectible,
      destination,
      move
    );
    await loadData();
    setLoading(false);
    return res;
  };

  const copyModal = copyCollectibleOpen > 0 && (
    <Modal
      onClose={() => {
        setCopyCollectibleOpen(0);
        setSelectedCollectible(undefined);
      }}
    >
      <CopyMoveCollectibleForm
        move={copyCollectibleOpen == 2}
        moveOrCopyCollectible={moveOrCopyCollectibleLocal}
        loading={loading}
        customerDefaultLanguage={
          loggedInUser && loggedInUser.user.user.customerDefaultLanguage
        }
      ></CopyMoveCollectibleForm>
    </Modal>
  );

  return (
    <WhitePage
      subMenu={subMenu}
      title={
        collection?.name?.[translationInfo.collectionLanguage]
          ? t("artworksProjectname") +
            collection?.name?.[translationInfo.collectionLanguage]
          : "Ohne Namen"
      }
      user={loggedInUser && loggedInUser.user.user}
      changesSaved={changesSaved}
    >
      <div className="flex flex-row relative">
        {accountAccess > 1 ? (
          <div>
            <AddButton
              buttonId="add-artwork"
              menuOpen={openAddModal}
              setMenuOpen={(d) => {
                setOpenAddModal(d);
                setResult(undefined);
              }}
            >
              <UploadForm
                collectionId={id}
                onSave={(toSave) => {
                  onSave(toSave);
                }}
                result={result}
                loading={uploading}
                customerModules={modules}
              ></UploadForm>
            </AddButton>

            {copyModal}
          </div>
        ) : (
          ""
        )}
      </div>
      <List
        noSortColumns={[]}
        items={displayItems}
        itemTagClass={ListRow}
        columnWidths={columnWidths}
        visibility={visibility}
        headerNames={headerNames}
        sortBy={sortListBy}
        sortByDir={sortListByDir}
        setSortBy={(sortByThis) => {
          let newSortByThis = sortListByDir;
          if (sortByThis === sortListBy) {
            newSortByThis = sortListByDir > 0 ? -1 : 1;
            setSortListByDir(newSortByThis);
          }
          setSortListBy(sortByThis);
          log.debug("items ", items);
          let multilangField =
            sortByThis &&
            translationInfo &&
            items &&
            columnsOfDataToShow[sortByThis] &&
            items[0][columnsOfDataToShow[sortByThis]][
              translationInfo.collectionLanguage
            ]
              ? translationInfo.collectionLanguage
              : null;
          setItems(
            sortData(
              items,
              sortByThis !== undefined && columnsOfDataToShow[sortByThis],
              newSortByThis,
              multilangField
            )
          );
        }}
        noItemsMessage={loading ? t("loadingData") : t("artworksNone")}
      />
      {message && (
        <ModalErrorAlertInfo
          message={message}
          setMessage={setMessage}
          acceptData={accept}
          setAcceptData={setAccept}
          acceptFunction={async () => {
            setMessage(undefined);
            await deleteCollectible(collection.id, accept);
            setAccept(undefined);
            loadData();
          }}
        ></ModalErrorAlertInfo>
      )}
    </WhitePage>
  );
}

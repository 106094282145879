import { React } from "react";

export default function Footer() {
  return (
    <>
      <div className="absolute bottom-0 text-center text-xs w-full p-4 ">
        © Copyright 2023{" "}
        <a href="https://freisicht.ch/" target="_blank" rel="noreferrer">
          freisicht GmbH
        </a>
      </div>
    </>
  );
}

import { t } from "i18next";
import { React, useState } from "react";
import Modal from "../Modal/Modal";
import DefaultButton from "../Button/DefaultButton";
import FileUpload from "../Modal/FileUpload";
import log from "../../utils/Logger";
import Loaderfreisicht from "../../utils/Loaderfreisicht";
import ModalImage from "../Modal/Modalmage";
import ModalContent, { ModalSubTitle, ModalTitle } from "../Modal/ModalContent";
import { acceptImgTrigger } from "../../utils/Fileformats";
import InputMultiline from "../../components/InputMultiline";

export default function TourInfo({
  menuOpen,
  setMenuOpen,
  className,
  classNameModal,
  collection,
  updateCollection,
  loading = false,
  collectionLanguage = "de",
  languageColorClass,
}) {
  const [description, setDescription] = useState(collection.description);
  const [selectedFiles, setSelectedFiles] = useState(undefined);

  const onButtonClicked = async () => {
    const formData = new FormData();
    collection.description = description ? description : collection.description;
    const previewImage =
      selectedFiles && selectedFiles.length > 0 ? selectedFiles[0] : null;

    formData.append("name", collection.name);

    if (previewImage) {
      formData.append("previewImage", previewImage);
    }

    for (let languageKey in collection.description) {
      if (collection.description[languageKey] != null) {
        formData.append(
          `description[${languageKey}]`,
          collection.description[languageKey]
        );
      }
    }

    formData.append(
      "description",
      collection.description ? collection.description : ""
    );

    updateCollection(collection.id, formData);
  };

  return (
    <div className={className}>
      {menuOpen && (
        <Modal
          onClose={() => {
            log.debug("close modal called");
            setMenuOpen(undefined);
            setDescription(undefined);
            setSelectedFiles(undefined);
          }}
          className={classNameModal}
        >
          <ModalContent>
            <ModalTitle title="Tour Info" />
            {collection && collection.id ? (
              <>
                <ModalSubTitle subtitle={t("tourDescription")} />
                <InputMultiline
                  value={
                    description && description[collectionLanguage]
                      ? description[collectionLanguage]
                      : ""
                  }
                  classNameAddon={"col-span-6 " + languageColorClass}
                  onChange={(event) => {
                    setDescription({
                      ...description,
                      [collectionLanguage]: event,
                    });
                  }}
                  maxlength={2550}
                />
                <ModalSubTitle subtitle={t("tourImage")} />
                <ModalImage
                  media={collection.imageMedia || collection.previewMedia}
                />
              </>
            ) : (
              ""
            )}
            <FileUpload
              filesToAccept={acceptImgTrigger()}
              saveFunction={setSelectedFiles}
              maxFiles={1}
            ></FileUpload>
          </ModalContent>

          {loading ? (
            <Loaderfreisicht></Loaderfreisicht>
          ) : (
            <DefaultButton
              text={t("tourConfirm")}
              onButtonClicked={onButtonClicked}
            ></DefaultButton>
          )}
        </Modal>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { List } from "../../components/List/List";
import { ListRow } from "../../components/List/ListRow";
import log from "../../utils/Logger";

export default function AnalyticsTourDetails({ items }) {
  const [displayItems, setDisplayItems] = useState([]);
  const [sortListBy, setSortListBy] = useState(0);
  const [sortListByDir, setSortListByDir] = useState(1);

  const columnWidths = [4, 3, 2, 1];

  async function loadData() {
    try {
      setDisplayItems(makeElementsFromData(items));
    } catch (error) {
      log.debug(error);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  const makeElementsFromData = (data) => {
    log.debug("data from tour details", data);
    let triggerInfo = [];
    data.map((triggerData) => {
      log.debug("triggerdata ", triggerData);
      if (triggerData.analyticsArTrigger.length > 0) {
        triggerData.analyticsArTrigger.map((singleTriggerInfo) => {
          log.debug("single trigger ", singleTriggerInfo);
          if (!triggerInfo[singleTriggerInfo.collectibleId]) {
            triggerInfo[singleTriggerInfo.collectibleId] = [];
          }
          triggerInfo[singleTriggerInfo.collectibleId].push(singleTriggerInfo);
        });
      }
    });
    log.debug("triggerInfo", triggerInfo);
    return triggerInfo.length > 0
      ? triggerInfo.map((triggerData) => {
          let medianDuration =
            triggerData && triggerData.length > 0
              ? triggerData.sort(
                  (a, b) => a.triggerDuration - b.triggerDuration
                )[Math.floor(triggerData.length / 2)].triggerDuration
              : 0;
          return [
            <div key={triggerData[0].collectibleId + "dummy"}>dummy</div>,
            <div key={triggerData[0].collectibleId}>
              {triggerData[0].collectible.title?.de}
            </div>,
            <div key={triggerData[0].collectibleId}>{triggerData.length}</div>,
            <div key={triggerData[0].collectibleId}>{medianDuration}</div>,
          ];
        })
      : [];
  };

  return (
    <div>
      <List
        items={displayItems}
        itemTagClass={ListRow}
        columnWidths={columnWidths}
        headerNames={["Trigger Name", "Aufrufe", "Dauer (Median)"]}
        sortBy={sortListBy}
        sortByDir={sortListByDir}
        setSortBy={(sortByThis) => {
          let newSortByThis = sortListByDir;
          if (sortByThis === sortListBy) {
            newSortByThis = sortListByDir > 0 ? -1 : 1;
            setSortListByDir(newSortByThis);
          }
          setSortListBy(sortByThis);
        }}
        noItemsMessage="Es gibt keine Analytics Daten"
      />
    </div>
  );
}

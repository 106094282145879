import React from "react";
import EditButton from "../Button/EditButton";
import DeleteButton from "../Button/DeleteButton";
import { BiMove } from "react-icons/bi";

export default function Tag({
  tag,
  onSetopenModal,
  onDelete,
  hideEditButton = " ",
  accountAccess = 3,
  provided,
}) {
  return (
    <div className="flex h-12 pt-2 pb-4 md:pl-8">
      <div className="flex w-full place-content-center place-items-center cursor-default text-darkGray bg-lightGray text-sm rounded-full text-center">
        {tag.name?.["de"]}
      </div>
      <EditButton
        onButtonClicked={onSetopenModal}
        title={"Editieren"}
        disabled={hideEditButton}
      />
      <DeleteButton
        onButtonClicked={() => {
          onDelete(tag.id);
        }}
        accountAccess={accountAccess}
      />
      <button>
        <div
          className="float-left pl-2 md:pl-4 text-freisicht hover:text-darkGreen transition transform hover:scale-105 cursor-pointer"
          {...provided.dragHandleProps}
        >
          <BiMove></BiMove>
        </div>
      </button>
    </div>
  );
}

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tabbar from "../../components/Header/Tabbar";
import { React, useEffect, useState } from "react";
import {
  getCollectible,
  saveCollectible,
} from "../../services/CollectibleService";
import { WhitePage } from "../../components/Page/WhitePage";
import { REACT_APP_API_SERVER } from "../../services/APIService";
import ClickableMedia from "../../components/Image/ClickableMedia";
import ChangePictureForm from "../../components/Form/ChangePictureForm";
import log from "../../utils/Logger";
import Modal from "../../components/Modal/Modal";
import { WorkOverlay } from "../../utils/Tabs";
import { ArtworkEditBlock } from "../../components/ArtworkEditBlock";
import { getCollection } from "../../services/CollectionService";
import EditButton from "../../components/Button/EditButton";
import InputGeoLocation from "../../components/Input/InputGeoLocation";
import ArtWorkTags from "../../components/Tags/ArtWorkTags";

export default function ArtWorkDetails({
  loggedInUser,
  translationInfo,
  setTranslationInfo,
}) {
  const { t } = useTranslation();
  const modules = loggedInUser.user.user.customerModules;
  let { id, cid } = useParams();
  const [collectible, setCollectible] = useState(undefined);
  const [title, setTitle] = useState(collectible && collectible["title"]);
  const [accountAccess, setAccountAccess] = useState(undefined);
  const [changesSaved, setChangesSaved] = useState(undefined);
  const [openModal, setOpenModal] = useState(undefined);
  const [menuOpen, setMenuOpen] = useState(undefined);
  const [loading, setLoading] = useState(false);

  async function updateCollectibleChangeImage(formData) {
    setLoading(true);
    log.debug("update collectible change mage called");
    const updatedCollectible = await saveCollectible(id, cid, formData);
    setCollectible(updatedCollectible);
    setLoading(false);
  }

  useEffect(() => {
    const asyncFnc = async () => {
      const res = await getCollectible(id, cid);
      setCollectible(res.collectible);
      setTitle(res.collectible.title);
      setAccountAccess(res.accountAccess);
      setChangesSaved("");
      // TODO: should check in a better way if languages are shown correctly
      if (
        translationInfo?.languagesToShow?.length ==
        translationInfo?.languagesAvailable?.length
      ) {
        const collection = await getCollection(id);
        const languagesToShow = translationInfo.languagesAvailable?.filter(
          (l) => collection.collection.name[l.code]
        );
        setTranslationInfo({
          ...translationInfo,
          languagesToShow,
        });
      }
    };
    asyncFnc();
  }, []);

  const subMenu = (
    <Tabbar
      locationToGo={"/artworks/" + id}
      showSearch={false}
      items={WorkOverlay(modules)}
      showMultilang={modules.includes("MULTILANG")}
      translationInfo={translationInfo}
      setTranslationInfo={setTranslationInfo}
    ></Tabbar>
  );

  const changeImageModal = menuOpen && (
    <Modal
      onClose={() => {
        log.debug("close modal called");
        setMenuOpen(false);
        //  setSelectedFiles(undefined);
      }}
    >
      <ChangePictureForm
        collectible={collectible}
        updateCollectible={updateCollectibleChangeImage}
        loading={loading}
      ></ChangePictureForm>
    </Modal>
  );

  return (
    <WhitePage
      subMenu={subMenu}
      title={
        title
          ? t("artworkDetailsFor") + title[translationInfo.collectionLanguage]
          : t("artworkDetailsForNoName")
      }
      user={loggedInUser && loggedInUser.user.user}
      changesSaved={changesSaved}
    >
      <div className="grid grid-cols-8">
        <div className="col-span-5 md:col-span-6 ">
          <ArtworkEditBlock
            title={t("artworkTitle")}
            fieldName="title"
            type="text"
            required={true}
            collectible={collectible}
            setCollectible={setCollectible}
            parentInfo={{ setChangesSaved, accountAccess }}
            multilangField={true}
            collectionLanguage={translationInfo.collectionLanguage}
            languageColorClass={translationInfo.languageColorClass}
          ></ArtworkEditBlock>
        </div>
        <div className="col-span-3 md:col-span-2 md:h-20">
          {collectible?.previewMedia ? (
            <ClickableMedia
              menuOpen={openModal}
              setMenuOpen={setOpenModal}
              mediaSource={
                REACT_APP_API_SERVER +
                (collectible &&
                  collectible.previewMedia &&
                  collectible.previewMedia.url)
              }
              imgClassName="max-h-20 md:max-h-56"
            ></ClickableMedia>
          ) : (
            ""
          )}
          <EditButton
            accountAccess={accountAccess}
            onButtonClicked={async () => {
              setMenuOpen(true);
            }}
          />
          {changeImageModal}
        </div>
        <div className="col-span-5 md:col-span-4 md:col-start-1">
          <ArtworkEditBlock
            title={t("artworkArtist")}
            fieldName="artist"
            type="text"
            collectible={collectible}
            setCollectible={setCollectible}
            parentInfo={{ setChangesSaved, accountAccess }}
            multilangField={true}
            collectionLanguage={translationInfo.collectionLanguage}
            languageColorClass={translationInfo.languageColorClass}
          ></ArtworkEditBlock>
        </div>
        <div className="col-span-3 md:col-span-2 h-20">
          <ArtworkEditBlock
            title={t("artworkYear")}
            fieldName="date"
            type="text"
            collectible={collectible}
            setCollectible={setCollectible}
            parentInfo={{ setChangesSaved, accountAccess }}
            multilangField={true}
            collectionLanguage={translationInfo.collectionLanguage}
            languageColorClass={translationInfo.languageColorClass}
          ></ArtworkEditBlock>
        </div>
        <div className="col-span-3 md:col-span-2 md:col-start-1">
          <ArtworkEditBlock
            title={t("artworkMaterial")}
            fieldName="materialTechnique"
            type="text"
            collectible={collectible}
            setCollectible={setCollectible}
            parentInfo={{ setChangesSaved, accountAccess }}
            multilangField={true}
            collectionLanguage={translationInfo.collectionLanguage}
            languageColorClass={translationInfo.languageColorClass}
          ></ArtworkEditBlock>
          <ArtworkEditBlock
            title={t("artworkInventory")}
            fieldName="inventarnummer"
            type="text"
            collectible={collectible}
            setCollectible={setCollectible}
            parentInfo={{ setChangesSaved, accountAccess }}
          ></ArtworkEditBlock>
          <ArtworkEditBlock
            title={t("artworkCreditline")}
            fieldName="creditline"
            type="text"
            collectible={collectible}
            setCollectible={setCollectible}
            parentInfo={{ setChangesSaved, accountAccess }}
            multilangField={true}
            collectionLanguage={translationInfo.collectionLanguage}
            languageColorClass={translationInfo.languageColorClass}
          ></ArtworkEditBlock>
        </div>
        <div className="col-span-2">
          <ArtworkEditBlock
            title={t("artworkObject")}
            fieldName="isObject"
            checkbox="true"
            type="checkbox"
            collectible={collectible}
            setCollectible={setCollectible}
            parentInfo={{ setChangesSaved, accountAccess }}
          ></ArtworkEditBlock>
          <ArtworkEditBlock
            title={t("artworkHeight")}
            fieldName="height"
            type="number"
            collectible={collectible}
            setCollectible={setCollectible}
            parentInfo={{ setChangesSaved, accountAccess }}
          ></ArtworkEditBlock>
          <ArtworkEditBlock
            title={t("artworkWidth")}
            fieldName="width"
            type="number"
            collectible={collectible}
            setCollectible={setCollectible}
            parentInfo={{ setChangesSaved, accountAccess }}
          ></ArtworkEditBlock>
          {collectible && collectible.isObject ? (
            <ArtworkEditBlock
              title={t("artworkDepth")}
              fieldName="depth"
              type="number"
              collectible={collectible}
              setCollectible={setCollectible}
              parentInfo={{ setChangesSaved, accountAccess }}
            ></ArtworkEditBlock>
          ) : (
            ""
          )}
        </div>
        <div className="col-span-3 md:col-span-2">
          <ArtworkEditBlock
            title={t("artworkCopyright")}
            fieldName="copyright"
            type="text"
            collectible={collectible}
            setCollectible={setCollectible}
            parentInfo={{ setChangesSaved, accountAccess }}
            multilangField={true}
            collectionLanguage={translationInfo.collectionLanguage}
            languageColorClass={translationInfo.languageColorClass}
          ></ArtworkEditBlock>
          <ArtworkEditBlock
            title={t("artworkLoan")}
            fieldName="isLeihgabe"
            checkbox="true"
            type="checkbox"
            collectible={collectible}
            setCollectible={setCollectible}
            parentInfo={{ setChangesSaved, accountAccess }}
          ></ArtworkEditBlock>
          {collectible && collectible.isLeihgabe ? (
            <ArtworkEditBlock
              title={t("artworkLoanBy")}
              fieldName="lendingInstitution"
              type="text"
              collectible={collectible}
              setCollectible={setCollectible}
              parentInfo={{ setChangesSaved, accountAccess }}
              multilangField={true}
              collectionLanguage={translationInfo.collectionLanguage}
              languageColorClass={translationInfo.languageColorClass}
            ></ArtworkEditBlock>
          ) : (
            ""
          )}
        </div>
        {modules.includes("GEOLOCATION") && (
          <InputGeoLocation
            collectible={collectible}
            setCollectible={setCollectible}
            parentInfo={{ setChangesSaved, accountAccess }}
            onSave={saveCollectible}
            fieldName="geoLocation"
          ></InputGeoLocation>
        )}
        <div className="col-span-8 md:col-span-6 md:col-start-1">
          {collectible &&
            accountAccess &&
            translationInfo.collectionLanguage && (
              <ArtworkEditBlock
                title={t("artworkInfo")}
                fieldName="info"
                type="richtext"
                tooltip={t("artworkInfoTooltip")}
                collectible={collectible}
                setCollectible={setCollectible}
                parentInfo={{ setChangesSaved, accountAccess }}
                multilangField={true}
                collectionLanguage={translationInfo.collectionLanguage}
                languageColorClass={
                  modules.includes("MULTILANG")
                    ? translationInfo.languageColorClass
                    : "bg-lightGray"
                }
              ></ArtworkEditBlock>
            )}
        </div>
        {modules.includes("TAGS") ? (
          <div className="col-span-8 md:col-span-6 md:col-start-1">
            <ArtWorkTags
              loggedInUser={loggedInUser}
              collectible={collectible}
              accountAccess={accountAccess}
              setCollectible={setCollectible}
            ></ArtWorkTags>
          </div>
        ) : (
          ""
        )}
      </div>
    </WhitePage>
  );
}

import { React, useEffect, useState } from "react";
import {
  BiImage,
  BiMusic,
  BiVideo,
  BiMove,
  BiCube,
  BiText,
  BiError,
} from "react-icons/bi";
import { Draggable } from "@hello-pangea/dnd";
import { useTranslation } from "react-i18next";
import { REACT_APP_API_SERVER } from "../services/APIService";
import { deleteOverlay, getOverlays } from "../services/OverlayService";
import ClickableMedia from "../components/Image/ClickableMedia";
import Loaderfreisicht from "../utils/Loaderfreisicht";
import {
  getOverlayLinkOptionKeys,
  getOverlayLinkOptions,
} from "../utils/OverlayLink";
import { OverlayEditBlock } from "./OverlayEditBlock";
import { ModalErrorAlertInfo } from "./Modal/ModalContent";
import { useParams } from "react-router";
import EditButton from "./Button/EditButton";
import DeleteButton from "./Button/DeleteButton";

export function OneOverlayElement({
  accountAccess,
  setChangesSaved,
  loadData,
  index,
  overlays,
  setOverlays,
  collectionLanguage,
  languageColorClass,
  setChangeOverlayOpen,
}) {
  const { t } = useTranslation();
  let iconType;
  if (overlays[index].type === 0) {
    iconType = <BiImage></BiImage>;
  } else if (overlays[index].type === 1) {
    iconType = <BiVideo></BiVideo>;
  } else if (overlays[index].type === 2) {
    iconType = <BiMusic></BiMusic>;
  } else if (overlays[index].type === 3) {
    iconType = <BiCube></BiCube>;
  } else if (overlays[index].type === 4) {
    iconType = <BiText></BiText>;
  } else if (overlays[index].type === 5) {
    iconType = "Unbekannt";
  }

  let { id, cid } = useParams();

  useEffect(() => {
    let processsingInterval = undefined;
    if (overlays?.some((x) => x.media?.[collectionLanguage]?.status == 1)) {
      processsingInterval = setInterval(async () => {
        const overlaysl = await getOverlays(id, cid);
        let hasProcessingOverlay = overlaysl.some(
          (x) => x.media?.[collectionLanguage]?.status == 1
        );
        if (!hasProcessingOverlay) {
          setOverlays(overlaysl);
          clearInterval(processsingInterval);
        }
      }, 5000);
    }
    return () => {
      processsingInterval && clearInterval(processsingInterval);
    };
  }, []);

  const parentInfo = {
    accountAccess,
    setChangesSaved,
    index,
    loadData,
    overlays,
    setOverlays,
  };
  const [openModal, setOpenModal] = useState(undefined);
  const [message, setMessage] = useState(false);
  const [acceptData, setAcceptData] = useState(undefined);

  return overlays[index] ? (
    <>
      <Draggable draggableId={overlays[index].id.toString()} index={index}>
        {(provided) => (
          <div
            className="grid grid-cols-8 border-b border-middleGray"
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <div className="col-span-2 justify-self-start place-self-center">
              <div className="text-4xl font-bold p-4 text-freisicht flex flex-cols">
                <div className="">{iconType}</div>
              </div>
            </div>
            <div className="col-span-5">
              <OverlayEditBlock
                title="Name"
                fieldName="title"
                type="text"
                required={true}
                parentInfo={parentInfo}
              />
            </div>

            <div className="col-span-1 justify-self-center place-self-center">
              {accountAccess > 1 ? (
                <div
                  className="p-4 text-freisicht hover:text-darkGreen transition transform hover:scale-105 cursor-pointer"
                  {...provided.dragHandleProps}
                >
                  <BiMove></BiMove>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="col-span-2">
              <OverlayEditBlock
                title={t("overlaysActive")}
                fieldName="isActive"
                type="checkbox"
                parentInfo={parentInfo}
                multilangField={true}
                collectionLanguage={collectionLanguage}
                languageColorClass={languageColorClass}
              />

              {overlays[index].media &&
              overlays[index].media[collectionLanguage]?.status == 0 &&
              overlays[index].media[collectionLanguage]?.url ? (
                <div>
                  <ClickableMedia
                    menuOpen={openModal}
                    type={overlays[index].type}
                    setMenuOpen={setOpenModal}
                    mediaSource={
                      REACT_APP_API_SERVER +
                      (overlays[index].media &&
                        overlays[index].media[collectionLanguage].url)
                    }
                    imgClassName=" max-h-56"
                  ></ClickableMedia>
                  <EditButton
                    accountAccess={accountAccess}
                    onButtonClicked={async () => {
                      setChangeOverlayOpen(overlays[index]);
                    }}
                    title={t("overlayChangeMedia")}
                  />
                </div>
              ) : overlays[index].media &&
                overlays[index].media[collectionLanguage]?.status == 1 ? (
                <div title={t("overlayMediaProcessed")}>
                  <Loaderfreisicht></Loaderfreisicht>
                </div>
              ) : overlays[index].media &&
                overlays[index].media[collectionLanguage]?.status == 2 ? (
                <div>
                  <div className="flex justify-center col-span-3 mt-2 text-middleGray">
                    <BiError fontSize={80}></BiError>
                  </div>
                  <div className="px-3 flex justify-center text-freisicht text-sm text-center col-span-3 text-middleGray">
                    {t("overlayMediaError")}
                  </div>
                  <EditButton
                    accountAccess={accountAccess}
                    onButtonClicked={async () => {
                      setChangeOverlayOpen(overlays[index]);
                    }}
                  />
                </div>
              ) : overlays[index].type == 4 ? (
                <div>
                  <div className="text-freisicht text-sm text-gray p-2 pb-0 md:pb-0 md:p-4">
                    {overlays[index].textOverlay?.[collectionLanguage]?.text
                      ?.replace(/(<([^>]+)>)/gi, "")
                      .slice(0, 30)}
                    ...
                  </div>
                  <EditButton
                    accountAccess={accountAccess}
                    onButtonClicked={async () => {
                      setChangeOverlayOpen(overlays[index]);
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div
              className={
                overlays[index].fadeinEnabled
                  ? "col-span-2 md:col-span-1"
                  : "col-span-1"
              }
            >
              {overlays[index].type === 1 || overlays[index].type === 2 ? (
                <OverlayEditBlock
                  title="Loop"
                  fieldName="loopEnabled"
                  type="checkbox"
                  parentInfo={parentInfo}
                />
              ) : (
                ""
              )}
              {overlays[index].type === 0 || overlays[index].type === 1 ? (
                <OverlayEditBlock
                  title={t("overlaysFadeIn")}
                  fieldName="fadeinEnabled"
                  type="checkbox"
                  parentInfo={parentInfo}
                />
              ) : (
                ""
              )}
              {overlays[index].fadeinEnabled ? (
                <OverlayEditBlock
                  title={t("overlaysDuration")}
                  fieldName="fadeinDuration"
                  type="number"
                  defaultValueIfNull={0}
                  parentInfo={parentInfo}
                />
              ) : (
                ""
              )}
            </div>
            <div className="col-span-2">
              {!(overlays[index].type === 2) ? (
                <>
                  <OverlayEditBlock
                    title={t("overlaysPosition")}
                    fieldName="position"
                    type="dropdown"
                    dropdownOptions={[
                      t("overlayPositionCenter"),
                      t("overlayPositionTop"),
                      t("overlayPositionLeft"),
                      t("overlayPositionBottom"),
                      t("overlayPositionRight"),
                    ]}
                    parentInfo={parentInfo}
                  />
                  <OverlayEditBlock
                    title={t("overlaysRelativeSize")}
                    fieldName="size"
                    type="number"
                    defaultValueIfNull={1}
                    parentInfo={parentInfo}
                  />
                  {!(overlays[index].type === 4) && (
                    <OverlayEditBlock
                      title={t("overlaysKeepRatio")}
                      fieldName="keepRatio"
                      type="checkbox"
                      parentInfo={parentInfo}
                    />
                  )}{" "}
                </>
              ) : (
                ""
              )}
            </div>
            <div className="col-span-2">
              {/*               {!(overlays[index].type === 2) ? (
                <OverlayEditBlock
                  title={t("overlaysWebaddress")}
                  fieldName="webviewUrl"
                  type="url"
                  parentInfo={parentInfo}
                />
              ) : (
                ""
              )} */}
              <OverlayEditBlock
                title={t("overlaysLinked")}
                fieldName="link"
                tooltip={t("overlaysLinkedTooltip")}
                type="dropdown"
                dropdownOptions={getOverlayLinkOptions(
                  overlays,
                  overlays[index].id
                )}
                dropdownOptionKeys={getOverlayLinkOptionKeys(
                  overlays,
                  overlays[index].id
                )}
                parentInfo={parentInfo}
              />
            </div>

            <div className="col-start-8 col-span-1 place-self-end justify-self-center">
              <DeleteButton
                accountAccess={accountAccess}
                onButtonClicked={async () => {
                  setMessage(t("overlaysDeleteConfirm"));
                  setAcceptData(overlays[index]);
                }}
              />
            </div>
          </div>
        )}
      </Draggable>
      {message && (
        <ModalErrorAlertInfo
          message={message}
          setMessage={setMessage}
          acceptData={acceptData}
          setAcceptData={setAcceptData}
          acceptFunction={async () => {
            setMessage(undefined);
            await deleteOverlay(id, acceptData.collectibleId, acceptData.id);
            setAcceptData(undefined);
            loadData();
          }}
        ></ModalErrorAlertInfo>
      )}
    </>
  ) : (
    ""
  );
}

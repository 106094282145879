import { postToApi, setTokens, setUser } from "./APIService";
import jwt from "jwt-decode"; // import dependency

export async function login(username, password) {
  const res = await postToApi(
    "login",
    {
      username,
      password,
    },
    false
  );
  if (res && res.accessToken && res.refreshToken) {
    setTokens(res.accessToken, res.refreshToken);
    const user = jwt(res.accessToken);
    setUser(user);
    return { ...res, user: user };
  }
  return false;
  //return undefined;
  //console.log("login service done with data", res);
}

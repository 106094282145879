import { React } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

export function Page({
  title,
  subMenu,
  locationToGo,
  children,
  user,
  changesSaved,
}) {
  return (
    <div className="relative pb-20 min-h-screen">
      <Header
        subMenu={subMenu}
        title={title}
        locationToGo={locationToGo}
        user={user}
      ></Header>
      <div className="fixed top-32 left-0 flex flex-row w-full justify-start">
        {changesSaved ? (
          <div className="flex justify-start text-white text-xs bg-red w-full px-4 h-4">
            {" "}
            {changesSaved}
          </div>
        ) : (
          <div className="h-4"></div>
        )}
      </div>
      {children}
      <Footer></Footer>
    </div>
  );
}

import React from "react";
import { BiX } from "react-icons/bi";

export default function Modal({ onClose, children, className }) {
  return (
    <div
      onClick={() => {
        onClose();
      }}
      className="fixed top-0 left-0 w-full h-full z-50 overflow-scroll"
      style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
    >
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}
        className={
          "bg-white m-auto w-5/6 md:w-1/2 my-5 relative p-4 md:p-6 " + className
        }
      >
        <div
          id="close-modal"
          onClick={() => {
            onClose();
          }}
          className="absolute transition transform hover:scale-105 text-freisicht hover:text-darkGreen text-4xl top-2 right-4 cursor-pointer"
        >
          <BiX></BiX>
        </div>
        {children}
      </div>
    </div>
  );
}

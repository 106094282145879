import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export function WorksExhibitionImpressum(module) {
  const { t } = useTranslation();
  let { id } = useParams();
  return module.includes("VR")
    ? [
        {
          link: "/artworks/" + id,
          title: t("tabbarWorks"),
        },
        {
          link: "/exhibitions/" + id,
          title: t("tabbarExhibitions"),
        },
      ]
    : module.includes("AR")
    ? [
        {
          link: "/artworks/" + id,
          title: t("tabbarWorks"),
        },
        {
          link: "/impressum/" + id,
          title: t("tabbarImpressum"),
        },
      ]
    : [
        {
          link: "/artworks/" + id,
          title: t("tabbarWorks"),
        },
        {
          link: "/exhibitions/" + id,
          title: t("tabbarExhibitions"),
        },
        {
          link: "/impressum/" + id,
          title: t("tabbarImpressum"),
        },
      ];
}

export function WorkOverlay(module = "") {
  let { id, cid } = useParams();
  return !module.includes("VR") && module.includes("TAGS")
    ? [
        {
          link: "/artWorkDetail/" + id + "/" + cid + "/details",
          title: t("tabbarWork"),
        },
        {
          link: "/artWorkDetail/" + id + "/" + cid + "/overlay",
          title: t("tabbarOverlays"),
        },
      ]
    : module.includes("TAGS")
    ? [
        {
          link: "/artWorkDetail/" + id + "/" + cid + "/details",
          title: t("tabbarWork"),
        },
      ]
    : !module.includes("VR")
    ? [
        {
          link: "/artWorkDetail/" + id + "/" + cid + "/details",
          title: t("tabbarWork"),
        },
        {
          link: "/artWorkDetail/" + id + "/" + cid + "/overlay",
          title: t("tabbarOverlays"),
        },
      ]
    : [];
}

import React, { useCallback } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import {
  AiOutlineBold,
  AiOutlineItalic,
  AiOutlineFontSize,
  AiOutlineUndo,
  AiOutlineRedo,
} from "react-icons/ai";
import { BiLink, BiUnlink } from "react-icons/bi";
import RichTextIcon from "../utils/RichTextIcon";
import log from "../utils/Logger";

export default function EditBlockRichText({
  onChange,
  onBlur,
  value = "",
  disabled,
  languageColorClass,
}) {
  let justFocused = false;
  const MenuBar = ({ editor }) => {
    if (!editor) {
      return null;
    }

    return (
      <>
        <RichTextIcon
          editorClicked={() => editor.chain().focus().toggleBold().run()}
          disabled={disabled}
          title="Fett"
          classNameAddon={editor.isActive("bold")}
        >
          <AiOutlineBold />
        </RichTextIcon>
        <RichTextIcon
          editorClicked={() => editor.chain().focus().toggleItalic().run()}
          disabled={disabled}
          title="Kursiv"
          classNameAddon={editor.isActive("italic")}
        >
          <AiOutlineItalic />
        </RichTextIcon>
        <RichTextIcon
          editorClicked={() => editor.chain().focus().setParagraph().run()}
          disabled={disabled}
          title="Textgrösse normal"
          classNameAddon={editor.isActive("paragraph")}
        >
          <AiOutlineFontSize />
        </RichTextIcon>
        <RichTextIcon
          title="Textgrösse Titel"
          editorClicked={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          disabled={disabled}
          classNameAddon={editor.isActive("heading", { level: 1 })}
        >
          H1
        </RichTextIcon>
        <RichTextIcon
          editorClicked={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          setFontStyling={(event) => {
            event.preventDefault();
          }}
          title="Textgrösse Untertitel"
          disabled={disabled}
          classNameAddon={editor.isActive("heading", { level: 2 })}
        >
          H2
        </RichTextIcon>
        <RichTextIcon
          title="Link hinzufügen"
          disabled={disabled}
          editorClicked={() => setLink()}
          classNameAddon={editor.isActive("link")}
        >
          <BiLink />
        </RichTextIcon>
        <RichTextIcon
          title="Link entfernen"
          disabled={disabled}
          editorClicked={() => editor.chain().focus().unsetLink().run()}
        >
          <BiUnlink />
        </RichTextIcon>
        <RichTextIcon
          title="Undo"
          disabled={disabled}
          editorClicked={() => editor.chain().focus().undo().run()}
        >
          <AiOutlineUndo />
        </RichTextIcon>
        <RichTextIcon
          title="Redo"
          disabled={disabled}
          editorClicked={() => editor.chain().focus().redo().run()}
        >
          <AiOutlineRedo />
        </RichTextIcon>
      </>
    );
  };

  function localBlur(a) {
    onBlur && onBlur(a);
  }

  const editor = useEditor(
    {
      editable: !disabled,
      extensions: [
        StarterKit,
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
        Highlight,
        Link.configure({
          openOnClick: false,
        }),
      ],
      editorProps: {
        transformPastedText(text) {
          return stripTags(
            text,
            "b",
            "i",
            "p",
            "br",
            "h1",
            "h2",
            "strong",
            "em",
            "a"
          );
        },
        transformPastedHTML(html) {
          return stripTags(
            html,
            "b",
            "i",
            "p",
            "br",
            "h1",
            "h2",
            "strong",
            "em",
            "a"
          );
        },
        attributes: {
          class:
            "prose prose-sm sm:prose lg:prose-lg xl:prose-2xl m-5 focus:outline-none",
        },
      },

      content: value,
      onBlur({ editor }) {
        // The editor isn’t focused anymore.
        if (justFocused == true) return;
        log.debug("blur event fired", editor.getHTML());
        onChange && onChange(editor.getHTML());

        localBlur(editor.getHTML());
      },
      // needed to not fire blur event just after focus
      onFocus() {
        // The editor isn’t focused anymore.
        log.debug("focus event fired");
        justFocused = true;
        setTimeout(() => {
          justFocused = false;
        }, 500);
      },
    },
    [value, onBlur]
  );

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  //console.log("this is the editor", editor);

  if (!editor) {
    return null;
  }

  return (
    <div className="border border-lightGray">
      <MenuBar editor={editor} />
      <EditorContent
        editor={editor}
        className={
          "editor-box border border-lightGray pt-1 " + languageColorClass
        }
      />
    </div>
  );
}

function stripTags(html, ...args) {
  return html
    .replace(/<(\/?)(\w+)[^>]*\/?>/g, (_, endMark, tag) => {
      return args.includes(tag) ? "<" + endMark + tag + ">" : "";
    })
    .replace(/<!--.*?-->/g, "");
}

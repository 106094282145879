import { React, useState } from "react";
import { BiMenu, BiX } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import Menu from "../Menu/Menu";

export default function Header({ title, subMenu, user }) {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuIcon = menuOpen ? <BiX></BiX> : <BiMenu />;
  const background = title ? "bg-lightGray" : "bg-freisicht";
  const modules = user?.customerModules;

  const data = [
    {
      title: t("menuProjects"),
      href: "/projects",
    },
  ];

  if (user && user.accountRole === 9) {
    data.push({
      title: t("menuUseradmin"),
      href: "/useradmin",
    });
  }

  if (user && user.accountRole === 9 && modules.includes("TAGS")) {
    data.push({
      title: t("menuSettings"),
      href: "/settings",
    });
  }

  if (user && user.accountRole === 9 && modules.includes("ANALYTICS")) {
    data.push({
      title: "Analytics",
      href: "/analytics",
    });
  }

  data.push(
    {
      title: t("menuLanguage"),
      languageOptions: ["de", "en"],
    },
    {
      title: "Logout",
      href: "/logout",
    }
  );

  return (
    <>
      <div className="absolute top-0 left-0 w-full flex h-16">
        <div
          className={"h-full " + background}
          style={{ width: "calc((100vw - 1024px)/2)" }}
        ></div>
        <div className="flex-grow max-w-screen-sm md:max-w-screen-lg m-auto grid grid-cols-2 h-full">
          <div className="text-gray font-black flex flex-row">
            {title ? (
              <div className="bg-lightGray flex flex-grow h-full pl-4 text-left content-center flex-wrap">
                {title}
              </div>
            ) : (
              <>
                <div className="flex flex-col">
                  <div className="bg-freisicht flex-grow"></div>
                  <img
                    src="/assets/images/logo.png"
                    alt="logo"
                    className="md:h-12 z-10"
                  />
                </div>
                <div className="bg-freisicht flex-grow h-full"></div>
              </>
            )}
          </div>
          <div className={background}>
            <div className="flex flex-row gap-6 justify-end content-center flex-wrap h-full pr-4">
              <div className="text-gray font-black">varcu Admin</div>
              {title && (
                <div
                  className="flex content-center flex-wrap -mt-1"
                  onClick={() => {
                    setMenuOpen(!menuOpen);
                  }}
                >
                  <span className="z-40 transition transform hover:scale-105 text-freisicht hover:text-darkGreen text-3xl cursor-pointer">
                    {menuIcon}
                  </span>
                  {menuOpen && (
                    <Menu
                      className="w-72 md:w-96 right-4 absolute top-2 "
                      data={data}
                      onClose={() => {
                        setMenuOpen(false);
                      }}
                      style={
                        window.innerWidth > 640
                          ? { right: "calc((100vw - 1024px)/2)" }
                          : { right: "0.5rem" }
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={"h-full " + background}
          style={{ width: "calc((100vw - 1024px)/2)" }}
        ></div>
      </div>
      {subMenu && (
        <div className="absolute top-16 left-0 w-full h-16">{subMenu}</div>
      )}
    </>
  );
}

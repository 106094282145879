import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import InputGeneral from "../../components/InputText";
import DefaultButton from "../Button/DefaultButton";
import ModalContent, {
  ModalInputDescription,
  ModalTitle,
} from "../Modal/ModalContent";

export default function NewProjectForm({ onSave }) {
  const { t } = useTranslation();
  const [projectName, setprojectName] = useState(undefined);
  const [userinfo, setUserinfo] = useState(undefined);

  const CheckInput = () => {
    console.log("pro name", projectName);
    if (!projectName) {
      setUserinfo("Das Projekt braucht einen Titel.");
      return false;
    } else {
      setUserinfo(undefined);
      return true;
    }
  };

  const onButtonClicked = () => {
    CheckInput() &&
      onSave({
        projectName,
      });
  };

  return (
    <div>
      <ModalContent>
        <ModalTitle title={t("projectNewTitle")} />
        <ModalInputDescription desc={t("projectNewName")} />
        <InputGeneral
          classNameAddon="col-span-4 md:col-span-3 new-project-name"
          value={projectName}
          onKeyUp={onButtonClicked}
          onChange={setprojectName}
          onBlur={CheckInput}
        />
        <div className="col-span-6"> {userinfo}</div>
      </ModalContent>
      <DefaultButton
        text={t("projectNewCreate")}
        onButtonClicked={userinfo ? () => {} : onButtonClicked}
        disabled={userinfo ? " opacity-50 cursor-not-allowed " : ""}
      ></DefaultButton>
    </div>
  );
}

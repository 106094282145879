import { React } from "react";

export default function RichTextIcon({
  editorClicked,
  classNameAddon,
  disabled,
  title,
  children,
}) {
  return (
    <button
      onClick={() => editorClicked()}
      onMouseDown={(event) => {
        event.preventDefault();
      }}
      title={title}
      disabled={disabled}
      className={
        (classNameAddon ? " is-active bg-lightGray" : "") + " px-3 py-2 "
      }
    >
      {children}
    </button>
  );
}

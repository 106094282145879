import { React, useEffect, useState } from "react";
import { BiInfoCircle } from "react-icons/bi";

export default function InputGeoLocation({
  onSave,
  collectible,
  setCollectible,
  fieldName,
  disabled = false,
  className = "text-gray placeholder-white bg-lightGray text-sm border-2 border-lightGray no-spin ",
  classNameAddon,
}) {
  const [latitude, setLatitude] = useState(undefined);
  const [longitude, setLongitude] = useState(undefined);
  const tooltip = "geoLocation tooltip";

  useEffect(() => {
    setLatitude(parseFloat(collectible?.[fieldName]?.[0]) || "");
    setLongitude(parseFloat(collectible?.[fieldName]?.[1]) || "");
  }, [collectible]);

  const onBlur = () => {
    const newValue = [latitude || null, longitude || null];
    const newCollectible = { ...collectible, [fieldName]: newValue };
    onSave(collectible.collectionId, collectible.id, newCollectible);
    setCollectible(newCollectible);
  };

  return (
    <div className="pl-4 col-span-8 md:col-span-6 col-start-1">
      <div className="text-gray font-bold text-sm" data-tip={tooltip}>
        {"Latitude"}{" "}
        {tooltip && (
          <span className="inline-block align-text-top">
            <BiInfoCircle className="inline -top-px relative"></BiInfoCircle>
          </span>
        )}
      </div>
      <input
        onChange={(event) => {
          setLatitude(parseFloat(event.target.value) || "");
        }}
        onBlur={(event) => {
          onBlur && onBlur(event.target.value);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        type="number"
        disabled={disabled}
        value={latitude != null ? latitude : ""}
        className={className + " " + classNameAddon}
      />
      <div className="text-gray font-bold text-sm" data-tip={tooltip}>
        {"Longitude"}{" "}
        {tooltip && (
          <span className="inline-block align-text-top">
            <BiInfoCircle className="inline -top-px relative"></BiInfoCircle>
          </span>
        )}
      </div>
      <input
        onChange={(event) => {
          setLongitude(parseFloat(event.target.value) || "");
        }}
        onBlur={(event) => {
          onBlur && onBlur(event.target.value);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        type="number"
        disabled={disabled}
        value={longitude != null ? longitude : ""}
        className={className + " " + classNameAddon}
      />
    </div>
  );
}

import { React, useEffect, useState } from "react";
import { setTokens, setUser } from "../../services/APIService";
import { login } from "../../services/LoginService";
import { Page } from "../Page/Page";
import { LoginBox } from "./LoginBox";
import log from "../../utils/Logger";
import Loaderfreisicht from "../../utils/Loaderfreisicht";

async function tryLogin({ username, password }) {
  const loginResult = await login(username, password);
  log.debug("login result ", loginResult);
  if (loginResult && loginResult.accessToken && loginResult.refreshToken) {
    return {
      timestamp: new Date().getTime(),
      ...loginResult,
      username,
      realname: username,
    };
  } else {
    return undefined;
  }
}

const DEFAULT_SESSION_EXP_TIME = 72 * 3600 * 1000;

export function LoginProvider({ loggedInUser, onLogin, children }) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function doIt() {
      //onLogin("")
      const oldLogin = localStorage.getItem("fsl");
      if (oldLogin) {
        try {
          const parsedItem = JSON.parse(oldLogin);
          if (
            new Date().getTime() <
            parsedItem.timestamp + DEFAULT_SESSION_EXP_TIME
          ) {
            setTokens(parsedItem.accessToken, parsedItem.refreshToken);
            setUser(parsedItem.user);
            onLogin(parsedItem);
          } else {
            localStorage.removeItem("fsl");
          }
        } catch (e) {
          log.debug("wasnt able to parse item from localstorage");
          localStorage.removeItem("fsl");
        }
        // todo check if sesssion token still valid
      }
      setLoaded(true);
    }
    doIt();
  }, []);

  if (!loaded) {
    return (
      <div className="h-full flex flex-row place-items-center place-content-center">
        <Loaderfreisicht />
      </div>
    );
  }

  return (
    <>
      {!loggedInUser ? (
        <Page background="/bg.png">
          <LoginBox
            onLoginPressed={async (data) => {
              const newLoginData = await tryLogin(data);
              if (newLoginData) {
                onLogin(newLoginData);
                localStorage.setItem("fsl", JSON.stringify(newLoginData));
              }
            }}
          ></LoginBox>
        </Page>
      ) : (
        children
      )}
    </>
  );
}

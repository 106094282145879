import { React } from "react";
import { useParams } from "react-router";
import {
  addOverlayLink,
  deleteOverlayLink,
  saveOverlay,
} from "../services/OverlayService";
import log from "../utils/Logger";
import { EditBlock } from "./EditBlock";

export function OverlayEditBlock(args) {
  const {
    fieldName,
    parentInfo,
    multilangField,
    collectionLanguage,
    defaultValueIfNull,
  } = args;
  const { setChangesSaved, index, overlays, setOverlays } = parentInfo;
  let { id, cid } = useParams();

  function updateOverlay(index, fieldName, value) {
    const newOverlays = [...overlays];
    if (multilangField == true) {
      const newValue = {
        ...newOverlays[index][fieldName],
        [collectionLanguage]: value,
      };
      newOverlays[index][fieldName] = newValue;
    } else {
      newOverlays[index][fieldName] = value;
    }
    setOverlays(newOverlays);
  }
  async function onChanged(newval) {
    try {
      if (fieldName == "link") {
        log.debug("should save linked overlay here, newval: ", newval);
        await saveOverlayLinkChanged(newval, overlays[index]);
      } else if (multilangField == true) {
        const newValue = {
          ...overlays[index][fieldName],
          [collectionLanguage]: newval,
        };
        await saveOverlay(id, cid, overlays[index].id, {
          ...overlays[index],
          [fieldName]: newValue,
        });
        updateOverlay(index, fieldName, newval);
      } else {
        await saveOverlay(id, cid, overlays[index].id, {
          ...overlays[index],
          [fieldName]: newval,
        });
        updateOverlay(index, fieldName, newval);
      }
      setChangesSaved("");
    } catch (error) {
      setChangesSaved("Speichern fehlgeschlagen");
      log.error("saveOverlay dropdown position error: ", error);
    }
  }

  function fetchData() {
    if (fieldName == "link") {
      let dropdownValue = -1;

      if (overlays[index][fieldName]) {
        let linkedOverlay = overlays.find(
          (x) =>
            x.id != overlays[index].id &&
            x[fieldName] &&
            x[fieldName].linkedGroupId ==
              overlays[index][fieldName].linkedGroupId
        );

        if (linkedOverlay?.link != null) {
          log.debug("linkedOverlay found: ", linkedOverlay);
          dropdownValue = linkedOverlay.id;
        }
      }
      // log.debug("getDropdownvalue: ", dropdownValue);
      return dropdownValue;
    } else {
      return overlays && overlays[index] && overlays[index][fieldName]
        ? multilangField
          ? overlays[index][fieldName][collectionLanguage]
          : overlays[index][fieldName]
        : defaultValueIfNull != null
        ? defaultValueIfNull
        : "";
    }
  }

  async function saveOverlayLinkChanged(linkedOverlayId, overlay) {
    try {
      const linkedOverlay = overlays.find((x) => x.id == linkedOverlayId);
      if (overlay.link && overlay.link.id) {
        log.debug("linkedGroupId to delete: ", overlay.link.linkedGroupId);
        log.debug("overlays before map: ", overlays);
        const newOverlays = [...overlays];
        await Promise.all(
          overlays.map(async (x) => {
            if (x.link && x.link.linkedGroupId == overlay.link.linkedGroupId) {
              log.debug("delete called for overlayid: ", overlay.id);
              await deleteOverlayLink(id, cid, x.id, x.link.id);
              const indexOfLinkDeleted = newOverlays.findIndex(
                (y) => y.id == x.id
              );
              log.debug("indexOfLinkDeleted ", indexOfLinkDeleted);
              newOverlays[indexOfLinkDeleted].link = null;
            }
          })
        );
        log.debug("newOverlays: ", newOverlays);
        setOverlays(newOverlays);
      }

      // reset
      if (!linkedOverlay) {
        return;
      }
      const res = await addOverlayLink(id, cid, overlay.id, {
        linkedGroupId: -1,
        overlayId: overlay.id,
      });
      log.debug("result res: ", res);
      if (res && res.linkedGroupId) {
        const updatedOverlays = [...overlays];
        const indexOfLinkAdded = updatedOverlays.findIndex(
          (y) => y.id == res.overlayId
        );
        updatedOverlays[indexOfLinkAdded].link = res;
        const res2 = await addOverlayLink(id, cid, linkedOverlay.id, {
          linkedGroupId: res.linkedGroupId,
          overlayId: linkedOverlay.id,
        });
        const indexOfSecondLinkAdded = updatedOverlays.findIndex(
          (y) => y.id == res2.overlayId
        );
        updatedOverlays[indexOfSecondLinkAdded].link = res2;
        setOverlays(updatedOverlays);
      }
    } catch (e) {
      log.debug("error: ", e);
    }
  }
  const data = fetchData();

  return <EditBlock {...args} onChanged={onChanged} data={data} />;
}

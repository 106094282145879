import { React } from "react";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";

export function List({
  items,
  headerNames,
  columnWidths,
  visibility,
  itemTagClass,
  sortBy,
  setSortBy,
  sortByDir,
  noItemsMessage = "Keine Daten vorhanden",
  noSortColumns = [],
  itemIds,
  listName,
}) {
  const ItemTag = itemTagClass;
  // console.log("ItemTag ", ItemTag);
  return items && items.length ? (
    <div id="fsList">
      {headerNames && (
        <ItemTag
          columnWidths={columnWidths}
          hasLinkAsFirstElement={false}
          data={headerNames.map((headerName, i) => {
            return (
              <div
                onClick={() => {
                  !noSortColumns.includes(i) && setSortBy(i);
                }}
                className="cursor-pointer text-darkGreen font-bold text-sm flex flex-row gap-4"
                key={i}
              >
                {headerName}{" "}
                {!noSortColumns.includes(i) && sortBy === i ? (
                  sortByDir > 0 ? (
                    <BsChevronDown className="text-lg"></BsChevronDown>
                  ) : (
                    <BsChevronUp className="text-lg"></BsChevronUp>
                  )
                ) : (
                  ""
                )}
              </div>
            );
          })}
          visibility={visibility}
        ></ItemTag>
      )}

      {items.map((itemData, i) => {
        return (
          <ItemTag
            columnWidths={columnWidths}
            hasLinkAsFirstElement={true}
            data={itemData}
            key={i}
            rowIndex={i}
            visibility={visibility}
            dataId={itemIds?.[i]}
            listName={listName}
          ></ItemTag>
        );
      })}
    </div>
  ) : (
    <div className="p-4">{noItemsMessage}</div>
  );
}

export const getBackgroundColorForLanguage = (lang) => {
  switch (lang) {
    case "de":
      return "bg-langDe";
    case "en":
      return "bg-langEn";
    case "fr":
      return "bg-langFr";
    default:
      return "bg-lightGray";
  }
};

import log from "../utils/Logger";

export function sortData(data, field, direction, multilang) {
  log.debug("multilang ", multilang);
  // needed to sort artworks by upload date
  if (field === "thumbnailMedia") {
    field = "createdAt";
  }

  if (
    data &&
    data.length > 0 &&
    field !== "createdAt" &&
    isStringArray(data, field, multilang)
  ) {
    if (multilang) {
      return data.sort((a, b) => {
        try {
          if (!a[field][multilang]) a[field][multilang] = "";
          if (!b[field][multilang]) b[field][multilang] = "";
          if (typeof b[field][multilang] !== "string")
            b[field][multilang] = String(b[field][multilang]);
          if (typeof a[field][multilang] !== "string")
            a[field][multilang] = String(a[field][multilang]);
          if (typeof b[field][multilang] !== "string")
            b[field][multilang] = String(b[field][multilang]);
        } catch (error) {
          log.error(error);
          return;
        }

        if (
          a[field][multilang].toLowerCase() < b[field][multilang].toLowerCase()
        ) {
          return direction > 0 ? -1 : 1;
        }
        if (
          a[field][multilang].toLowerCase() > b[field][multilang].toLowerCase()
        ) {
          return direction > 0 ? 1 : -1;
        }
      });
    } else {
      return data.sort((a, b) => {
        try {
          if (a[field] == null) a[field] = "";
          if (b[field] == null) b[field] = "";
          if (typeof b[field] !== "string") b[field] = String(b[field]);
          if (typeof a[field] !== "string") a[field] = String(a[field]);
          if (typeof b[field] !== "string") b[field] = String(b[field]);
        } catch (error) {
          log.error(error);
          return;
        }

        if (a[field].toLowerCase() < b[field].toLowerCase()) {
          return direction > 0 ? -1 : 1;
        }
        if (a[field].toLowerCase() > b[field].toLowerCase()) {
          return direction > 0 ? 1 : -1;
        }
      });
    }
  }

  log.debug("sorting by field ", field, "with data", data);
  if (multilang) {
    return data.sort((a, b) => {
      if (a[field] && !a[field][multilang]) a[field][multilang] = 0;
      if (b[field] && !b[field][multilang]) b[field][multilang] = 0;
      if (a[field]?.[multilang] < b[field]?.[multilang]) {
        return direction > 0 ? -1 : 1;
      }
      if (a[field]?.[multilang] > b[field]?.[multilang]) {
        return direction > 0 ? 1 : -1;
      }
    });
  } else {
    return data.sort((a, b) => {
      if (a[field] < b[field]) {
        return direction > 0 ? -1 : 1;
      }
      if (a[field] > b[field]) {
        return direction > 0 ? 1 : -1;
      }
    });
  }
}

function isStringArray(data, field, multilang) {
  return data.some((x) => {
    return multilang
      ? typeof x?.[field]?.[multilang] === "string"
      : typeof x?.[field] === "string";
  });
}

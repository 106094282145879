import React from "react";
import { useTranslation } from "react-i18next";
import DefaultButton from "../Button/DefaultButton";
import Modal from "./Modal";
import Tooltip from "../../utils/Tooltip";

export default function ModalContent({ children }) {
  return <div className="grid grid-cols-6 gap-4 py-4">{children}</div>;
}

export function ModalTitle({ title }) {
  return <div className="col-span-6 font-bold">{title}</div>;
}

export function ModalSubTitle({ subtitle, tooltip }) {
  return (
    <Tooltip
      subtitle={subtitle}
      tooltip={tooltip}
      tooltipId={`tooltip-${subtitle}`}
    />
  );
}

export function ModalInputDescription({ desc }) {
  return <div className="col-span-2 py-2 text-sm">{desc}</div>;
}

export function ModalErrorAlertInfoMessages({
  message,
  confirm,
  confirmText,
  reject,
  rejectText,
}) {
  return (
    <div className="py-4 px-6">
      {message}
      <div className="flex justify-center space-x-6">
        {confirm && confirmText && (
          <DefaultButton
            text={confirmText}
            onButtonClicked={confirm}
          ></DefaultButton>
        )}
        {reject && rejectText && (
          <DefaultButton
            text={rejectText}
            onButtonClicked={reject}
          ></DefaultButton>
        )}
      </div>
    </div>
  );
}

export function ModalErrorAlertInfo({
  setMessage,
  setAcceptData,
  message,
  acceptData,
  acceptFunction,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      onClose={() => {
        setMessage(undefined);
        setAcceptData(undefined);
      }}
    >
      <ModalErrorAlertInfoMessages
        message={message}
        confirm={acceptData ? acceptFunction : undefined}
        confirmText={acceptData ? "ok" : ""}
        reject={() => {
          setMessage(undefined);
          setAcceptData(undefined);
        }}
        rejectText={acceptData ? t("modalCancel") : t("modalConfirm")}
      ></ModalErrorAlertInfoMessages>
    </Modal>
  );
}

import { React } from "react";
import { BiPlus } from "react-icons/bi";
import Modal from "../Modal/Modal";

export default function AddButton({
  menuOpen,
  setMenuOpen,
  children,
  Icon = BiPlus,
  classNameModal,
  classNameTop = "flex-grow max-w-screen-lg m-auto flex py-2 px-4 h-full justify-end content-center",
  classNamePlus = "text-3xl text-freisicht transition transform hover:scale-105 hover:text-darkGreen cursor-pointer ",
  className = "absolute right-0 flex h-16",
  buttonId = "add-button",
}) {
  return (
    <div className={className}>
      <div className={classNameTop}>
        <Icon
          className={classNamePlus}
          id={buttonId}
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        ></Icon>
      </div>
      {menuOpen && (
        <Modal
          onClose={() => {
            setMenuOpen(false);
          }}
          className={classNameModal}
        >
          {children}
        </Modal>
      )}
    </div>
  );
}

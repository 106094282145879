import { React } from "react";
import { ThreeCircles } from "react-loader-spinner";
// import { colors } from "tailwindcss/defaultTheme";

// todo: load color with variable (not working so used hard set color)
export default function Loaderfreisicht() {
  return (
    <div className="flex justify-center col-span-3 mt-2">
      <ThreeCircles color="#3B6501"></ThreeCircles>
    </div>
  );
}

import { React, useEffect, useState } from "react";
import InputCheckBox from "./InputCheckBox";
import InputGeneral from "./InputText";
import Dropdown from "./Dropdown";
import log from "../utils/Logger";
import EditBlockRichText from "./EditBlockRichText";
import InputMultiline from "./InputMultiline";
import Tooltip from "../utils/Tooltip";

export function EditBlock({
  title,
  type = "text",
  dropdownOptions = [],
  dropdownOptionKeys = [],
  parentInfo,
  tooltip,
  required,
  data,
  onChanged,
  classNameAddon,
  languageColorClass,
}) {
  const { accountAccess } = parentInfo;
  const [value, setValue] = useState(data);

  useEffect(() => {
    setValue(data);
  }, [data]);

  async function blurit(newValue) {
    if (type == "number") {
      newValue = parseFloat(newValue);
      if (isNaN(newValue)) newValue = 0;
    }
    log.debug("blurit newValue: ", newValue);
    setValue(newValue);
    onChanged(newValue);
  }

  async function checkboxClicked(event) {
    const newval = event.target.checked;
    setValue(newval);
    onChanged(newval);
  }

  async function dropDownChanged(event) {
    const newval = parseInt(event.target.value);
    setValue(newval);
    onChanged(newval);
  }

  const generalType = () => {
    return (
      <div>
        <InputGeneral
          value={value}
          type={type}
          disabled={accountAccess < 2}
          onChange={setValue}
          onBlur={blurit}
          classNameAddon={"w-full " + languageColorClass}
          required={required}
        />
      </div>
    );
  };

  const inputElement = (
    {
      dropdown: () => {
        return (
          <Dropdown
            value={value}
            options={dropdownOptions}
            optionkeys={dropdownOptionKeys}
            dropDownChanged={dropDownChanged}
            hasWriteaccess={accountAccess > 1}
          />
        );
      },
      checkbox: () => {
        return (
          <div>
            <InputCheckBox
              checkedValue={value}
              hasWriteaccess={accountAccess > 1}
              clicked={checkboxClicked}
              languageColorClass={languageColorClass}
            />
          </div>
        );
      },
      multiline: () => {
        return (
          <InputMultiline
            className={"inputStyle"}
            value={value}
            type={type}
            disabled={accountAccess < 2}
            onChange={setValue}
            onBlur={blurit}
            classNameAddon="w-full"
            maxlength={1000}
          />
        );
      },
      richtext: () => {
        return (
          <div>
            <EditBlockRichText
              value={value}
              disabled={accountAccess < 2}
              onChange={setValue}
              onBlur={blurit}
              languageColorClass={languageColorClass}
            />
          </div>
        );
      },
    }[type] || generalType
  )();

  return (
    <div
      className={
        "p-2 md:p-4 place-items-center place-content-center " + classNameAddon
      }
    >
      <Tooltip
        tooltip={tooltip}
        subtitle={title}
        tooltipId={`tooltip-${title}`}
      />
      {inputElement}
    </div>
  );
}

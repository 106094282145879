import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import InputCheckBox from "../InputCheckBox";
import ModalContent, {
  ModalErrorAlertInfo,
  ModalTitle,
} from "../Modal/ModalContent";
import Modal from "../Modal/Modal";
import ProjectLanguageElement from "./ProjectLanguageElement";
import { deleteTranslationForCollection } from "../../services/CollectionService";

export default function EditProjectLanguagesForm({
  collection,
  updateCollection,
  defaultLanguage,
  languages,
  setModalOpen,
}) {
  const getLanguagesStatus = () => {
    let languagesStatus = {};
    languages?.map((l) => {
      if (collection.name[l.code]) {
        languagesStatus[l.id] = true;
      } else {
        languagesStatus[l.id] = false;
      }
    });
    return languagesStatus;
  };
  const { t } = useTranslation();
  const [message, setMessage] = useState(false);
  const [acceptData, setAcceptData] = useState(undefined);
  const [activatedLanguages, setActivatedLanguages] = useState(() =>
    getLanguagesStatus()
  );
  const projectTitle = collection?.name[defaultLanguage];

  return (
    <Modal
      onClose={() => {
        setModalOpen(undefined);
        updateCollection();
      }}
    >
      <ModalContent>
        <ModalTitle title={t("multilangModalTitle") + projectTitle} />

        {languages && languages.length > 0 ? (
          <div className="col-span-6 mt-4">
            <div className="grid grid-cols-6 text-sm items-center mb-2">
              <div className="col-span-2 col-start-2">
                {languages.find((x) => x.code == defaultLanguage).name}:
              </div>
              <div className="px-4 h-5 text-darkGreen col-span-2 transition">
                <InputCheckBox checkedValue={true} hasWriteaccess={false} />
              </div>
            </div>
            {languages.map((language, i) => {
              if (language.code != defaultLanguage) {
                return (
                  <ProjectLanguageElement
                    language={language}
                    isLanguageActive={activatedLanguages[language.id]}
                    collection={collection}
                    setMessage={setMessage}
                    setAcceptData={setAcceptData}
                    availableLanguages={activatedLanguages}
                    setAvailableLanguages={setActivatedLanguages}
                    key={i}
                  />
                );
              }
            })}
          </div>
        ) : (
          <div className="col-span-6 text-sm col-start-2">
            {t("userRightNoSpecificRights")}
          </div>
        )}
      </ModalContent>
      {message && (
        <ModalErrorAlertInfo
          message={message}
          setMessage={setMessage}
          acceptData={acceptData}
          setAcceptData={setAcceptData}
          acceptFunction={async () => {
            setMessage(undefined);
            if (acceptData) {
              await deleteTranslationForCollection(collection.id, acceptData);
              setAcceptData(undefined);
              setActivatedLanguages({
                ...activatedLanguages,
                [acceptData]: false,
              });
            }
          }}
        ></ModalErrorAlertInfo>
      )}
    </Modal>
  );
}

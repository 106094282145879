import React from "react";
import "@google/model-viewer/dist/model-viewer";

export default function ThreeDeeModel({ url }) {
  const modelRef = React.useRef();

  return (
    <div className="col-span-6 pb-2 pr-8 md:pr-6 max-h-100 max-w-full h-auto w-auto">
      <model-viewer
        // className="model-viewer"
        className="h-screen w-screen"
        src={url}
        alt="3D Model"
        camera-controls
        ref={(ref) => {
          modelRef.current = ref;
        }}
      ></model-viewer>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { List } from "../../components/List/List";
import { WhitePage } from "../../components/Page/WhitePage";
import { ListRow } from "../../components/List/ListRow";
import { getAnalytics } from "../../services/AnalyticsService";
import log from "../../utils/Logger";
import DateRangeSelector from "../../utils/DateRangeSelector";
import { isSameDay, isWithinInterval } from "date-fns";
import AnalyticsLineChart from "../../components/Charts/AnalyticsLineChart";
import AnalyticsTourDetails from "./AnalyticsTourDetails";

export default function AnalyticsOverview({ loggedInUser }) {
  const [analytics, setAnalytics] = useState(undefined);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [tourItems, setTourItems] = useState([]);
  const [displayItems, setDisplayItems] = useState([]);
  const [sortListBy, setSortListBy] = useState(0);
  const [sortListByDir, setSortListByDir] = useState(1);
  const [chartData, setChartData] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState([]);

  const columnsOfDataToShow = ["name", "length", "tourDuration"];
  const columnWidths = [4, 3, 2, 1];

  const modules = loggedInUser.user.user.customerModules;

  async function loadData() {
    try {
      const data = await getAnalytics();
      if (data) {
        setAnalytics(data);
        setTourItems(data);
        let newData = setSelectionData(data, selectionRange);
        setDisplayItems(makeElementsFromData(newData));
      }
    } catch (error) {
      log.debug(error);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  const setSelectionData = (data, range) => {
    if (!data || !range) return [];
    const dataArray = Object.values(data);
    const filteredData = [];
    dataArray.map((tourData) => {
      const filteredTourData = [];
      tourData.map((singleTour) => {
        let dateOfTour = new Date(singleTour.createdAt);
        if (
          isWithinInterval(dateOfTour, {
            start: range.startDate,
            end: range.endDate,
          }) ||
          isSameDay(dateOfTour, range.startDate) ||
          isSameDay(dateOfTour, range.endDate)
        ) {
          filteredTourData.push(singleTour);
        }
      });
      filteredData.push(filteredTourData);
    });
    setTourItems(filteredData);
    let chartData = createChartData(filteredData, range);
    setChartData(chartData);
    return filteredData;
  };

  const makeElementsFromData = (data) => {
    const dataArray = Object.values(data);
    return dataArray.map((tourData) => {
      let medianDuration =
        tourData && tourData.length > 0
          ? tourData.sort((a, b) => a.tourDuration - b.tourDuration)[
              Math.floor(tourData.length / 2)
            ].tourDuration
          : 0;
      let numberOfDevices =
        tourData && tourData.length > 0
          ? new Set(tourData.map((x) => x.deviceId)).size
          : 0;

      return tourData[0]
        ? [
            onTourSelected,
            <div key={tourData[0].id}>{tourData[0].collection.name.de}</div>,
            <div key={tourData[0].id}>{tourData.length}</div>,
            <div key={tourData[0].id}>{medianDuration}</div>,
            <div key={tourData[0].id}>{numberOfDevices}</div>,
          ]
        : [];
    });
  };

  const createChartData = (data, range) => {
    log.debug("chartdata: ", data);
    const daysArray = getDaysArray(range.startDate, range.endDate);
    daysArray.map((dayData) => {
      data.map((tour) => {
        if (tour[0]) {
          let tourId = tour[0].collectionId;
          let toursOnDay = tour.filter((x) =>
            isSameDay(new Date(x.createdAt), new Date(dayData.date))
          );
          dayData[tourId] = toursOnDay.length;
        }
      });
    });
    return daysArray;
  };

  const getDaysArray = (start, end) => {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      const shortDate = dt.getDate() + "." + (dt.getMonth() + 1) + ".";
      arr.push({ date: new Date(dt), name: shortDate });
    }
    return arr;
  };

  const onChangeSelectionRange = (newRange) => {
    setSelectionRange(newRange.selection);
    let newData = setSelectionData(analytics, newRange.selection);
    setDisplayItems(makeElementsFromData(newData));
  };

  const onTourSelected = (row) => {
    // hack to get latest state in callback, see https://stackoverflow.com/questions/56782079/react-hooks-stale-state
    setTourItems((prevState) => {
      log.debug("selected coll: ", prevState[row]);
      setSelectedCollection(prevState[row]);
      return prevState;
    });
  };

  const resetCollection = () => {
    setSelectedCollection([]);
  };

  return modules.includes("ANALYTICS") ? (
    <WhitePage
      title={
        loggedInUser?.user.user.name
          ? loggedInUser?.user.user.name
          : "Ohne Namen"
      }
      user={loggedInUser && loggedInUser.user.user}
    >
      <div className="p-2">
        <DateRangeSelector
          value={selectionRange}
          onChange={onChangeSelectionRange}
        ></DateRangeSelector>
      </div>
      {selectedCollection && selectedCollection.length > 0 ? (
        <div>
          <div className="flex flex-cols py-3" onClick={resetCollection}>
            <BiChevronLeft className="w-10 text-3xl transition cursor-pointer text-freisicht hover:text-darkGreen " />
          </div>
          <AnalyticsTourDetails
            selectionRange={selectionRange}
            items={selectedCollection}
          ></AnalyticsTourDetails>
        </div>
      ) : (
        <div>
          <List
            items={displayItems}
            itemTagClass={ListRow}
            columnWidths={columnWidths}
            headerNames={[
              "Tour Name",
              "Aufrufe",
              "Dauer (Median)",
              "Total Geräte",
            ]}
            sortBy={sortListBy}
            sortByDir={sortListByDir}
            setSortBy={(sortByThis) => {
              let newSortByThis = sortListByDir;
              if (sortByThis === sortListBy) {
                newSortByThis = sortListByDir > 0 ? -1 : 1;
                setSortListByDir(newSortByThis);
              }
              setSortListBy(sortByThis);

              log.debug(
                "sortByThis",
                sortByThis,
                "columnsOfDataToShow[sortByThis]",
                columnsOfDataToShow[sortByThis],
                columnsOfDataToShow
              );
            }}
            noItemsMessage="Es gibt keine Analytics Daten"
          />
          <AnalyticsLineChart
            data={chartData}
            toursToDisplay={tourItems}
          ></AnalyticsLineChart>{" "}
        </div>
      )}
    </WhitePage>
  ) : (
    <WhitePage
      title={
        loggedInUser?.user.user.name
          ? loggedInUser?.user.user.name
          : "Ohne Namen"
      }
      user={loggedInUser && loggedInUser.user.user}
    >
      no access
    </WhitePage>
  );
}

import { React } from "react";
import ThreeDeeModel from "../../utils/3DModel";

export default function Media({ mediaSource, type = 0 }) {
  return (
    <>
      {type === 0 && (
        <img
          className="a col-span-6 pb-2 pr-8 md:pr-6 max-h-100 max-w-full h-auto w-auto"
          src={mediaSource}
        ></img>
      )}
      {type === 1 && (
        <video
          controls
          className="col-span-6 pb-2 pr-8 md:pr-6 max-h-100 max-w-full h-auto w-auto"
          src={mediaSource}
        ></video>
      )}
      {type === 2 && (
        <audio
          controls
          src={mediaSource}
          className="p-4 cursor-pointer"
        ></audio>
      )}
      {type === 3 && <ThreeDeeModel url={mediaSource} />}
    </>
  );
}

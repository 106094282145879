import { getFromApi, deleteToApi, getFileFromApi } from "./APIService";
import logger from "../utils/Logger";

export async function getExhibitions(collectionid) {
  logger.debug("getExhibitions");
  let res = await getFromApi(`collections/${collectionid}/exhibitions`, {});
  let exhibitions = res.exhibitions;
  return exhibitions;
}

export async function deleteExhibition(collectionid, exhibitionid) {
  logger.debug("deleteExhibition " + exhibitionid);
  const res = await deleteToApi(
    `collections/${collectionid}/exhibitions/${exhibitionid}`,
    {}
  );
  return res;
}

export async function exportExhibition(collectionid, exhibition) {
  logger.debug("export Exhibition " + exhibition.id);
  const res = await getFileFromApi(
    `collections/${collectionid}/exhibitions/${exhibition.id}/export`,
    {}
  );
  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `varcu_export_${exhibition.title}.xlsx`); //or any other extension
  document.body.appendChild(link);
  link.click();
  return res;
}

import { React, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import Search from "../../utils/Search";
import Dropdown from "../Dropdown";
import { getBackgroundColorForLanguage } from "../../utils/Multilang";
import Tooltip from "../../utils/Tooltip";

export default function Tabbar({
  elementsToFilter,
  onFilteredElements,
  locationToGo,
  openedAtStart = 1,
  showSearch = true,
  hiddenOnMobile = false,
  items,
  showMultilang = false,
  translationInfo,
  setTranslationInfo,
}) {
  const { t } = useTranslation();
  const [openTab, setOpenTab] = useState(openedAtStart);
  openTab;
  let navigate = useNavigate();
  if (items == null) {
    items = [
      {
        link: "/artworks/",
        title: t("tabbarWorks"),
      },
      {
        link: "/exhibitions/",
        title: t("tabbarExhibitions"),
      },
      {
        link: "/impressum/",
        title: t("tabbarImpressum"),
      },
    ];
  }
  function TabLink(props) {
    const { number, to, name } = props;

    const location = useLocation();
    return (
      <Link
        className={
          "text-s px-3 md:px-5 py-3 leading-normal block h-full " +
          (location.pathname.indexOf(to) > -1
            ? "text-freisicht  border-t border-r border-l bg-white"
            : "text-darkGreen transition hover:text-freisicht")
        }
        onClick={() => {
          setOpenTab({ number });
        }}
        to={to}
      >
        {name}
      </Link>
    );
  }

  const changeLanguage = (languageId) => {
    const newLang = translationInfo.languagesToShow.find(
      (x) => x.id == languageId
    );
    const languageName = newLang.code;
    const languageClassAddon = getBackgroundColorForLanguage(languageName);
    setTranslationInfo({
      ...translationInfo,
      collectionLanguage: languageName,
      languageColorClass: languageClassAddon,
      activeLanguageId: languageId,
    });
  };

  showSearch = showSearch && (window.innerWidth < 640 ? !hiddenOnMobile : true);

  return (
    <>
      <div className="flex bg-lightGray flex-wrap w-full font-semibold">
        <div className="w-full border-b border-freisicht">
          <div className="max-w-screen-lg m-auto flex flex-row place-content-between">
            <ul
              className="flex mb-0 list-none  flex-row flex-grow"
              role="tablist"
            >
              {locationToGo ? (
                <li className="-mb-px last:mr-0">
                  <div
                    className="flex flex-cols py-3"
                    onClick={() => {
                      navigate(locationToGo);
                    }}
                  >
                    <BiChevronLeft className="w-10 text-3xl transition cursor-pointer text-freisicht hover:text-darkGreen " />
                  </div>
                </li>
              ) : (
                ""
              )}
              {items.map((item, i) => {
                return (
                  <li className="-mb-px last:mr-0 " key={i}>
                    <TabLink to={item.link} name={item.title} number={i + 1} />
                  </li>
                );
              })}
            </ul>
            {showMultilang && (
              <div className="flex justify-end items-center relative">
                <Dropdown
                  value={translationInfo?.activeLanguageId}
                  options={translationInfo.languagesToShow?.map((l) => l.name)}
                  optionkeys={translationInfo.languagesToShow?.map((l) => l.id)}
                  hasWriteaccess={true}
                  dropDownChanged={async (event) => {
                    //event.preventDefault();
                    const newVal = parseInt(event.target.value);
                    changeLanguage(newVal);
                  }}
                  languageColorClass={translationInfo?.languageColorClass}
                />
                <Tooltip
                  tooltip={t("tabbarTranslation")}
                  subtitle={""}
                  tooltipId="tooltip-tabbar"
                />
              </div>
            )}
            {showSearch ? (
              <Search
                elementsToFilter={elementsToFilter}
                onFilteredElements={onFilteredElements}
              ></Search>
            ) : (
              <div className="w-64"></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

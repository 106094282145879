import { getFromApi } from "./APIService";
import logger from "../utils/Logger";

export async function getAnalytics(customerId) {
  logger.debug("getAnalytics");
  let res = await getFromApi(`analytics/${customerId}`, {});
  let analytics = groupBy(res, "collectionId");
  logger.debug("analytics, ", analytics);
  return analytics;
}

const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

import { React } from "react";

export default function Dropdown({
  value,
  options,
  optionkeys,
  className,
  hasWriteaccess,
  dropDownChanged,
  languageColorClass = "bg-lightGray",
}) {
  className = hasWriteaccess
    ? "text-gray text-sm border-none w-full " + languageColorClass
    : "text-gray text-sm border-none w-full cursor-default " +
      languageColorClass;

  return (
    <div>
      <select
        className={className}
        value={value}
        disabled={!hasWriteaccess}
        onChange={dropDownChanged}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {options && options.length ? (
          options.map((o, index) => {
            let val =
              optionkeys && optionkeys[index] ? optionkeys[index] : index;
            return (
              <option key={val} value={val}>
                {o}
              </option>
            );
          })
        ) : (
          <option key={0} value={0}>
            loading..
          </option>
        )}
      </select>
    </div>
  );
}

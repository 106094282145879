import readXlsxFile from "read-excel-file";

export async function getHeaderNames(file) {
  const rows = await readXlsxFile(file);
  let headerRow = rows[0];
  if (headerRow && headerRow.length > 0) {
    headerRow = headerRow.map((col, index) => {
      if (!col) {
        return numberToLetters(index);
      } else {
        return col;
      }
    });
  }
  return headerRow;
}

function numberToLetters(num) {
  let letters = "";
  while (num >= 0) {
    letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[num % 26] + letters;
    num = Math.floor(num / 26) - 1;
  }
  return letters;
}

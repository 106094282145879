import axios from "axios";
import log from "../utils/Logger";

let accessToken = undefined;
let refreshToken = undefined;
let user = undefined;

export function setTokens(access, refresh) {
  accessToken = access;
  refreshToken = refresh;

  const oldFSL = localStorage.getItem("fsl");
  if (oldFSL) {
    try {
      const oldFSLj = JSON.parse(oldFSL);
      oldFSLj.accessToken = access;
      oldFSLj.refreshToken = refresh;
      localStorage.setItem("fsl", JSON.stringify(oldFSLj));
    } catch (e) {
      log.error("wasnt able to parse fsl in setToken");
    }
  }
}

export function setUser(webtoken) {
  log.debug("webtoken", webtoken);
  user = webtoken.user;
}

export function getUser() {
  return user;
}

//const REACT_APP_apiserver = "http://localhost:3001";
//const REACT_APP_apiserver = "https://dev-varcu-backend.jcloud.ik-server.com";
const REACT_APP_apiserver = "https://dev-backend.varcu.app";
//const REACT_APP_apiserver = "https://prod-backend.varcu.app";
const REACT_APP_apiserverurl = REACT_APP_apiserver + "/api";
export async function getFromApi(route, data, noAccessLogsout) {
  return await workWithApi({
    httpMethod: "get",
    route,
    data,
    noAccessLogsout,
  });
}

export async function postToApi(route, data, noAccessLogsout) {
  return await workWithApi({
    httpMethod: "post",
    route,
    data,
    noAccessLogsout,
  });
}

export async function putToApi(route, data, noAccessLogsout) {
  return await workWithApi({
    httpMethod: "put",
    route,
    data,
    noAccessLogsout,
  });
}

export async function deleteToApi(route, noAccessLogsout) {
  return await workWithApi({
    httpMethod: "delete",
    route,
    noAccessLogsout,
  });
}

export async function uploadFileToApi(route, formData, noAccessLogsout) {
  log.debug("uploadFileToApi uploads this data", formData.get("images"));
  //debugger;
  return await workWithApi({
    httpMethod: "post",
    route,
    data: formData,
    extraHeaders: {
      "Content-Type": "multipart/form-data",
    },
    noAccessLogsout,
  });
}

async function tryRefreshToken() {
  try {
    const resultFromRefresh = await postToApi("refresh-token", {
      refresh_token: refreshToken,
      user: user,
    });
    if (
      resultFromRefresh &&
      resultFromRefresh.accessToken &&
      resultFromRefresh.newRefreshToken
    ) {
      setTokens(
        resultFromRefresh.accessToken,
        resultFromRefresh.newRefreshToken
      );
      return true;
    }
    return false;
  } catch (e) {
    log.debug("e", e);
    return e;
  }
}

export async function workWithApi({
  httpMethod,
  route,
  data,
  retry,
  extraHeaders,
  noAccessLogsout = true,
}) {
  log.debug("getFromApi method", route, data);
  return new Promise((resolve, reject) => {
    try {
      const config = {
        headers: {
          ...(extraHeaders ? extraHeaders : {}),
          Authorization: "Bearer " + accessToken,
        },
      };
      (httpMethod === "delete"
        ? axios[httpMethod](REACT_APP_apiserverurl + "/" + route, config)
        : axios[httpMethod](
            REACT_APP_apiserverurl + "/" + route,
            httpMethod === "get" ? config : data,
            config
          )
      )
        .then(function (response) {
          log.debug(
            "axios got answer for " + route + " from api:",
            response.data
          );
          resolve(response.data);
        })
        .catch(async function (error) {
          //   log.debug("axis had error", error);
          log.debug("axis had error", error.response);

          if (error.response && error.response.status === 401) {
            log.debug("retry", retry);
            if (!retry) {
              const refreshed = await tryRefreshToken();
              log.debug("refreshed", refreshed);
              if (refreshed) {
                resolve(
                  await workWithApi({
                    httpMethod,
                    route,
                    data,
                    retry: 1,
                    noAccessLogsout,
                  })
                );
              } else {
                reject(error.response);
              }
            } else {
              if (noAccessLogsout) {
                window.location.href =
                  window.location.protocol +
                  "//" +
                  window.location.host +
                  "/logout";
              }
              reject(error.response);
            }
          } else if (error.response && error.response.status === 413) {
            resolve(413);
          } else {
            if (error.response) {
              reject(error.response);
            } else if (error.message) {
              reject(error.message);
            }
          }
        });
    } catch (e) {
      log.error("axis had error1", e);
      reject(e);
    }
  });
}

export async function getFileFromApi(route, data) {
  log.debug("getFileFromApi method", route, data);
  return new Promise((resolve, reject) => {
    try {
      const config = {
        responseType: "arraybuffer",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };
      axios
        .get(REACT_APP_apiserverurl + "/" + route, config)
        .then(function (response) {
          log.debug("axios got answer for " + route + " from api:", response);
          resolve(response);
        })
        .catch(async function (error) {
          log.error("axis had error", error.response);
        });
    } catch (e) {
      log.error("axis had error1", e);
      reject(e);
    }
  });
}

export const REACT_APP_API_SERVER = REACT_APP_apiserver;

import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetUserAccessName } from "../../utils/UserRights";
import {
  addAccess,
  editAccess,
  getAccount,
  deleteAccess,
} from "../../services/UserService";
import DefaultButton from "../Button/DefaultButton";
import log from "../../utils/Logger";
import ModalContent, {
  ModalErrorAlertInfo,
  ModalSubTitle,
  ModalTitle,
} from "../Modal/ModalContent";
import DeleteButton from "../Button/DeleteButton";

export default function EditUserForm({ data, account, collections, language }) {
  const { t } = useTranslation();
  const [accountAccessId, setaccountAccess] = useState(
    data ? data.accountAccess : undefined
  );
  const [specialAccesslist, setSpecialAccesslist] = useState([]);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [message, setMessage] = useState(false);
  const [acceptData, setAcceptData] = useState(undefined);

  //get special accesrights for this account
  // set username and default access
  log.debug("data", account, collections);

  async function loadData() {
    const specAccess = await getAccount(account.accountRole.accountId);
    log.debug("specialAccesslist res", specAccess);
    setSpecialAccesslist(specAccess);
  }

  useEffect(() => {
    loadData();
  }, []);

  const role = account.accountRole.role;

  const onButtonClicked = async () => {
    specialAccesslist &&
    specialAccesslist.length &&
    specialAccesslist.reduce((ret, val) => {
      return ret || val.collectionId === parseInt(accountAccessId, 10);
    }, false)
      ? await EditSpecialAccess(
          specialAccesslist.filter((access) => {
            return access.collectionId === parseInt(accountAccessId, 10);
          })
        )
      : await AddSpecialAccess(selectedOption);
    loadData();
  };

  async function AddSpecialAccess(accessType) {
    const accountid = account.id;
    const collectionid = parseInt(accountAccessId, 10);
    try {
      const res = await addAccess({
        accountid,
        accessType,
        collectionid,
      });
      log.debug("AddedAccess res", res);
    } catch (error) {
      log.error("addAccess error", error);
    }
  }

  async function EditSpecialAccess(accessid) {
    const accountid = account.id;
    accessid = accessid[0].id;
    const collectionid = parseInt(accountAccessId, 10);
    try {
      const res = await editAccess({
        accountid,
        selectedOption,
        collectionid,
        accessid,
      });
      log.debug("AddAccess res", res);
    } catch (error) {
      log.error("editAccess error", error);
    }
  }

  async function DeleteSpecialAccess(access) {
    const accountid = account.id;
    const accessid = access.id;
    try {
      const res = await deleteAccess(accountid, accessid);
      log.debug("DeleteAccess res", res);
    } catch (error) {
      log.error("addAccess error", error);
    }
  }

  const CustomRadioButtons = (value, name) => {
    return (
      <div className="col-span-1">
        <input
          className="mr-2 text-gray focus:ring-gray text-gray border-gray"
          type="radio"
          value={value}
          checked={selectedOption === value}
          onChange={(event) => {
            setSelectedOption(event.target.value);
          }}
        />
        {name}
      </div>
    );
  };

  return (
    <div>
      <ModalContent>
        <ModalTitle title={t("userRightBy") + account.name} />
        <ModalSubTitle subtitle={t("userRightGeneralRights")} />
        <div className="col-span-6 col-start-2 text-sm">
          {GetUserAccessName({ role })}
        </div>
        <ModalSubTitle subtitle={t("userRightProjectRights")} />
        <div className="col-span-2 col-start-2 self-center text-sm">
          {t("userRightProject")}
        </div>
        <div className="col-span-3">
          <select
            className="w-full text-gray bg-lightGray text-sm border-none"
            value={accountAccessId}
            onChange={(event) => {
              setaccountAccess(event.target.value);
            }}
          >
            <option>{t("userRightChooseProject")}</option>
            {collections.map((col) => {
              return (
                <option key={col.id} value={col.id}>
                  {col.name[language]}
                </option>
              );
            })}
          </select>
        </div>
        {accountAccessId ? (
          <>
            <div className="col-span-2 col-start-2 text-sm"></div>
            <div className="grid grid-cols-3 col-span-3 text-sm">
              {CustomRadioButtons("0", t("userRightNone"))}
              {CustomRadioButtons("1", t("userRightRead"))}
              {CustomRadioButtons("2", t("userRightWrite"))}
            </div>
          </>
        ) : (
          ""
        )}
        {specialAccesslist && specialAccesslist.length > 0 ? (
          <div className="col-span-6 mt-4">
            {specialAccesslist.map((specialAccess, i) => {
              const accestype = "" + specialAccess.accessType;
              return (
                <div key={i} className="grid grid-cols-6 text-sm items-center">
                  <div className="col-span-2 col-start-2">
                    {specialAccess.collection?.name?.[language]}:
                  </div>
                  <div className="px-4 h-5 text-darkGreen col-span-2 transition">
                    {GetUserAccessName({ role: accestype })}
                  </div>
                  <DeleteButton
                    onButtonClicked={async () => {
                      setMessage(t("userRightConfirmDelete"));
                      setAcceptData(specialAccess);
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="col-span-6 text-sm col-start-2">
            {t("userRightNoSpecificRights")}
          </div>
        )}
      </ModalContent>
      <DefaultButton
        text={t("userRightConfirm")}
        onButtonClicked={onButtonClicked}
      ></DefaultButton>
      {message && (
        <ModalErrorAlertInfo
          message={message}
          setMessage={setMessage}
          acceptData={acceptData}
          setAcceptData={setAcceptData}
          acceptFunction={async () => {
            setMessage(undefined);
            await DeleteSpecialAccess(acceptData);
            setAcceptData(undefined);
            loadData();
          }}
        ></ModalErrorAlertInfo>
      )}
    </div>
  );
}

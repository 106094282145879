import {
  getFromApi,
  putToApi,
  postToApi,
  deleteToApi,
  getUser,
} from "./APIService";
import log from "../utils/Logger";

var timeOptions = { hour: "2-digit", minute: "2-digit" };

export async function getCollections() {
  let res = await getFromApi("collections?multilang=true", {});
  log.debug("get collections: ", res);
  res = res.collections.map((c) => {
    let date = new Date(c.updatedAt);
    return {
      ...c,
      updatedAt:
        date.toLocaleDateString("de-DE") +
        ", " +
        date.toLocaleTimeString("de-DE", timeOptions),
    };
  });
  return res;
}

export async function getAccountAccessForCollections() {
  let res = await getFromApi("collections?multilang=true", {});
  log.debug("get collections: ", res);
  res = res.accountAccess;
  log.debug("accountaccess in collection ", res);
  return res;
}

export async function getCollection(collectionid) {
  log.debug("getCollectionid ", collectionid);
  const res = await getFromApi(
    `collections/${collectionid}?multilang=true`,
    {}
  );
  const collection = res.collection;
  res.collection.updatedAt =
    new Date(collection.updatedAt).toLocaleDateString("de-DE") +
    ", " +
    new Date(collection.updatedAt).toLocaleTimeString("de-DE", timeOptions);
  res.collection.collectible = collection.collectible.map((c) => {
    let date = new Date(c.updatedAt);
    return {
      ...c,
      updatedAt:
        date.toLocaleDateString("de-DE") +
        ", " +
        date.toLocaleTimeString("de-DE", timeOptions),
    };
  });
  // TODO: use accountAccess to show/hide write buttons
  log.debug("accountaccess in collection ", res.accountAccess);
  return res;
}

export async function addCollection(name) {
  const newCollection = {
    name,
    customerId: getUser.customerId,
  };
  log.debug("addCollection");
  const res = await postToApi("collections", newCollection);
  return res;
}

export async function saveCollection(collectionid, collection, form) {
  log.debug("saveCollectionid ", collectionid);
  log.debug("saveCollection ", collection);
  log.debug("saveform ", form);

  // eslint-disable-next-line no-unused-vars
  let { numberOfCollectibles, ...partialCollection } = collection;
  if (form != null) {
    partialCollection = collection;
  } else {
    partialCollection.collectible = null;
  }

  log.debug("partialCollection ", partialCollection);
  const res = await putToApi(`collections/${collectionid}`, partialCollection);
  return res;
}

export async function deleteCollection(collectionid) {
  log.debug("deleteCollectionid ", collectionid);
  const res = await deleteToApi(`collections/${collectionid}`, {});
  return res;
}

export async function createTranslationForCollection(collectionid, languageId) {
  // '/collections/:collectionid/translations/:languageid'
  log.debug("createTranslation ", collectionid);
  const res = await postToApi(
    `collections/${collectionid}/translations/${languageId}`,
    {}
  );
  return res;
}

export async function deleteTranslationForCollection(collectionid, languageId) {
  // '/collections/:collectionid/translations/:languageid'
  log.debug("deleteTranslation ", collectionid);
  const res = await deleteToApi(
    `collections/${collectionid}/translations/${languageId}`,
    {}
  );
  return res;
}

export async function copyCollection(collectionid) {
  log.debug("copyCollectionid ", collectionid);
  const res = await getFromApi(`collections/${collectionid}/copy`, {});
  return res;
}

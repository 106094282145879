import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Artworks from "./pages/Collection/Artworks";
import ArtWorkDetails from "./pages/Collection/ArtWorkDetails";
import ArtWorkOverlay from "./pages/Collection/ArtWorkOverlay";

import Exhibitions from "./pages/Collection/Exhibitions";
import Impressum from "./pages/Collection/Impressum";
import Collections from "./pages/Projects";
import { useState } from "react";
import { LoginProvider } from "./components/Login/LoginProvider";
import Useradmin from "./pages/Users/Useradmin";
import AnalyticsOverview from "./pages/Analytics/AnalyticsOverview";
import Modal from "./components/Modal/Modal";
import log from "./utils/Logger";
import { ModalErrorAlertInfoMessages } from "./components/Modal/ModalContent";
import { getBackgroundColorForLanguage } from "./utils/Multilang";
import Settings from "./pages/Settings/Settings";

function Logout({ setLoggedInUser }) {
  localStorage.removeItem("fsl");
  useEffect(() => {
    setLoggedInUser(undefined);
  }, []);
  return <Navigate replace to="/"></Navigate>;
}

function App() {
  const [loggedInUser, setLoggedInUser] = useState(undefined);
  const [translationInfo, setTranslationInfo] = useState({});
  const [error, setError] = useState(undefined);
  // TODO: get this from backend
  const languagesAvailable = [
    { id: 1, code: "de", name: "DE" },
    { id: 2, code: "en", name: "EN" },
    { id: 3, code: "fr", name: "FR" },
  ];

  useEffect(() => {
    window.addEventListener("error", (error) => {
      error &&
        error.data &&
        error.data.errorMessage &&
        setError(error.data.errorMessage);
      if (error == "Network Error") {
        setError("Netzwerkfehler. Prüfe deine Internetverbindung");
        log.error("error Netzwerk", error);
      } else if (error.data == undefined) {
        setError("Etwas ist schiefgelaufen");
        log.error("error schiefgelaufen", error);
      }
    });

    window.addEventListener("unhandledrejection", (error) => {
      error &&
        error.reason &&
        error.reason.data &&
        error.reason.data.errorMessage &&
        setError(error.reason.data.errorMessage);
      if (error && error.reason == "Network Error") {
        setError("Netzwerkfehler. Prüfe deine Internetverbindung");
        log.debug("unhandledrejection Netzwerkfehler", error);
      } else if (error && error.reason.status === 401) {
        setError("Passwort oder Benutzername falsch");
        log.debug("Login Error ", error.reason);
      } else if (error && error.reason.status == 429) {
        setError("Zu viele Anmeldeversuche, versuche es später wieder.");
        log.debug("unhandledrejection Too many requests", error);
      } else if (error.reason.data == undefined) {
        setError("Etwas ist schiefgelaufen");
        log.debug("unhandledrejection schiefgelaufen", error);
      }
    });
  }, []);

  return (
    <div className=" h-screen">
      <LoginProvider
        loggedInUser={loggedInUser}
        onLogin={(loginData) => {
          setLoggedInUser(loginData);
          const modules = loginData.user.user.customerModules;
          const defaultLanguage = loginData.user.user.customerDefaultLanguage;
          const languageColorClass = modules.includes("MULTILANG")
            ? getBackgroundColorForLanguage(defaultLanguage)
            : "bg-lightGray";
          setTranslationInfo({
            collectionLanguage: defaultLanguage,
            languageColorClass,
            languagesAvailable,
            activeLanguageId: 0,
            languagesToShow: languagesAvailable,
          });
        }}
      >
        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <Navigate
                  replace
                  to="/projects"
                  translationInfo={translationInfo}
                  setTranslationInfo={setTranslationInfo}
                />
              }
            ></Route>
            <Route
              exact
              path="/logout"
              element={<Logout setLoggedInUser={setLoggedInUser} />}
            ></Route>
            <Route
              path="/useradmin"
              element={<Useradmin loggedInUser={loggedInUser} />}
            ></Route>
            <Route
              path="/settings"
              element={<Settings loggedInUser={loggedInUser} />}
            ></Route>
            <Route
              path="/analytics"
              element={<AnalyticsOverview loggedInUser={loggedInUser} />}
            ></Route>

            <Route
              path="/projects"
              element={
                <Collections
                  loggedInUser={loggedInUser}
                  translationInfo={translationInfo}
                  setTranslationInfo={setTranslationInfo}
                />
              }
            ></Route>
            <Route
              path="/artWorkDetail/:id/:cid/overlay"
              element={
                <ArtWorkOverlay
                  loggedInUser={loggedInUser}
                  translationInfo={translationInfo}
                  setTranslationInfo={setTranslationInfo}
                />
              }
            ></Route>
            <Route
              path="/artWorkDetail/:id/:cid/details"
              element={
                <ArtWorkDetails
                  loggedInUser={loggedInUser}
                  translationInfo={translationInfo}
                  setTranslationInfo={setTranslationInfo}
                />
              }
            ></Route>
            <Route
              path="/artworks/:id"
              element={
                <Artworks
                  loggedInUser={loggedInUser}
                  translationInfo={translationInfo}
                  setTranslationInfo={setTranslationInfo}
                />
              }
            ></Route>
            <Route
              path="/exhibitions/:id"
              element={
                <Exhibitions
                  loggedInUser={loggedInUser}
                  translationInfo={translationInfo}
                  setTranslationInfo={setTranslationInfo}
                />
              }
            ></Route>
            <Route
              path="/impressum/:id"
              element={
                <Impressum
                  loggedInUser={loggedInUser}
                  translationInfo={translationInfo}
                  setTranslationInfo={setTranslationInfo}
                />
              }
            ></Route>
          </Routes>
        </Router>
      </LoginProvider>
      {error && (
        <Modal
          onClose={() => {
            setError(undefined);
          }}
        >
          <ModalErrorAlertInfoMessages
            message={error}
            confirm={() => {
              setError(undefined);
            }}
          ></ModalErrorAlertInfoMessages>
        </Modal>
      )}
    </div>
  );
}

export default App;

import { React } from "react";

export default function InputCheckBox({
  checkedValue,
  clicked,
  className,
  hasWriteaccess,
  languageColorClass,
}) {
  // TODO: find better solution to set color of checkbox when checked.
  languageColorClass =
    languageColorClass && languageColorClass != "bg-lightGray"
      ? " text-" + languageColorClass.slice(3)
      : " text-gray ";

  className = hasWriteaccess
    ? "placeholder transform hover:scale-105 text-gray bg-lightGray focus:ring-gray text-sm border-none " +
      languageColorClass
    : " text-middleGray bg-middleGray text-sm border-none cursor-pointer ";

  return (
    <input
      className={className + languageColorClass}
      type="checkbox"
      checked={checkedValue}
      onChange={() => {}}
      onClick={(event) => {
        event.stopPropagation();
        clicked(event);
      }}
      disabled={!hasWriteaccess}
    />
  );
}

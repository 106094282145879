import { React } from "react";
import Menu from "../Menu/Menu";
import { BiX, BiDotsHorizontalRounded } from "react-icons/bi";
import log from "../../utils/Logger";

export function ListRowMenu({ data, isOpen, setIsOpen, classAddon }) {
  const CoolIcon = isOpen ? BiX : BiDotsHorizontalRounded;
  return (
    <div
      className="relative flex text-3xl text-freisicht transition hover:text-darkGreen fs-dots"
      onClick={(event) => {
        log.debug("clickediclick");
        event.stopPropagation();
        return false;
      }}
    >
      <CoolIcon
        onClick={() => {
          log.debug("isOpen ", isOpen);
          setIsOpen(!isOpen);
        }}
        className={isOpen ? "z-30 " : "z-1"}
      />
      {isOpen && (
        <Menu
          className={"w-36 text-sm absolute top-0 " + classAddon}
          data={data}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      )}
    </div>
  );
}

import { React } from "react";
import { REACT_APP_API_SERVER } from "../../services/APIService";
import { ModalTitle } from "./ModalContent";

export default function ModalImage({ media, title = "" }) {
  return (
    <>
      {title != "" ? <ModalTitle title={title} /> : ""}
      {media ? (
        <img
          src={REACT_APP_API_SERVER + media.url}
          className="col-span-6 pb-2 max-h-60 max-w-full h-auto w-auto"
        ></img>
      ) : (
        <div className="col-span-6">Kein Bild vorhanden</div>
      )}
    </>
  );
}

import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultButton from "../Button/DefaultButton";
import FileUpload from "../Modal/FileUpload";
import Loaderfreisicht from "../../utils/Loaderfreisicht";
import ModalContent, { ModalSubTitle, ModalTitle } from "../Modal/ModalContent";
import {
  acceptExcel,
  acceptImgTrigger,
  acceptOverlayFiles,
} from "../../utils/Fileformats";
import { getHeaderNames } from "../../utils/readExcelFile";
import log from "../../utils/Logger";
import MapColumnsToDataForm from "./MapColumnsToDataForm";
import OverlayUploadTabs from "../Modal/OverlayUploadTabs";
import { EditBlock } from "../EditBlock";

export default function UploadForm({
  onSave,
  data,
  isOverlay,
  result,
  loading = false,
  customerModules,
  showTextOption,
}) {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState(
    data ? data.selectedFiles : undefined
  );
  const [selectedInfoFile, setSelectedInfoFile] = useState(
    data ? data.setSelectedInfoFile : undefined
  );

  const [allfilesAccepted, setAllfilesAccepted] = useState(undefined);
  const [columnHeaderNames, setColumnHeaderNames] = useState(false);
  const [openTab, setOpenTab] = useState(1);
  const [overlaytext, setOverlaytext] = useState("");

  const uploadTooLargeError = result && result === 413;

  const resultHadDoublePix =
    result &&
    result.alreadyExistingPictures &&
    !!result.alreadyExistingPictures.length;
  const resultHadWrongPix =
    result &&
    result.sizeNotValidPictures &&
    !!result.sizeNotValidPictures.length;

  const resultHadPixNotInInfofile =
    result &&
    result.picturesNotInInfoFile &&
    !!result.picturesNotInInfoFile.length;

  const resultHadInvalidOverlayFiles =
    result && result.invalidOverlays && !!result.invalidOverlays.length;

  // needs to have same order as in backend
  const collectibleFieldsToFill = [
    "bildcode",
    "artist",
    "title",
    "massangaben",
    "date",
    "copyright",
    "creditline",
    "info",
    "inventarnummer",
    "materialTechnique",
    "tags",
    "geoLocation",
  ];

  const onButtonClicked = async () => {
    log.debug("bla ok button clicked");
    if (selectedInfoFile) {
      // set modal with mapping of collectible fields to excel columns
      const headerNames = await getHeaderNames(selectedInfoFile[0]);
      if (headerNames) {
        setColumnHeaderNames(headerNames);
        return;
      }
    }
    if (openTab == 2) {
      if (!overlaytext) return;
      const textblob = new Blob([overlaytext], { type: "text/plain" });
      const textfile = new File([textblob], "overlaytext.txt");
      const myFiles = [];
      myFiles.push(textfile);
      onSave({ selectedFiles: myFiles });
      return;
    }

    selectedFiles &&
      onSave({
        selectedFiles,
        selectedInfoFile,
      });
  };

  const saveAfterMapping = (dataColumnMap) => {
    selectedFiles &&
      onSave({
        selectedFiles,
        selectedInfoFile,
        dataColumnMap,
      });
  };

  function Result({ message, mapData = undefined }) {
    return (
      <div className="pb-4">
        {message}
        {mapData
          ? mapData.map((pic, index) => {
              return (
                <li className="list-inside text-xs" key={index}>
                  {pic}
                </li>
              );
            })
          : ""}
      </div>
    );
  }

  function ShowResult({ result }) {
    return (
      <div className="p-4">
        {uploadTooLargeError ? (
          <div className="font-bold pb-4">{t("uploadFormError")}</div>
        ) : (
          <div className="font-bold pb-4">{t("uploadFormFinished")}</div>
        )}
        {resultHadDoublePix && (
          <Result
            message={
              result.alreadyExistingPictures.length +
              t("uploadFormFileAlreadyExists")
            }
            mapData={result.alreadyExistingPictures}
          ></Result>
        )}
        {resultHadWrongPix && (
          <Result
            message={
              result.sizeNotValidPictures.length +
              t("uploadFormCouldntReadSize")
            }
            mapData={result.sizeNotValidPictures}
          ></Result>
        )}
        {resultHadPixNotInInfofile && (
          <Result
            message={
              result.picturesNotInInfoFile.length +
              t("uploadFormFilesNotInInfofile")
            }
            mapData={result.picturesNotInInfoFile}
          ></Result>
        )}
        {resultHadInvalidOverlayFiles && (
          <Result
            message={
              result.invalidOverlays.length + t("uploadFormInvalidFormat")
            }
            mapData={result.invalidOverlays}
          ></Result>
        )}
        {uploadTooLargeError && (
          <Result message={t("uploadFormFileSize")}></Result>
        )}
        {!resultHadDoublePix &&
          !resultHadWrongPix &&
          !resultHadPixNotInInfofile &&
          !resultHadInvalidOverlayFiles &&
          !uploadTooLargeError && (
            <Result message={t("uploadFormSuccess")}></Result>
          )}
      </div>
    );
  }

  return (
    <div>
      {result ? (
        <ShowResult result={result} />
      ) : columnHeaderNames ? (
        <MapColumnsToDataForm
          columnHeaders={columnHeaderNames}
          dataFields={collectibleFieldsToFill}
          onSave={saveAfterMapping}
          loading={loading}
          customerModules={customerModules}
        />
      ) : (
        <>
          <ModalContent>
            {isOverlay ? (
              <>
                {showTextOption && (
                  <OverlayUploadTabs
                    openTab={openTab}
                    setOpenTab={setOpenTab}
                  ></OverlayUploadTabs>
                )}

                {openTab == 1 ? (
                  <>
                    <ModalTitle title={t("uploadFormTitleFiles")} />
                    <FileUpload
                      filesToAccept={acceptOverlayFiles()}
                      saveFunction={setSelectedFiles}
                      setAllfilesAccepted={setAllfilesAccepted}
                    ></FileUpload>
                  </>
                ) : (
                  <>
                    <ModalTitle title={t("uploadFormTitleText")} />
                    <div className="col-span-6">
                      <EditBlock
                        type="richtext"
                        title="Textoverlay"
                        parentInfo={{ accountAccess: 3 }}
                        data={overlaytext}
                        onChanged={setOverlaytext}
                      ></EditBlock>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <ModalSubTitle subtitle={t("uploadFormTitlePictures")} />
                <FileUpload
                  filesToAccept={acceptImgTrigger()}
                  saveFunction={setSelectedFiles}
                  setAllfilesAccepted={setAllfilesAccepted}
                ></FileUpload>
                <ModalSubTitle
                  subtitle={t("uploadFormTitleInfoFile")}
                  tooltip={t("uploadFormTooltipInfofile")}
                />
                <FileUpload
                  filesToAccept={acceptExcel()}
                  saveFunction={setSelectedInfoFile}
                  maxFiles="1"
                  setAllfilesAccepted={setAllfilesAccepted}
                ></FileUpload>
              </>
            )}
          </ModalContent>

          {loading ? (
            <Loaderfreisicht></Loaderfreisicht>
          ) : (
            <DefaultButton
              text={
                openTab === 1 ? t("uploadFormUpload") : t("uploadFormAddText")
              }
              onButtonClicked={
                allfilesAccepted && allfilesAccepted.length > 0 && openTab === 1
                  ? () => {}
                  : onButtonClicked
              }
              disabled={
                allfilesAccepted && allfilesAccepted.length > 0
                  ? " opacity-50 cursor-not-allowed "
                  : ""
              }
            ></DefaultButton>
          )}
        </>
      )}
    </div>
  );
}

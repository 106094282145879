export function calculateNewIndexNumber(sourceIndex, destinationIndex, items) {
  if (sourceIndex > destinationIndex) {
    return destinationIndex > 0
      ? Math.round(
          (items[destinationIndex].indexNumber +
            items[destinationIndex - 1].indexNumber) /
            2
        )
      : items[destinationIndex].indexNumber + 1024;
  } else {
    return destinationIndex < items.length - 1
      ? Math.round(
          (items[destinationIndex].indexNumber +
            items[destinationIndex + 1].indexNumber) /
            2
        )
      : items[destinationIndex].indexNumber / 2;
  }
}

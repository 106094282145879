import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Tabbar from "../../components/Header/Tabbar";
import { WhitePage } from "../../components/Page/WhitePage";
import log from "../../utils/Logger";
import SettingTags from "../../components/Tags/SettingTags";

export default function Settings({ loggedInUser }) {
  const { t } = useTranslation();
  const [changesSaved, setChangesSaved] = useState(undefined);

  log.debug("loggedInUser", loggedInUser.user.user);
  const customerId = loggedInUser.user.user.customerId;
  // const modules = loggedInUser.user.user.customerModules;

  const subMenu = (
    <Tabbar
      onFilteredElements={{}}
      elementsToFilter={[]}
      showSearch={false}
      locationToGo="/projects"
      items={[]}
    ></Tabbar>
  );

  //TODO:accountaccess hinzufügen, damit nur jene ändern können, die (super)admins sind

  return (
    <WhitePage
      title={t("menuSettings") + " " + loggedInUser.user.user.customerName}
      user={loggedInUser && loggedInUser.user.user}
      changesSaved={changesSaved}
      subMenu={subMenu}
    >
      {/* {modules.includes("tags") && ( */}
      <SettingTags customerId={customerId} setChangesSaved={setChangesSaved} />
      {/* )} */}
    </WhitePage>
  );
}

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { List } from "../../components/List/List";
import { WhitePage } from "../../components/Page/WhitePage";
import AddButton from "../../components/Button/AddButton";
import { ListRow } from "../../components/List/ListRow";
import {
  addAccount,
  deleteAccount,
  getAccounts,
  saveAccount,
} from "../../services/UserService";
import { sortData } from "../../utils/sort";
import Tabbar from "../../components/Header/Tabbar";
import NewUserForm from "../../components/Form/NewUserForm";
import EditUserForm from "../../components/Form/EditUserForm";
import { getCollections } from "../../services/CollectionService";
import { Options } from "../../utils/UserRights";
import Modal from "../../components/Modal/Modal";
import log from "../../utils/Logger";
import { ModalErrorAlertInfo } from "../../components/Modal/ModalContent";
import Dropdown from "../../components/Dropdown";
import DeleteButton from "../../components/Button/DeleteButton";

export default function Useradmin({ loggedInUser }) {
  const { t } = useTranslation();
  const [accounts, setAccounts] = useState(undefined);
  const [collections, setCollections] = useState(undefined);
  const [changesSaved, setChangesSaved] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(undefined);
  const [openAddModal, setOpenAddModal] = useState(undefined);
  const [message, setMessage] = useState(false);
  const [acceptData, setAcceptData] = useState(undefined);

  const [items, setItems] = useState([]);
  const [sortListBy, setSortListBy] = useState(0);
  const [sortListByDir, setSortListByDir] = useState(1);

  const columnsOfDataToShow = ["name", "role", "accountAccess"];
  const columnWidths = [4, 3, 2, 1];
  const visibility = ["visible", "visible", "invisible", "visible"];
  log.debug("loggedInUser", loggedInUser.user.user);
  const user = loggedInUser.user.user;

  function updateAccount(index, fieldName, value) {
    const newAccounts = [...accounts];
    log.debug(
      " newAccount[index], fieldName",
      newAccounts[index],
      fieldName,
      value
    );
    newAccounts[index].accountRole[fieldName] = value;
    setAccounts(newAccounts);
  }

  const editBlockStyle = "text-gray bg-lightGray text-sm border-none";

  const onSave = async (data) => {
    let result = null;
    log.debug("userToAdd", data);
    result = await addAccount(data);
    log.debug("this is the result from the upload", result);
    loadData && loadData();
  };

  const makeElementsFromData = (data) => {
    return data.map((account, index) => {
      const isMainAdmin = user.id === account.id;
      //   log.debug("user in map is", account, isMainAdmin);

      return [
        () => {
          setModalOpen(index);
        },
        <div key={account.id}>
          <div className="text-sm">{account[columnsOfDataToShow[0]]}</div>
        </div>,
        <div key={account.id}>
          {isMainAdmin ? (
            <div className="text-sm px-4 pt-1">{Options()[3]}</div>
          ) : (
            <Dropdown
              index={index}
              options={Options()}
              optionkeys={[0, 1, 2, 9]}
              className={editBlockStyle}
              value={
                accounts[index] &&
                accounts[index].accountRole &&
                accounts[index].accountRole[columnsOfDataToShow[1]]
              }
              dropDownChanged={async (event) => {
                try {
                  updateAccount(
                    index,
                    columnsOfDataToShow[1],
                    event.target.value
                  );
                  await saveAccount(accounts[index].id, event.target.value);
                  setChangesSaved("");
                } catch (error) {
                  setChangesSaved("Speichern fehlgeschlagen");
                  log.error("saveAccount dropdown error: ", error);
                }
              }}
              hasWriteaccess={true}
            />
          )}
        </div>,
        <div key={account.id}>
          <div className="text-sm">
            {account[columnsOfDataToShow[2]].length}
          </div>
        </div>,
        <div key={account.id}>
          {modalOpen === index && !isMainAdmin && (
            <Modal
              onClose={() => {
                setModalOpen(false);
                loadData && loadData();
              }}
            >
              <EditUserForm
                account={account}
                collections={collections}
                language={user.customerDefaultLanguage}
              ></EditUserForm>
            </Modal>
          )}
        </div>,
        <div key={account.id}>
          {!isMainAdmin ? (
            <DeleteButton
              onButtonClicked={async (event) => {
                setMessage(t("useradminConfirmDelete"));
                setAcceptData(account.id);
                event.stopPropagation();
              }}
              classAddon=" col-span-1 "
            />
          ) : (
            ""
          )}
        </div>,
      ];
    });
  };

  async function loadData() {
    try {
      const accs = await getAccounts();
      setAccounts(accs);
      setItems(
        sortData(
          accs,
          sortListBy && columnsOfDataToShow[sortListBy],
          sortListByDir
        )
      );
      setCollections(await getCollections());
      setChangesSaved("");
    } catch (error) {
      setChangesSaved("Prüfe deine Internetverbindung.");
    }
  }

  useEffect(() => {
    loadData();
  }, []);
  log.debug("getAccounts", accounts);

  const displayItems = (accounts && makeElementsFromData(items)) || [];

  const subMenu = (
    <Tabbar
      onFilteredElements={(elements) => {
        //  console.log("setting new filtered elements", elements);
        setItems(
          sortData(
            elements,
            sortListBy && columnsOfDataToShow[sortListBy],
            sortListByDir
          )
        );
      }}
      elementsToFilter={accounts || []}
      locationToGo="/projects"
      showSearch={true}
      items={[]}
    ></Tabbar>
  );

  return (
    <WhitePage
      subMenu={subMenu}
      title={
        loggedInUser?.user.user.name
          ? loggedInUser?.user.user.name
          : "Ohne Namen"
      }
      user={loggedInUser && loggedInUser.user.user}
      changesSaved={changesSaved}
    >
      <div className="flex flex-row relative">
        <AddButton menuOpen={openAddModal} setMenuOpen={setOpenAddModal}>
          <NewUserForm
            onSave={(toSave) => {
              onSave(toSave);
              setOpenAddModal(false);
            }}
          ></NewUserForm>
        </AddButton>
      </div>
      <List
        items={displayItems}
        itemTagClass={ListRow}
        columnWidths={columnWidths}
        headerNames={[
          t("useradminName"),
          t("useradminRightsGeneral"),
          t("useradminRightsProject"),
          "",
          "",
        ]}
        visibility={visibility}
        sortBy={sortListBy}
        sortByDir={sortListByDir}
        setSortBy={(sortByThis) => {
          let newSortByThis = sortListByDir;
          if (sortByThis === sortListBy) {
            newSortByThis = sortListByDir > 0 ? -1 : 1;
            setSortListByDir(newSortByThis);
          }
          setSortListBy(sortByThis);

          log.debug(
            "sortByThis",
            sortByThis,
            "columnsOfDataToShow[sortByThis]",
            columnsOfDataToShow[sortByThis],
            columnsOfDataToShow
          );

          setItems(
            sortData(
              items,
              sortByThis !== undefined && columnsOfDataToShow[sortByThis],
              newSortByThis
            )
          );
        }}
        noItemsMessage={t("usersNone")}
      />
      {message && (
        <ModalErrorAlertInfo
          message={message}
          setMessage={setMessage}
          acceptData={acceptData}
          setAcceptData={setAcceptData}
          acceptFunction={async () => {
            setMessage(undefined);
            await deleteAccount(acceptData);
            setAcceptData(undefined);
            loadData();
          }}
        ></ModalErrorAlertInfo>
      )}
    </WhitePage>
  );
}

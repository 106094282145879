import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddButton from "../../components/Button/AddButton";
import NewProjectForm from "../../components/Form/NewProjectForm";
import Tabbar from "../../components/Header/Tabbar";
import { List } from "../../components/List/List";
import { ListRow } from "../../components/List/ListRow";
import { ListRowMenu } from "../../components/List/ListRowMenu";
import { WhitePage } from "../../components/Page/WhitePage";
import {
  addCollection,
  copyCollection,
  deleteCollection,
  getAccountAccessForCollections,
  getCollections,
  saveCollection,
} from "../../services/CollectionService";
import { sortData } from "../../utils/sort";
import { hasWriteAccess } from "../../utils/UserRights";
import InputGeneral from "../../components/InputText";
import Dropdown from "../../components/Dropdown";
import TourInfo from "../../components/Form/TourInfo";
import log from "../../utils/Logger";
import { ModalErrorAlertInfo } from "../../components/Modal/ModalContent";
import EditProjectLanguagesForm from "../../components/Form/EditProjectLanguagesForm";
import DeleteButton from "../../components/Button/DeleteButton";

export default function Projects({
  loggedInUser,
  translationInfo,
  setTranslationInfo,
}) {
  const { t } = useTranslation();
  const modules = loggedInUser.user.user.customerModules;
  log.debug("module: ", modules);
  const defaultLanguage = loggedInUser.user.user.customerDefaultLanguage;

  const [collections, setCollections] = useState([]);
  const [accountAccessList, setAccountAccessList] = useState([]);
  const [menuOpen, setMenuOpen] = useState(undefined);
  //const [tourDetailsOpen, setTourDetailsOpen] = useState(undefined);
  const [activeCollection, setActiveCollection] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(undefined);

  const [items, setItems] = useState([]);
  const [sortListBy, setSortListBy] = useState(1);
  const [sortListByDir, setSortListByDir] = useState(0);

  const [changesSaved, setChangesSaved] = useState(undefined);
  // eslint-disable-next-line no-unused-vars
  const [title, setTitle] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [acceptData, setAcceptData] = useState(undefined);

  const headerNames = modules.includes("VR")
    ? [
        t("projectsName"),
        t("projectsCreated"),
        "Status",
        t("projectsWorksNumber"),
        "",
      ]
    : modules.includes("AR")
    ? [
        t("projectsName"),
        t("projectsCreated"),
        "Status",
        t("projectsWorksNumber"),
        "",
      ]
    : [
        t("projectsName"),
        t("projectsCreated"),
        "v:culture",
        "artverse",
        t("projectsWorksNumber"),
        "",
      ];
  const columnsOfDataToShow = modules.includes("VR")
    ? ["name", "createdAt", "statusVr", "", "numberOfCollectibles"]
    : modules.includes("AR")
    ? ["name", "createdAt", "statusAr", "", "numberOfCollectibles"]
    : ["name", "createdAt", "statusVr", "statusAr", "numberOfCollectibles"];
  const columnWidths =
    modules.includes("VR") || modules.includes("AR")
      ? [4, 2, 2, 2, 2]
      : [3, 2, 2, 2, 2, 1];
  const visibility =
    modules.includes("VR") || modules.includes("AR")
      ? ["visible", "invisible", "visible", "invisible", "invisible"]
      : ["visible", "invisible", "visible", "visible", "invisible", "visible"];

  function updateCollection(collectionId, fieldName, value) {
    const newCollections = [...collections];
    const collectionToUpdate = newCollections.find((x) => x.id == collectionId);
    collectionToUpdate[fieldName] = value;
    setCollections(newCollections);
  }

  async function updateTourDetails(cid, formData) {
    log.debug("update tour details called");
    setLoading(true);
    const updatedCollection = await saveCollection(cid, formData, true);
    setCollections(updatedCollection);
    setActiveCollection(updatedCollection);
    loadData && loadData();
    setLoading(false);
  }

  const onSave = async (data) => {
    let result = null;

    result = await addCollection(data.projectName);
    log.debug("this is the result from the upload", result);
    loadData && loadData();
  };

  const openTranslationModal = (collectionId) => {
    const collection = collections.find((x) => x.id == collectionId);
    setActiveCollection(collection);
    setModalOpen("languages");
  };

  const makeElementsFromData = (data) => {
    return data.map((collection) => {
      const availableForLanguage =
        !modules.includes("MULTILANG") ||
        collection.name[translationInfo.collectionLanguage];
      return [
        availableForLanguage
          ? "/artworks/" + collection.id
          : openTranslationModal,
        <div key={collection.id} className="text-sm">
          <InputGeneral
            onChange={(t) => {
              setTitle(t);
              collection[columnsOfDataToShow[0]][
                translationInfo.collectionLanguage
              ] = t;
            }}
            onBlur={async () => {
              try {
                updateCollection(
                  collection.id,
                  [columnsOfDataToShow[0]],
                  collection[columnsOfDataToShow[0]]
                );
                await saveCollection(collection.id, {
                  ...collection,
                  [columnsOfDataToShow[0]]: collection[columnsOfDataToShow[0]],
                });
                setChangesSaved("");
              } catch (error) {
                setChangesSaved("Speichern fehlgeschlagen");
                log.error("saveCollection title public error:", error);
              }
            }}
            value={
              collection[columnsOfDataToShow[0]][
                translationInfo.collectionLanguage
              ]
                ? collection[columnsOfDataToShow[0]][
                    translationInfo.collectionLanguage
                  ]
                : collection[columnsOfDataToShow[0]][defaultLanguage]
            }
            disabled={
              !hasWriteAccess(collection.id, accountAccessList) ||
              !availableForLanguage
            }
            className={
              "text-gray text-sm border-none w-full " +
              translationInfo.languageColorClass
            }
          >
            {
              collection[columnsOfDataToShow[0]][
                translationInfo.collectionLanguage
              ]
            }
          </InputGeneral>
        </div>,
        <div key={collection.id} className="text-sm">
          {new Date(collection[columnsOfDataToShow[1]]).toLocaleDateString(
            "de-DE"
          )}
        </div>,
        !modules.includes("AR") ? (
          <div key={collection.id} className="text-sm">
            <Dropdown
              value={collection.statusVr}
              options={[
                t("projectStatusInactive"),
                t("projectStatusActive"),
                t("projectStatusPublished"),
              ]}
              hasWriteaccess={
                hasWriteAccess(collection.id, accountAccessList) &&
                availableForLanguage
              }
              dropDownChanged={async (event) => {
                //event.preventDefault();
                const newVal = parseInt(event.target.value);
                if (newVal == 2) {
                  setMessage("Du hast keine Berechtigung zur Veröffentlichung");
                  return;
                }
                try {
                  await saveCollection(collection.id, {
                    ...collection,
                    ["statusVr"]: newVal,
                  });
                  updateCollection(collection.id, ["statusVr"], newVal);
                  setChangesSaved("");
                } catch (error) {
                  setChangesSaved("Speichern fehlgeschlagen");
                  log.error("saveCollection checkbox public error:", error);
                }
              }}
            />
          </div>
        ) : (
          ""
        ),
        !modules.includes("VR") ? (
          <div key={collection.id} className="text-sm">
            <Dropdown
              value={
                collection.statusAr[translationInfo.collectionLanguage] >= 0
                  ? collection.statusAr[translationInfo.collectionLanguage]
                  : 0
              }
              options={[
                t("projectStatusInactive"),
                t("projectStatusActive"),
                t("projectStatusPublished"),
              ]}
              languageColorClass={translationInfo.languageColorClass}
              hasWriteaccess={
                hasWriteAccess(collection.id, accountAccessList) &&
                availableForLanguage
              }
              dropDownChanged={async (event) => {
                //event.preventDefault();

                const newVal = parseInt(event.target.value);
                if (newVal == 2) {
                  setMessage("Du hast keine Berechtigung zur Veröffentlichung");
                  return;
                }
                try {
                  const newStatusAr = {
                    ...collection["statusAr"],
                    [translationInfo.collectionLanguage]: newVal,
                  };
                  await saveCollection(collection.id, {
                    ...collection,
                    ["statusAr"]: newStatusAr,
                  });
                  updateCollection(collection.id, ["statusAr"], newStatusAr);
                  setChangesSaved("");
                } catch (error) {
                  setChangesSaved("Speichern fehlgeschlagen");
                  log.error("saveCollection checkbox public error:", error);
                }
              }}
            />
          </div>
        ) : (
          ""
        ),
        <div key={collection.id} className="text-sm">
          {collection[columnsOfDataToShow[4]]}
        </div>,
        modules.includes("VR") ? (
          <div key={collection.id}>
            {hasWriteAccess(collection.id, accountAccessList) ? (
              <DeleteButton
                classAddon=" delete-project col-span-1 "
                onButtonClicked={async (event) => {
                  setMessage(t("projectConfirmDelete"));
                  setAcceptData(collection.id);
                  event.stopPropagation();
                }}
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          <div key={collection.id}>
            {hasWriteAccess(collection.id, accountAccessList) &&
            availableForLanguage &&
            modules.includes("MULTILANG") ? (
              <ListRowMenu
                data={[
                  {
                    title: "AR Tour Info",
                    onClick: async () => {
                      setActiveCollection(collection);
                      setModalOpen("tourdetails");
                    },
                  },
                  {
                    title: t("projectManageLanguages"),
                    onClick: async () => {
                      setActiveCollection(collection);
                      setModalOpen("languages");
                    },
                  },
                  {
                    title: t("projectDuplicate"),
                    onClick: async () => {
                      setMessage(t("projectConfirmDuplicate"));
                      setAcceptData(collection.id);
                      loadData();
                    },
                  },
                  {
                    title: t("projectDelete"),
                    onClick: async () => {
                      setMessage(t("projectConfirmDelete"));
                      setAcceptData(collection.id);
                      loadData();
                    },
                  },
                ]}
                isOpen={menuOpen === collection.id}
                setIsOpen={(close) => {
                  setMenuOpen(close && collection.id);
                }}
                classAddon=" -left-28"
              ></ListRowMenu>
            ) : hasWriteAccess(collection.id, accountAccessList) &&
              availableForLanguage ? (
              <ListRowMenu
                data={[
                  {
                    title: "AR Tour Info",
                    onClick: async () => {
                      setActiveCollection(collection);
                      setModalOpen("tourdetails");
                    },
                  },
                  {
                    title: t("projectDuplicate"),
                    onClick: async () => {
                      setMessage(t("projectConfirmDuplicate"));
                      setAcceptData(collection.id);
                      loadData();
                    },
                  },
                  {
                    title: t("projectDelete"),
                    onClick: async () => {
                      setMessage(t("projectConfirmDelete"));
                      setAcceptData(collection.id);
                      loadData();
                    },
                  },
                ]}
                isOpen={menuOpen === collection.id}
                setIsOpen={(close) => {
                  setMenuOpen(close && collection.id);
                }}
                classAddon=" -left-28"
              ></ListRowMenu>
            ) : (
              ""
            )}
          </div>
        ),
      ].filter(Boolean);
    });
  };

  async function loadData() {
    setLoading(true);
    try {
      const collectionsl = await getCollections();
      const accountAccesses = await getAccountAccessForCollections();
      // console.log("colll and aaccess", collectionsl, accountAccesses)
      setCollections(collectionsl);
      setAccountAccessList(accountAccesses);
      let multilangField =
        translationInfo &&
        collections &&
        collections[0] &&
        columnsOfDataToShow[sortListBy] &&
        collections[0][columnsOfDataToShow[sortListBy]][defaultLanguage] !==
          undefined
          ? translationInfo.collectionLanguage
          : null;
      setItems(
        sortData(
          collectionsl,
          sortListBy && columnsOfDataToShow[sortListBy],
          sortListByDir,
          multilangField
        )
      );
      setTranslationInfo({
        ...translationInfo,
        languagesToShow: translationInfo.languagesAvailable,
      });
      setChangesSaved("");
    } catch (error) {
      setChangesSaved("Prüfe deine Internetverbindung.");
    }

    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);

  const displayItems = makeElementsFromData(items) || [];
  //console.log("displayItems", displayItems, items, collections);

  const subMenu = (
    <Tabbar
      onFilteredElements={(elements) => {
        //  console.log("setting new filtered elements", elements);
        let multilangField =
          translationInfo &&
          collections &&
          collections[0] &&
          columnsOfDataToShow[sortListBy] &&
          collections[0][columnsOfDataToShow[sortListBy]][defaultLanguage] !==
            undefined
            ? translationInfo.collectionLanguage
            : null;
        setItems(
          sortData(
            elements,
            sortListBy && columnsOfDataToShow[sortListBy],
            sortListByDir,
            multilangField
          )
        );
      }}
      elementsToFilter={collections || []}
      showSearch={true}
      items={[]}
      showMultilang={modules.includes("MULTILANG")}
      translationInfo={translationInfo}
      setTranslationInfo={setTranslationInfo}
    ></Tabbar>
  );
  const tourModal = modalOpen == "tourdetails" && (
    <TourInfo
      collection={activeCollection}
      updateCollection={updateTourDetails}
      menuOpen={modalOpen}
      setMenuOpen={setModalOpen}
      loading={loading}
      collectionLanguage={translationInfo.collectionLanguage}
      languageColorClass={translationInfo.languageColorClass}
    ></TourInfo>
  );
  const languagesModal = modalOpen == "languages" && (
    <EditProjectLanguagesForm
      collection={activeCollection}
      updateCollection={loadData}
      defaultLanguage={defaultLanguage}
      languages={translationInfo.languagesAvailable}
      setModalOpen={setModalOpen}
    ></EditProjectLanguagesForm>
  );
  return (
    <WhitePage
      subMenu={subMenu}
      title={
        loggedInUser
          ? t("projectsBy") + loggedInUser.realname
          : "Wird geladen..."
      }
      locationToGo="/projects"
      user={loggedInUser.user.user}
      changesSaved={changesSaved}
    >
      <div className="flex flex-row relative">
        {loggedInUser &&
        (loggedInUser.user.user.accountRole === 2 ||
          loggedInUser.user.user.accountRole === 9) ? (
          <div>
            <AddButton
              buttonId="add-project"
              menuOpen={openAddModal}
              setMenuOpen={setOpenAddModal}
            >
              <NewProjectForm
                onSave={(toSave) => {
                  onSave(toSave);
                  setOpenAddModal(false);
                }}
              ></NewProjectForm>
            </AddButton>
            {tourModal}
            {languagesModal}
          </div>
        ) : (
          ""
        )}
      </div>
      <List
        items={displayItems}
        itemIds={collections && collections[0] && collections.map((x) => x.id)}
        itemTagClass={ListRow}
        headerNames={headerNames}
        columnWidths={columnWidths}
        visibility={visibility}
        sortBy={sortListBy}
        sortByDir={sortListByDir}
        setSortBy={(sortByThis) => {
          let newSortByThis = sortListByDir;
          log.debug(
            "sortbythis",
            sortByThis,
            columnsOfDataToShow[sortByThis],
            sortListBy
          );
          if (sortByThis === sortListBy) {
            newSortByThis = sortListByDir > 0 ? -1 : 1;
            setSortListByDir(newSortByThis);
          }
          setSortListBy(sortByThis);
          let multilangField =
            translationInfo &&
            collections &&
            collections[0] &&
            columnsOfDataToShow[sortByThis] &&
            collections[0][columnsOfDataToShow[sortByThis]][defaultLanguage] !==
              undefined
              ? translationInfo.collectionLanguage
              : null;
          setItems(
            sortData(
              items,
              sortByThis !== undefined && columnsOfDataToShow[sortByThis],
              newSortByThis,
              multilangField
            )
          );
        }}
        noItemsMessage={loading ? t("loadingData") : t("projectsNone")}
        onClickOverride={openTranslationModal}
        translationInfo={translationInfo}
        listName="projects"
      />
      {message && (
        <ModalErrorAlertInfo
          message={message}
          setMessage={setMessage}
          acceptData={acceptData}
          setAcceptData={setAcceptData}
          acceptFunction={async () => {
            if (message == t("projectConfirmDelete")) {
              setMessage(undefined);
              await deleteCollection(acceptData);
            } else {
              setMessage(undefined);
              await copyCollection(acceptData);
            }

            setAcceptData(undefined);
            loadData();
          }}
        ></ModalErrorAlertInfo>
      )}
    </WhitePage>
  );
}

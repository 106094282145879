import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import InputGeneral from "../../components/InputText";
import DefaultButton from "../Button/DefaultButton";
import ModalContent, {
  ModalInputDescription,
  ModalTitle,
} from "../Modal/ModalContent";

export default function NewUserForm({ onSave }) {
  const { t } = useTranslation();
  const [username, setUsername] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const [passwordConfirm, setPasswordConfirm] = useState(undefined);
  const [userinfo, setUserinfo] = useState(undefined);

  const CheckPWInput = () => {
    if (
      passwordConfirm != password &&
      password &&
      password.length > 0 &&
      passwordConfirm &&
      passwordConfirm.length > 0
    ) {
      setUserinfo(t("newUserFormPasswordMismatch"));
      return false;
    } else if (password && password.length < 8) {
      setUserinfo(t("newUserFormPasswordLength"));
      return false;
    } else {
      setUserinfo(undefined);
      return true;
    }
  };
  const CheckInput = () => {
    if (!username || !password || !passwordConfirm) {
      setUserinfo(t("newUserFormNoEmptyFields"));
      return false;
    } else {
      setUserinfo(undefined);
      return true;
    }
  };

  const onButtonClicked = () => {
    const isReady1 = CheckInput();
    const isReady2 = CheckPWInput();
    if (isReady1 && isReady2) {
      onSave({
        username,
        password,
        passwordConfirm,
      });
    }
  };

  return (
    <div>
      <ModalContent>
        <ModalTitle title={t("newUserFormTitle")} />
        <ModalInputDescription desc={t("newUserFormUser")} />
        <InputGeneral
          classNameAddon=" col-span-3"
          value={username}
          onChange={setUsername}
          onKeyUp={onButtonClicked}
        />
        <ModalInputDescription desc={t("newUserFormPassword")} />
        <InputGeneral
          type="password"
          classNameAddon="col-span-3"
          value={password}
          onChange={setPassword}
          onBlur={CheckPWInput}
          onKeyUp={onButtonClicked}
        />
        <ModalInputDescription desc={t("newUserFormPasswordConfirm")} />
        <InputGeneral
          type="password"
          classNameAddon="col-span-3"
          value={passwordConfirm}
          onChange={setPasswordConfirm}
          onBlur={CheckPWInput}
          onKeyUp={onButtonClicked}
        />
        <div className="col-span-6"> {userinfo}</div>
      </ModalContent>
      <DefaultButton
        text={t("newUserFormSubmit")}
        onButtonClicked={userinfo ? () => {} : onButtonClicked}
        disabled={userinfo ? " opacity-50 cursor-not-allowed " : ""}
      ></DefaultButton>
    </div>
  );
}

import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultButton from "../Button/DefaultButton";
import FileUpload from "../Modal/FileUpload";
import Loaderfreisicht from "../../utils/Loaderfreisicht";
import ModalImage from "../Modal/Modalmage";
import ModalContent, { ModalTitle } from "../Modal/ModalContent";
import { acceptImgTrigger } from "../../utils/Fileformats";

export default function ChangePictureForm({
  collectible,
  updateCollectible,
  loading = false,
}) {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState(undefined);

  const onButtonClicked = async () => {
    const formData = new FormData();
    const collectibleImage =
      selectedFiles && selectedFiles.length > 0 ? selectedFiles[0] : null;
    if (collectibleImage) {
      formData.append("collectibleImage", collectibleImage);
    }
    updateCollectible(formData);
  };

  return (
    <>
      <ModalContent>
        <ModalTitle title={t("artworkChangeImage")} />
        {collectible && collectible.id ? (
          <ModalImage media={collectible.previewMedia}></ModalImage>
        ) : (
          ""
        )}
        <FileUpload
          filesToAccept={acceptImgTrigger()}
          saveFunction={setSelectedFiles}
          maxFiles={1}
        ></FileUpload>
      </ModalContent>
      {loading ? (
        <Loaderfreisicht></Loaderfreisicht>
      ) : (
        <DefaultButton
          text={t("artworkChangeImageConfirm")}
          onButtonClicked={onButtonClicked}
        ></DefaultButton>
      )}
    </>
  );
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createTranslationForCollection } from "../../services/CollectionService";
import InputCheckBox from "../InputCheckBox";
import log from "../../utils/Logger";

export default function ProjectLanguageElement({
  language,
  isLanguageActive,
  availableLanguages,
  setAvailableLanguages,
  collection,
  setMessage,
  setAcceptData,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleOnChange = async (e) => {
    const newval = e.target.checked;
    if (newval) {
      // create translation
      setLoading(true);
      try {
        await createTranslationForCollection(collection.id, language.id);
        setMessage(t("multilangModalTranslationCreateSuccess") + language.name);
        setAvailableLanguages({ ...availableLanguages, [language.id]: true });
      } catch {
        log.debug("error on creating translation");
        setMessage(t("multilangModalTranslationCreateError"));
        setAvailableLanguages({ ...availableLanguages, [language.id]: false });
      }
      setLoading(false);
    } else {
      // warn user if translation should be deleted
      setMessage(t("multilangModalTranslationDeleteConfirm") + language.name);
      setAcceptData(language.id);
    }
  };

  return (
    <div
      key={language.id}
      className="grid grid-cols-6 text-sm items-center mb-2"
    >
      <div className="col-span-2 col-start-2">{language.name}:</div>
      <div className="px-4 h-5 text-darkGreen col-span-2 transition">
        <InputCheckBox
          checkedValue={loading ? true : isLanguageActive}
          hasWriteaccess={loading ? false : true}
          clicked={handleOnChange}
        />
      </div>
    </div>
  );
}

import { t } from "i18next";
import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { getNamesFor } from "../../utils/Fileformats";
import log from "../../utils/Logger";

export default function FileUpload({
  saveFunction,
  filesToAccept,
  maxFiles,
  setAllfilesAccepted,
}) {
  const onDrop = useCallback((acceptedFiles) => {
    const myFiles = [];
    const l = acceptedFiles.length;
    for (var i = 0; i < l; i++) {
      myFiles.push(acceptedFiles[i]);
    }
    log.debug("saveFunction", myFiles);
    saveFunction(myFiles);
  }, []);

  const maxSize = 104857600; // 100MB in Bytes

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    accept: filesToAccept,
    maxFiles,
    maxSize: maxSize,
  });

  const files = acceptedFiles.map((file) => (
    <li className="list-inside text-xs" key={file.path}>
      {file.path} ({parseInt(file.size / 1000)} KB)
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    // log.debug("filerejection errors", errors);
    return (
      <li key={file.path} className="list-inside text-xs">
        {file.path + " "}
        {errors.map((e) => {
          return "-> " + e.message;
        })}
      </li>
    );
  });

  useEffect(() => {
    if (setAllfilesAccepted) {
      setAllfilesAccepted(fileRejections);
    }
  }, [fileRejections]);

  var filesToAcceptNames = getNamesFor((filesToAccept = { filesToAccept }));

  return (
    <>
      <div
        className="col-span-6 border border-dashed border-gray p-4 flex justify-center"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Dateien hier loslassen ...</p>
        ) : (
          <div>
            <div className="col-span pb-2 px-4 flex self-center">
              {maxFiles == 1
                ? t("uploadFormMoveFileHere")
                : t("uploadFormMoveFilesHere")}
              {t("uploadFormOr")}
              <div className="ml-4 font-bold text-darkGreen transition hover:text-freisicht cursor-pointer">
                {t("uploadFormChooseFiles")}
              </div>
            </div>
          </div>
        )}
      </div>
      {fileRejectionItems.length > 0 ? (
        <div
          className="col-span-6 h-20 pt-2 overflow-y-auto"
          {...getRootProps()}
        >
          <ul>
            {t("uploadFormInvalidSelection")} {fileRejectionItems}
          </ul>
        </div>
      ) : (
        ""
      )}
      {files.length > 0 && fileRejectionItems.length <= 0 ? (
        <div
          className="col-span-6 h-20 pt-2 overflow-y-auto"
          {...getRootProps()}
        >
          <ul>
            {t("uploadFormSelection")} {files}
          </ul>
        </div>
      ) : (
        ""
      )}
      <div className="col-span-6 h-20 pt-2 text-xs overflow-y-auto">
        <ul>
          {t("uploadFormSupportedFormats")} {filesToAcceptNames}
        </ul>
        <ul>
          {t("uploadFormMaxFileSize")} {maxSize / 1048576} MB
        </ul>
      </div>
    </>
  );
}

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tabbar from "../../components/Header/Tabbar";
import { React, useEffect, useState } from "react";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import {
  getCollectible,
  addCollectionItem,
  saveCollectionItem,
} from "../../services/CollectibleService";
import { WhitePage } from "../../components/Page/WhitePage";
import {
  addOverlay,
  getOverlays,
  getTriggerQuality,
  saveOverlay,
} from "../../services/OverlayService";
import AddButton from "../../components/Button/AddButton";
import UploadForm from "../../components/Form/UploadForm";
import { OneOverlayElement } from "../../components/OneOverlayElement";
import { WorkOverlay } from "../../utils/Tabs";
import { calculateNewIndexNumber } from "../../utils/drag";
import { ArtworkOverlayEditBlock } from "../../components/ArtworkOverlayEditBlock";
import Modal from "../../components/Modal/Modal";
import log from "../../utils/Logger";
import ChangeOverlayForm from "../../components/Form/ChangeOverlayForm";
import { getCollection } from "../../services/CollectionService";
import Tooltip from "../../utils/Tooltip";

export default function ArtWorkOverlay({
  loggedInUser,
  translationInfo,
  setTranslationInfo,
}) {
  const { t } = useTranslation();
  const modules = loggedInUser.user.user.customerModules;
  let { id, cid } = useParams();
  const [collectible, setCollectible] = useState(undefined);
  const [overlays, setOverlays] = useState(undefined);
  const [triggerQuality, setTriggerQuality] = useState(undefined);
  //const [title, setTitle] = useState(collectible && collectible["title"]);
  const [result, setResult] = useState(undefined);
  const [accountAccess, setAccountAccess] = useState(undefined);
  const [changesSaved, setChangesSaved] = useState(undefined);
  const [openAddModal, setOpenAddModal] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [changeOverlayOpen, setChangeOverlayOpen] = useState(undefined);

  const onSaveUpload = async (selectedFiles) => {
    setUploading(true);
    const formData = new FormData();
    let result = null;

    log.debug("formdata overlay", formData);
    selectedFiles &&
      selectedFiles.selectedFiles &&
      selectedFiles.selectedFiles.forEach((file) => {
        formData.append("overlays", file);
      });
    try {
      result = await addOverlay(id, cid, formData);
      log.debug("this is the result from the upload", result);
      setResult(result);
    } catch (error) {
      log.error("addOverlay error", error);
    }
    loadData && loadData();
    setUploading(false);
  };

  async function loadData() {
    setLoading(true);
    const triggerQuality = await getTriggerQuality(cid);
    setTriggerQuality(triggerQuality);
    let res = await getCollectible(id, cid);
    setAccountAccess(res.accountAccess);
    if (res.collectible.collectionItem == null && res.accountAccess > 1) {
      await addCollectionItem(id, cid);
      res = await getCollectible(id, cid);
    }
    setCollectible(res.collectible);
    const overlays = await getOverlays(id, cid);
    setOverlays(overlays);
    if (
      has3DOverlay(overlays) &&
      res.collectible.collectionItem.arPinStatus != 3
    ) {
      log.debug("has 3d overlays");
      await saveCollectionItem(id, cid, {
        ...res.collectible.collectionItem,
        arPinStatus: 3,
      });
      loadData();
    }

    setLoading(false);
    if (
      translationInfo?.languagesToShow?.length ==
      translationInfo?.languagesAvailable?.length
    ) {
      const collection = await getCollection(id);
      const languagesToShow = translationInfo.languagesAvailable?.filter(
        (l) => collection.collection.name[l.code]
      );
      setTranslationInfo({
        ...translationInfo,
        languagesToShow,
      });
    }
  }

  async function onDragEnd(result) {
    log.debug("drag end called, ", result);
    const { destination, source, draggableId } = result;

    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }
    const newOverlays = Array.from(overlays);
    const movedOverlay = newOverlays[source.index];
    const newIndexNumber = calculateNewIndexNumber(
      source.index,
      destination.index,
      overlays
    );
    log.debug("newIndexNumber", newIndexNumber);
    movedOverlay.indexNumber = newIndexNumber;
    newOverlays.splice(source.index, 1);
    newOverlays.splice(
      destination.index,
      0,
      overlays.find((x) => x.id == draggableId)
    );
    setOverlays(newOverlays);
    // persist to DB
    try {
      await saveOverlay(id, cid, movedOverlay.id, movedOverlay);
      setChangesSaved("");
    } catch (error) {
      setChangesSaved("Speichern fehlgeschlagen");
      log.error("saveOverlay inputfield error: ", error);
    }
  }

  async function updateOverlayChangeMedia(formData) {
    setLoading(true);
    const mediaLanguageId = formData.get("mediaLanguageId");
    formData.delete("mediaLanguageId");
    await saveOverlay(id, cid, changeOverlayOpen.id, formData, mediaLanguageId);
    setChangeOverlayOpen(false);
    setLoading(false);
    loadData && loadData();
  }

  useEffect(() => {
    loadData();
  }, []);

  const subMenu = (
    <Tabbar
      locationToGo={"/artworks/" + id}
      openedAtStart={2}
      showSearch={false}
      items={WorkOverlay(modules)}
      showMultilang={modules.includes("MULTILANG")}
      translationInfo={translationInfo}
      setTranslationInfo={setTranslationInfo}
    ></Tabbar>
  );

  const changeOverlayModal = changeOverlayOpen && (
    <Modal
      onClose={() => {
        log.debug("close modal called");
        setChangeOverlayOpen(undefined);
      }}
    >
      <ChangeOverlayForm
        overlay={changeOverlayOpen}
        updateOverlay={updateOverlayChangeMedia}
        loading={loading}
        collectionLanguage={translationInfo.collectionLanguage}
        multilang={modules.includes("MULTILANG")}
      ></ChangeOverlayForm>
    </Modal>
  );

  const isPinnableInSpace = () => {
    const collectionItem = collectible?.collectionItem;
    if (collectionItem) {
      return collectionItem.arPinStatus == 3;
    }
    return false;
  };

  const has3DOverlay = (localOverlays) => {
    if (localOverlays) {
      return localOverlays.some((o) => o.type === 3);
    }
    return overlays?.some((o) => o.type === 3);
  };

  const dropdownOptions = [
    t("overlayPinnableNo"),
    t("overlayPinnableYes"),
    t("overlayPinnableAuto"),
    t("overlayPinnableInSpace"),
  ];

  return (
    <WhitePage
      subMenu={subMenu}
      title={
        collectible?.title
          ? t("overlaysFor") +
            collectible?.title[translationInfo.collectionLanguage]
          : "Ohne Namen"
      }
      user={loggedInUser && loggedInUser.user.user}
      changesSaved={changesSaved}
    >
      <div className="">
        <div className="flex-row w-full relative grid grid-cols-8">
          <div
            className="p-2 col-span-4 md:p-4 md:col-span-2 text-gray font-bold text-sm flex place-items-center"
            data-tip={t("overlaysTriggerQualityTooltip")}
          >
            <Tooltip
              tooltip={t("overlaysTriggerQualityTooltip")}
              tooltipId="tooltip-triggerquality"
              subtitle={t("overlaysTriggerQuality")}
            />
            <span className="pl-2">{loading ? "" : " " + triggerQuality}</span>
          </div>
          <div className="col-span-2 md:col-span-1 place-items-center flex">
            <ArtworkOverlayEditBlock
              title={t("overlaysSlider")}
              tooltip={t("overlaysSliderTooltip")}
              type="checkbox"
              fieldName="arShowSlider"
              parentInfo={{ accountAccess, setChangesSaved }}
              collectible={collectible}
              setCollectible={setCollectible}
            ></ArtworkOverlayEditBlock>
          </div>
          <div className="col-span-3 md:col-span-2 place-items-center flex">
            <ArtworkOverlayEditBlock
              title={t("overlaysMove")}
              tooltip={t("overlaysMoveTooltip")}
              type="checkbox"
              fieldName="arMovableOverlays"
              parentInfo={{ accountAccess, setChangesSaved }}
              collectible={collectible}
              setCollectible={setCollectible}
            ></ArtworkOverlayEditBlock>
          </div>
          <div className="col-span-4 md:col-span-2 place-items-center flex">
            {has3DOverlay() ? (
              <ArtworkOverlayEditBlock
                title={t("overlaysPin")}
                tooltip={t("overlaysPinTooltip3d")}
                fieldName="arPinStatus"
                type="dropdown"
                dropdownOptions={dropdownOptions}
                parentInfo={{ accountAccess: 0, setChangesSaved }}
                collectible={collectible}
                setCollectible={setCollectible}
              ></ArtworkOverlayEditBlock>
            ) : (
              <ArtworkOverlayEditBlock
                title={t("overlaysPin")}
                tooltip={t("overlaysPinTooltip")}
                fieldName="arPinStatus"
                type="dropdown"
                dropdownOptions={dropdownOptions}
                parentInfo={{ accountAccess, setChangesSaved }}
                collectible={collectible}
                setCollectible={setCollectible}
              ></ArtworkOverlayEditBlock>
            )}
          </div>

          {accountAccess > 1 ? (
            <AddButton
              menuOpen={openAddModal}
              setMenuOpen={(d) => {
                setOpenAddModal(d);
                setResult(undefined);
              }}
            >
              <UploadForm
                isOverlay={true}
                onSave={(toSave) => {
                  onSaveUpload(toSave);
                }}
                result={result}
                loading={uploading}
                showTextOption={modules.includes("TEXTOVERLAYS")}
              ></UploadForm>
            </AddButton>
          ) : (
            ""
          )}
        </div>
        {isPinnableInSpace() ? (
          <div className="flex-row w-full pb-2 md:pb-6 relative grid grid-cols-8">
            <div className="col-span-4 md:col-span-2 place-items-center flex">
              <ArtworkOverlayEditBlock
                title={t("overlaysTriggerWidth")}
                tooltip={t("overlaysTriggerSizeTooltip")}
                defaultValue={collectible?.width}
                fieldName="arTriggerWidth"
                type="number"
                collectible={collectible}
                setCollectible={setCollectible}
                parentInfo={{ setChangesSaved, accountAccess }}
              ></ArtworkOverlayEditBlock>
            </div>
          </div>
        ) : (
          ""
        )}

        {overlays && overlays.length ? (
          <div className="grid grid-cols-8 border-b border-middleGray"></div>
        ) : (
          ""
        )}
        {changeOverlayModal}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="overlayDroppableZone">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {overlays && overlays.length ? (
                  overlays.map((overlay, index) => {
                    return (
                      <OneOverlayElement
                        accountAccess={accountAccess}
                        setChangesSaved={setChangesSaved}
                        loadData={loadData}
                        overlay={overlay}
                        key={overlay.id}
                        index={index}
                        overlays={overlays}
                        setOverlays={setOverlays}
                        collectionLanguage={translationInfo.collectionLanguage}
                        languageColorClass={translationInfo.languageColorClass}
                        setChangeOverlayOpen={setChangeOverlayOpen}
                      ></OneOverlayElement>
                    );
                  })
                ) : (
                  <div className="p-4">{loading ? "" : t("overlaysNone")}</div>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </WhitePage>
  );
}

import { React, useEffect, useState } from "react";
import {
  addCollectibleTag,
  deleteCollectibleTag,
} from "../../services/CollectibleService";
import log from "../../utils/Logger";
import Dropdown from "../Dropdown";
import { getTags } from "../../services/SettingsService";
import TagsLayout from "./TagsLayout";

export default function ArtWorkTags({
  loggedInUser,
  collectible,
  accountAccess,
  setCollectible,
}) {
  const [allTags, setAllTags] = useState(collectible && collectible.tags);
  const [tagSelected, setTagSelected] = useState(0);
  const [dropdownOptionValues, setDropdownOptioValues] = useState({});

  function updateCollectible(value) {
    setCollectible({
      ...collectible,
      ["tags"]: value,
    });
  }

  const load = async () => {
    if (!allTags || allTags.length < 1) {
      const tags = await getTags(loggedInUser.user.user.customerId);
      if (tags && tags.length > 0) {
        const newTags = [];
        tags.map((t) => {
          newTags.push({ id: t.id, name: t.name });
        });
        setAllTags(newTags);
        setDropdownOptionsAndKeys(collectible?.tags);
      }
    }
    setDropdownOptionsAndKeys(collectible?.tags);
  };

  useEffect(() => {
    load();
  }, [collectible]);

  const dropDownChanged = (event) => {
    const newval = parseInt(event.target.value);
    setTagSelected(newval);
  };

  const setDropdownOptionsAndKeys = (alreadySetTags) => {
    const alreadySetTagsIds = [];
    alreadySetTags?.map((t) => {
      alreadySetTagsIds.push(t.id);
    });
    const availableOptionNames = { 0: "-" };

    allTags?.map((tag) => {
      if (!alreadySetTagsIds.includes(tag.id)) {
        availableOptionNames[tag.id] = tag.name["de"];
      }
    });
    setDropdownOptioValues(availableOptionNames);
  };

  const onAddTag = async () => {
    if (tagSelected === 0) return;
    await addCollectibleTag(
      collectible?.collectionId,
      collectible?.id,
      tagSelected
    );

    const newTags = [
      ...(collectible ? collectible.tags : []),
      ...allTags.filter((item) => item.id == tagSelected),
    ];
    setTagSelected(0);
    updateCollectible(newTags);
    await load();
  };

  const onRemoveTag = async (tagId) => {
    log.debug("remove tag called for: ", tagId);

    await deleteCollectibleTag(
      collectible?.collectionId,
      collectible?.id,
      tagId
    );
    const newTags = collectible.tags.filter((item) => item.id !== tagId);
    updateCollectible(newTags);
    await load();
  };

  return collectible ? (
    <div className="p-2 md:p-4 flex">
      <div className="w-full">
        <div className="text-gray font-bold text-sm gap-4">Verfügbare Tags</div>
        <TagsLayout
          showError={false}
          accountAccess={accountAccess}
          tags={collectible.tags}
          title={"Verfügbare Tags"}
          inputElement={
            <Dropdown
              value={tagSelected}
              options={Object.values(dropdownOptionValues)}
              optionkeys={Object.keys(dropdownOptionValues)}
              dropDownChanged={dropDownChanged}
              hasWriteaccess={accountAccess > 1}
            />
          }
          onSave={onAddTag}
          onDelete={async (tagId) => {
            await onRemoveTag(tagId);
          }}
          hideEditButton={" hidden"}
        />
      </div>
    </div>
  ) : null;
}

import { React } from "react";
import { Page } from "./Page";

/* This component only adds a padding on top of the content area, else its exactly a Page. */
export function WhitePage({
  title,
  subMenu,
  locationToGo,
  children,
  user,
  changesSaved,
}) {
  return (
    <Page
      subMenu={subMenu}
      title={title}
      locationToGo={locationToGo}
      user={user}
      changesSaved={changesSaved}
    >
      <div
        className={(subMenu ? "pt-32" : "pt-16") + " max-w-screen-lg m-auto"}
      >
        {children}
      </div>
    </Page>
  );
}

import { React } from "react";
import Modal from "../Modal/Modal";
import { BiPlayCircle } from "react-icons/bi";
import Media from "./Media";

export default function ClickableMedia({
  menuOpen,
  setMenuOpen,
  mediaSource,
  type = 0,
  classNameModal = "w-auto h-auto",
  className = "p-2 pb-0 md:pb-0 md:p-4",
  imgClassName = " p-4 ",
}) {
  return (
    <div
      className={className}
      onClick={async (event) => {
        event.stopPropagation();
        setMenuOpen(!menuOpen);
      }}
    >
      {type === 0 && (
        <div className="relative z-0 transition transform hover:scale-105 cursor-pointer">
          <img
            src={mediaSource}
            className={"cursor-pointer  " + imgClassName}
          ></img>
        </div>
      )}
      {type === 1 && (
        <div className="relative z-0 transition transform hover:scale-105 cursor-pointer">
          <video src={mediaSource} className=" cursor-pointer max-h-56"></video>
          <div className="absolute inset-0 flex justify-center items-center z-10 text-freisicht hover:text-darkGreen">
            <BiPlayCircle className="text-4xl"></BiPlayCircle>
          </div>
        </div>
      )}
      {type === 2 && (
        <div className="relative z-0 transition transform hover:scale-105 cursor-pointer ">
          <img src="/assets/images/Audio_icon.jpg" className="max-h-48 "></img>
          <div className="absolute inset-0 flex justify-center items-center z-10 text-freisicht hover:text-darkGreen">
            <BiPlayCircle className="text-4xl"></BiPlayCircle>
          </div>
        </div>
      )}
      {type === 3 && (
        <div className="relative z-0 transition transform hover:scale-105 cursor-pointer ">
          <img
            src="/assets/images/cube-solid-240.png"
            className="max-h-48 "
          ></img>
          <div className="absolute inset-0 flex justify-center items-center z-10 text-freisicht hover:text-darkGreen">
            <BiPlayCircle className="text-4xl"></BiPlayCircle>
          </div>
        </div>
      )}

      {menuOpen && (
        <Modal
          onClose={() => {
            setMenuOpen(false);
          }}
          className={classNameModal}
        >
          <Media
            mediaSource={mediaSource}
            className={className}
            type={type}
          ></Media>
        </Modal>
      )}
    </div>
  );
}

import { React, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import Tabbar from "../../components/Header/Tabbar";
import { List } from "../../components/List/List";
import { ListRow } from "../../components/List/ListRow";
import { ListRowMenu } from "../../components/List/ListRowMenu";
import { WhitePage } from "../../components/Page/WhitePage";
import { REACT_APP_API_SERVER } from "../../services/APIService";
import { getCollection } from "../../services/CollectionService";
import {
  deleteExhibition,
  getExhibitions,
  exportExhibition,
} from "../../services/ExhibitionService";
import { sortData } from "../../utils/sort";
import LazyLoad from "react-lazyload";
import log from "../../utils/Logger";
import { WorksExhibitionImpressum } from "../../utils/Tabs";
import { ModalErrorAlertInfo } from "../../components/Modal/ModalContent";

export default function Exhibitions({
  loggedInUser,
  translationInfo,
  setTranslationInfo,
}) {
  const { t } = useTranslation();
  let { id } = useParams();
  const [exhibitions, setExhibition] = useState(undefined);
  const [collection, setCollection] = useState(undefined);
  const [menuOpen, setMenuOpen] = useState(undefined);
  const [accountAccess, setAccountAccess] = useState(undefined);
  const [message, setMessage] = useState(false);
  const [acceptData, setAcceptData] = useState(undefined);

  const [items, setItems] = useState([]);
  const [sortListBy, setSortListBy] = useState(3);
  const [sortListByDir, setSortListByDir] = useState(-1);
  const [loading, setLoading] = useState(false);

  const columnsOfDataToShow = ["media", "title", "author", "updatedAt"];
  const columnWidths = [2, 3, 3, 2];
  const visibility = ["visible", "visible", "invisible", "visible"];

  const dateTimeOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  const openExhibition = (ex) => {
    window.open(
      `https://exhibitions.varcu.app/${loggedInUser.user.user.customerId}/?collectionId=${ex.collectionId}&exhibitionId=${ex.id}`
    );
  };

  const makeElementsFromData = (data) => {
    return data.map((ex) => {
      //console.log("collectible in map is", collectible);
      // console.log("exhibition in map is", ex);
      return [
        "/exhibitions/" + id,
        <div key={ex.id}>
          <LazyLoad>
            <img
              src={
                REACT_APP_API_SERVER +
                (ex[columnsOfDataToShow[0]] && ex[columnsOfDataToShow[0]].url)
              }
              className="max-h-20 max-w-full -m-2 h-auto w-auto"
            ></img>
          </LazyLoad>
        </div>,
        <div key={ex.id}>
          <div className="text-sm">{ex[columnsOfDataToShow[1]]}</div>{" "}
        </div>,
        <div key={ex.id}>
          <div className="text-sm">{ex[columnsOfDataToShow[2]]}</div>
        </div>,
        <div key={ex.id}>
          <div className="text-sm ">
            {new Date(ex[columnsOfDataToShow[3]]).toLocaleDateString(
              "de-DE",
              dateTimeOptions
            )}
          </div>
        </div>,
        <div key={ex.id}>
          {accountAccess > 1 ? (
            <ListRowMenu
              data={[
                {
                  title: "Exportieren",
                  onClick: async () => {
                    await exportExhibition(id, ex);
                  },
                },
                {
                  title: "Webansicht",
                  onClick: () => {
                    openExhibition(ex);
                  },
                },
                {
                  title: "Löschen",
                  onClick: async () => {
                    setMessage("Willst du diese Ausstellung wirklich löschen?");
                    setAcceptData(ex.id);
                  },
                },
              ]}
              isOpen={menuOpen === ex.id}
              setIsOpen={(close) => {
                setMenuOpen(close && ex.id);
              }}
              classAddon=" -left-28"
            ></ListRowMenu>
          ) : (
            ""
          )}
        </div>,
      ];
    });
  };

  async function loadData() {
    setLoading(true);
    const res = await getCollection(id);
    setCollection(res.collection);
    setAccountAccess(res.accountAccess);
    const exs = await getExhibitions(id);
    setExhibition(exs);
    log.debug("exhibitions", exs);
    setItems(
      sortData(
        exs,
        sortListBy && columnsOfDataToShow[sortListBy],
        sortListByDir
      )
    );
    const languagesToShow = translationInfo.languagesAvailable?.filter(
      (l) => res.collection.name[l.code]
    );
    setTranslationInfo({
      ...translationInfo,
      languagesToShow,
    });
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);
  log.debug("getExhibitions ", exhibitions);

  const displayItems = (exhibitions && makeElementsFromData(items)) || [];
  const modules = loggedInUser.user.user.customerModules;

  const subMenu = (
    <Tabbar
      onFilteredElements={(elements) => {
        log.debug("setting new filtered elements", elements);
        setItems(
          sortData(
            elements,
            sortListBy && columnsOfDataToShow[sortListBy],
            sortListByDir
          )
        );
      }}
      elementsToFilter={exhibitions || []}
      locationToGo="/projects"
      showSearch={true}
      hiddenOnMobile={true}
      items={WorksExhibitionImpressum(modules)}
      showMultilang={modules.includes("MULTILANG")}
      translationInfo={translationInfo}
      setTranslationInfo={setTranslationInfo}
    ></Tabbar>
  );

  return (
    <WhitePage
      subMenu={subMenu}
      title={
        collection?.name?.[translationInfo.collectionLanguage]
          ? t("artworksProjectname") +
            collection?.name?.[translationInfo.collectionLanguage]
          : "Ohne Namen"
      }
      user={loggedInUser && loggedInUser.user.user}
    >
      <List
        noSortColumns={[0]}
        items={displayItems}
        itemTagClass={ListRow}
        headerNames={["Abbildung", "Titel", "Autor*in", "Letzte Änderung", ""]}
        visibility={visibility}
        columnWidths={columnWidths}
        sortBy={sortListBy}
        sortByDir={sortListByDir}
        setSortBy={(sortByThis) => {
          let newSortByThis = sortListByDir;
          if (sortByThis === sortListBy) {
            newSortByThis = sortListByDir > 0 ? -1 : 1;
            setSortListByDir(newSortByThis);
          }
          setSortListBy(sortByThis);

          setItems(
            sortData(
              items,
              sortByThis !== undefined && columnsOfDataToShow[sortByThis],
              newSortByThis
            )
          );
        }}
        noItemsMessage={loading ? t("loadingData") : t("exhibitionsNone")}
      />
      {message && (
        <ModalErrorAlertInfo
          message={message}
          setMessage={setMessage}
          acceptData={acceptData}
          setAcceptData={setAcceptData}
          acceptFunction={async () => {
            setMessage(undefined);
            await deleteExhibition(id, acceptData);
            setAcceptData(undefined);
            loadData();
          }}
        ></ModalErrorAlertInfo>
      )}
    </WhitePage>
  );
}

export function getOverlayLinkOptions(overlays, currentId) {
  if (overlays && overlays.length > 1) {
    const currentOverlay = overlays.find((x) => x.id == currentId);
    const linkedGroupId = currentOverlay.link
      ? currentOverlay.link.linkedGroupId
      : null;
    const defaultOption = ["-"];
    return defaultOption.concat(
      overlays
        .filter(
          (x) =>
            x.id != currentId &&
            (!x.link || x.link.linkedGroupId == linkedGroupId)
        )
        .map((x) => x.title)
    );
  } else {
    return ["-"];
  }
}

export function getOverlayLinkOptionKeys(overlays, currentId) {
  if (overlays && overlays.length > 1) {
    const currentOverlay = overlays.find((x) => x.id == currentId);
    const linkedGroupId = currentOverlay.link
      ? currentOverlay.link.linkedGroupId
      : null;
    const defaultOption = [-1];
    return defaultOption.concat(
      overlays
        .filter(
          (x) =>
            x.id != currentId &&
            (!x.link || x.link.linkedGroupId == linkedGroupId)
        )
        .map((x) => x.id)
    );
  } else {
    return ["-"];
  }
}

import { t } from "i18next";
import { React, useState } from "react";
import Modal from "../Modal/Modal";
import DefaultButton from "../Button/DefaultButton";
import log from "../../utils/Logger";
import Loaderfreisicht from "../../utils/Loaderfreisicht";
import ModalContent, { ModalTitle } from "../Modal/ModalContent";
import InputGeneral from "../InputText";

export default function EditTag({
  menuOpen,
  setMenuOpen,
  className,
  classNameModal,
  tag,
  updateTag,
  loading = false,
}) {
  const [tagName, setTagName] = useState(tag.name["de"]);

  const onButtonClicked = async () => {
    // save tag here
    await updateTag(tag, tagName);
    setMenuOpen(undefined);
  };

  return (
    <div className={className}>
      {menuOpen && (
        <Modal
          onClose={() => {
            log.debug("close modal called");
            setMenuOpen(undefined);
          }}
          className={classNameModal}
        >
          <ModalContent>
            <ModalTitle title={t("settingsEditTag")} />
            <div>
              <InputGeneral value={tagName} onChange={setTagName} />
            </div>
          </ModalContent>

          {loading ? (
            <Loaderfreisicht></Loaderfreisicht>
          ) : (
            <DefaultButton
              text={t("settingsEditTagConfirm")}
              onButtonClicked={!tagName ? () => {} : onButtonClicked}
              disabled={!tagName ? " opacity-50 cursor-not-allowed " : ""}
            ></DefaultButton>
          )}
        </Modal>
      )}
    </div>
  );
}

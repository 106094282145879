import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import InputGeneral from "../components/InputText";

export default function Search({ elementsToFilter, onFilteredElements }) {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState();

  // needed for nested objects due to multilang fields
  const isObject = (el) => {
    return typeof el === "object" && !(el instanceof String) && el !== null;
  };

  async function doFilter(searchText) {
    if (!searchText) {
      onFilteredElements(elementsToFilter);
    }
    const res = elementsToFilter.filter((element) => {
      return Object.keys(element).reduce((ret, elKey) => {
        const el = element[elKey];
        return (
          ret ||
          (typeof el === "string" &&
            el.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) >
              -1) ||
          (isObject(el) &&
            JSON.stringify(el)
              .toLocaleLowerCase()
              .indexOf(searchText.toLocaleLowerCase()) > -1)
        );
      }, false);
    });
    onFilteredElements(res);
  }

  return (
    <div className="px-2 md:px-0 py-2 flex justify-end items-center w-64 relative">
      <InputGeneral
        value={searchText}
        placeholder={t("searchPlaceholder")}
        onChange={(newValue) => {
          setSearchText(newValue);
          doFilter(newValue);
        }}
        className="placeholder-lightGray bg-white border-2 border-lightGray text-gray text-sm"
      ></InputGeneral>
    </div>
  );
}

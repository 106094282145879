import { t } from "i18next";

export function GetUserAccessName({ role }) {
  const roleNr = Number(role);
  const rolename = roleNr && roleNr === 9 ? Options()[3] : Options()[roleNr];
  return rolename;
}

export function Options() {
  const options = [
    t("userRightNone"),
    t("userRightRead"),
    t("userRightWrite"),
    t("userRightAdmin"),
  ];
  return options;
}

export function hasWriteAccess(cid, accountAccessList) {
  const collAccess =
    accountAccessList &&
    accountAccessList.filter((accountAccess) => {
      return accountAccess.collectionId === cid;
    })[0]?.collectionAccess;
  return collAccess === 9 || collAccess === 2 ? true : false;
}

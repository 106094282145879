import { React } from "react";
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  Legend,
} from "recharts";

export default function AnalyticsLineChart({
  data,
  toursToDisplay,
  className = "p-4",
}) {
  const colors = ["#1f77b4", "#ff7f0e", "#2ca02c"];
  return (
    <div className={className}>
      <LineChart
        data={data}
        width={600}
        height={300}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis allowDecimals={false} />
        <Tooltip />
        <Legend />
        {toursToDisplay && toursToDisplay.length > 0
          ? toursToDisplay.map((tour, index) => {
              return tour[0] ? (
                <Line
                  key={tour[0].collectionId}
                  dataKey={tour[0].collectionId}
                  stroke={colors[index % 3]}
                  name={tour[0].collection.name?.de}
                />
              ) : (
                ""
              );
            })
          : ""}
      </LineChart>
    </div>
  );
}

import { getFromApi, putToApi, postToApi, deleteToApi } from "./APIService";
import log from "../utils/Logger";

export async function getAccounts() {
  let res = await getFromApi("accounts", {});
  log.debug("get accounts: ", res);
  return res;
}

export async function getAccount(accountid) {
  let res = await getFromApi(`accounts/${accountid}/access`, {});
  log.debug("get account: ", res);
  return res;
}

export async function saveAccount(accountid, role) {
  log.debug("updateAccount with new role", role);
  const newRole = { accountRole: role };
  const res = await putToApi(`accounts/${accountid}`, newRole);
  return res;
}

export async function addAccount(data) {
  log.debug(
    "addAccount...",
    data.username,
    data.password,
    data.passwordConfirm
  );
  const account = {
    username: data.username,
    password: data.password,
    passwordConfirm: data.passwordConfirm,
    accountRole: "0",
  };
  log.debug("account", account);

  const res = await postToApi("accounts", account);
  return res;
}

export async function deleteAccount(accountid) {
  log.debug("deleteAccount ", accountid);
  const res = await deleteToApi(`accounts/${accountid}`, {});
  return res;
}

export async function addAccess(data) {
  log.debug("add access", data);
  const access = {
    accountId: data.accountid,
    accessType: data.accessType,
    collectionId: data.collectionid,
  };
  const res = await postToApi(`accounts/${data.accountid}/access`, access);
  return res;
}

export async function editAccess(data) {
  log.debug("edit access", data);
  const access = {
    accountId: data.accountid,
    accessType: data.selectedOption,
    collectionId: data.collectionid,
  };
  const res = await putToApi(
    `accounts/${data.accountid}/access/${data.accessid}`,
    access
  );
  return res;
}

export async function deleteAccess(accountid, accessid) {
  log.debug("delete access", accountid, accessid);
  const res = await deleteToApi(`accounts/${accountid}/access/${accessid}`, {});
  return res;
}

import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultButton from "../Button/DefaultButton";
import ModalContent, { ModalTitle } from "../Modal/ModalContent";
import log from "../../utils/Logger";
import Loaderfreisicht from "../../utils/Loaderfreisicht";

export default function MapColumnsToDataForm({
  columnHeaders,
  dataFields,
  onSave,
  loading = false,
  customerModules,
}) {
  const { t } = useTranslation();

  const showField = (fieldValue) => {
    if (fieldValue == "tags" && !customerModules?.includes("TAGS")) {
      return false;
    }

    if (
      fieldValue == "geoLocation" &&
      !customerModules?.includes("GEOLOCATION")
    ) {
      return false;
    }

    return true;
  };

  // maps field index to columnHeader index
  const [dataColumnMap, setDataColumnMap] = useState(
    dataFields.map((field, index) => {
      if (!showField(field)) return -1;
      const fieldNameShort = field.toLowerCase().substr(0, 4);
      const fieldNameShort2 =
        fieldNameShort == "arti"
          ? "nstl"
          : fieldNameShort == "titl"
          ? "titel"
          : fieldNameShort == "date"
          ? "dat"
          : fieldNameShort;
      const matchingColumn = columnHeaders.findIndex(
        (col) =>
          col.toLowerCase().includes(fieldNameShort) ||
          col.toLowerCase().includes(fieldNameShort2)
      );
      if (matchingColumn >= 0) return matchingColumn;
      return index;
    })
  );
  log.debug("dataColumnMap ", dataColumnMap);

  const onButtonClicked = async () => {
    onSave(dataColumnMap);
  };

  const setSelectedOption = (value, field) => {
    const newMap = [...dataColumnMap];
    newMap[field] = parseInt(value);
    setDataColumnMap(newMap);
  };

  return (
    <ModalContent>
      <ModalTitle title={t("uploadFormMapFieldsTitle")} />
      <div className="pb-4 w-full text-gray col-span-6">
        {t("uploadFormMapFieldsInfo")}
      </div>
      {dataFields && dataFields.length ? (
        dataFields.map((field, index) => {
          return showField(field) ? (
            <div className="col-span-6" key={index}>
              <div className="col-span-2 col-start-1 self-center text-sm">
                {t("uploadFormField_" + field)}
              </div>
              <div className="col-span-2 col-start-3">
                <select
                  className="text-gray bg-lightGray text-sm border-none"
                  value={dataColumnMap && dataColumnMap[index]}
                  onChange={(event) => {
                    setSelectedOption(event.target.value, index);
                  }}
                >
                  <option value={-1}>-</option>
                  {columnHeaders
                    ? columnHeaders.map((col, index) => {
                        return (
                          <option key={index} value={index}>
                            {col}
                          </option>
                        );
                      })
                    : "-"}
                </select>
              </div>
            </div>
          ) : (
            ""
          );
        })
      ) : (
        <div className="p-4">no data</div>
      )}
      {loading ? (
        <Loaderfreisicht></Loaderfreisicht>
      ) : (
        <div id="mapDataColumn" className="col-span-6">
          <DefaultButton
            text={t("uploadFormMapFieldsConfirm")}
            onButtonClicked={onButtonClicked}
          ></DefaultButton>
        </div>
      )}
    </ModalContent>
  );
}

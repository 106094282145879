import { React } from "react";
import { BiTrash } from "react-icons/bi";

export default function DeleteButton({
  accountAccess = 2,
  title = "Löschen",
  onButtonClicked,
  className = " cursor-pointer text-freisicht transition transform hover:scale-105 hover:text-darkGreen ",
  classAddon = " ",
  disabled = " ",
}) {
  return (
    <>
      {accountAccess > 1 ? (
        <button className="float-left pl-2 md:pl-4 " onClick={onButtonClicked}>
          <div className={className + disabled + classAddon} title={title}>
            <BiTrash></BiTrash>
          </div>
        </button>
      ) : (
        ""
      )}
    </>
  );
}

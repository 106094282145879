import { React } from "react";
import { useParams } from "react-router";
import log from "../utils/Logger";
import { EditBlock } from "./EditBlock";
import { saveCollection } from "../services/CollectionService";

export function ImpressumEditBlock(args) {
  const {
    fieldName,
    parentInfo,
    collection,
    setCollection,
    collectionLanguage,
  } = args;
  const { setChangesSaved } = parentInfo;
  let { id } = useParams();

  async function onChanged(value) {
    try {
      await saveCollection(id, {
        ...collection,
        [fieldName]: { ...collection[fieldName], [collectionLanguage]: value },
      });
      setCollection({
        ...collection,
        [fieldName]: { ...collection[fieldName], [collectionLanguage]: value },
      });

      setChangesSaved("");
    } catch (error) {
      setChangesSaved("Speichern fehlgeschlagen");
      log.error("saveCollectible inputfield error: ", error);
    }
  }

  function fetchData() {
    return collection && collection[fieldName]
      ? collection[fieldName][collectionLanguage]
      : "";
  }

  const data = fetchData();

  return <EditBlock {...args} onChanged={onChanged} data={data} />;
}

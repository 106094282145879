import { React } from "react";

export default function DefaultButton({
  text,
  onButtonClicked,
  className = "flex justify-center transition transform hover:scale-105 bg-darkGreen hover:bg-freisicht text-white font-bold px-4 py-2 cursor-pointer w-full",
  disabled = " ",
}) {
  return (
    <div className="flex justify-center col-span-3 mt-10">
      <div
        className={className + disabled}
        onClick={() => {
          onButtonClicked();
        }}
      >
        {text}
      </div>
    </div>
  );
}

import { React, useState } from "react";
import { useParams } from "react-router";
import log from "../utils/Logger";
import { EditBlock } from "./EditBlock";
import { saveCollectible } from "../services/CollectibleService";

export function ArtworkEditBlock(args) {
  const {
    fieldName,
    parentInfo,
    collectible,
    setCollectible,
    multilangField,
    collectionLanguage,
  } = args;
  const { setChangesSaved } = parentInfo;
  let { id, cid } = useParams();
  const [title, setTitle] = useState(collectible && collectible["title"]);

  function updateCollectible(fieldName, value) {
    if (multilangField) {
      setCollectible({
        ...collectible,
        [fieldName]: { ...collectible[fieldName], [collectionLanguage]: value },
      });
    } else {
      setCollectible({
        ...collectible,
        [fieldName]: value,
      });
    }

    if (fieldName === "title") {
      setTitle(value);
    }
    log.debug("title", title);
  }

  async function onChanged(value) {
    try {
      if (multilangField) {
        await saveCollectible(id, cid, {
          ...collectible,
          [fieldName]: {
            ...collectible[fieldName],
            [collectionLanguage]: value,
          },
        });
      } else {
        await saveCollectible(id, cid, { ...collectible, [fieldName]: value });
      }

      updateCollectible(fieldName, value);

      setChangesSaved("");
    } catch (error) {
      setChangesSaved("Speichern fehlgeschlagen");
      log.error("saveCollectible inputfield error: ", error);
    }
  }

  function fetchData() {
    return collectible && collectible[fieldName]
      ? multilangField
        ? collectible[fieldName][collectionLanguage]
        : collectible[fieldName]
      : "";
  }

  const data = fetchData();

  return (
    <EditBlock
      {...args}
      onChanged={(a) => {
        onChanged(a);
      }}
      data={data}
      classNameAddon={fieldName}
    />
  );
}

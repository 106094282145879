import { React } from "react";

export default function OverlayUploadTabs({ openTab, setOpenTab }) {
  function TabLink(props) {
    const { number, name } = props;

    return (
      <div
        className={
          "text-s px-3 md:px-5 py-3 leading-normal block h-full " +
          (number == openTab
            ? "text-freisicht  bg-white"
            : "text-darkGreen transition hover:text-freisicht")
        }
        onClick={() => {
          setOpenTab(number);
        }}
      >
        {name}
      </div>
    );
  }

  return (
    <div className="flex bg-lightGray flex-wrap col-span-6 font-semibold -mt-10 -mx-6">
      <div className="w-full ">
        <div className="max-w-screen-lg m-auto flex flex-row place-content-between">
          <ul
            className="flex mb-0 list-none  flex-row flex-grow"
            role="tablist"
          >
            <li
              className="-mb-px last:mr-0 w-1/2 cursor-pointer"
              key={"multimedia"}
            >
              <TabLink name={"Multimedia"} number={1} />
            </li>
            <li className="-mb-px last:mr-0 w-1/2 cursor-pointer" key={"text"}>
              <TabLink name={"Text"} number={2} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

import { React } from "react";
import { useParams } from "react-router";
import log from "../utils/Logger";
import { EditBlock } from "./EditBlock";
import { saveCollectionItem } from "../services/CollectibleService";

export function ArtworkOverlayEditBlock(args) {
  const { fieldName, parentInfo, collectible, setCollectible, defaultValue } =
    args;
  const { setChangesSaved } = parentInfo;
  let { id, cid } = useParams();

  async function onChanged(value) {
    try {
      const collectionItem = collectible.collectionItem;
      await saveCollectionItem(id, cid, {
        ...collectionItem,
        [fieldName]: value,
      });
      setCollectible({
        ...collectible,
        collectionItem: { ...collectible.collectionItem, [fieldName]: value },
      });
      setChangesSaved("");
    } catch (error) {
      setChangesSaved("Speichern fehlgeschlagen");
      log.error("saveCollectible inputfield error: ", error);
    }
  }

  function fetchData() {
    let fieldValue =
      collectible &&
      collectible.collectionItem &&
      collectible.collectionItem[fieldName] != null
        ? collectible.collectionItem[fieldName]
        : "";
    if (!fieldValue && defaultValue) {
      fieldValue = defaultValue;
    }
    return fieldValue;
  }

  const data = fetchData();

  return (
    <EditBlock
      {...args}
      classNameAddon="flex gap-3"
      onChanged={onChanged}
      data={data}
    />
  );
}

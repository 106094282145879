/* eslint-disable no-useless-escape */
import { React, useState } from "react";
import log from "../utils/Logger";

export default function InputGeneral({
  placeholder,
  onChange,
  onKeyUp,
  onBlur,
  value = "",
  disabled = false,
  step = "0.1",
  type = "text",
  className = "text-gray placeholder-white bg-lightGray text-sm border-2 border-lightGray",
  classNameAddon,
  required = false,
}) {
  const [errorMessage, setErrorMessage] = useState(undefined);

  const validateUrl = (value) => {
    var res = value.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res !== null || value == "") {
      setErrorMessage("");
    } else {
      setErrorMessage("Keine gültige URL");
    }
  };

  const validateRequired = (value) => {
    if (!value || /^\s*$/.test(value)) {
      setErrorMessage("Dieses Feld darf nicht leer sein");
    } else {
      setErrorMessage("");
    }
  };

  return (
    <>
      <input
        onChange={(event) => {
          onChange && onChange(event.target.value);
          if (type === "url") {
            validateUrl(event.target.value);
          }
        }}
        onKeyUp={(event) => {
          if (event.nativeEvent.keyCode === 13) {
            log.debug("clicked");
            onKeyUp && onKeyUp(event);
          }
          if (required) {
            validateRequired(event.target.value);
          }
        }}
        onBlur={(event) => {
          onBlur && !errorMessage && onBlur(event.target.value);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        type={type}
        step={step}
        disabled={disabled}
        value={value != null ? value : ""}
        placeholder={placeholder}
        className={className + " " + classNameAddon}
      />
      {errorMessage ? (
        <div className="text-xs px-2 text-white bg-red">{errorMessage}</div>
      ) : (
        <div></div>
      )}
    </>
  );
}

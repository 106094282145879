/* eslint-disable no-useless-escape */
import { React, useState } from "react";
import log from "../utils/Logger";

export default function InputMultiline({
  placeholder,
  onChange,
  onKeyUp,
  onBlur,
  value = "",
  disabled = false,
  step = "0.1",
  type = "multiline",
  cols = "50",
  rows = "5",
  maxlength = 250,
  className = "text-gray placeholder-white bg-lightGray text-sm border-2 border-lightGray",
  classNameAddon,
}) {
  const [errorMessage, setErrorMessage] = useState(undefined);

  const validate = (value) => {
    var res = value.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res !== null) {
      setErrorMessage("");
    } else {
      setErrorMessage("Keine gültige URL");
    }
  };

  return (
    <>
      <textarea
        onChange={(event) => {
          onChange && onChange(event.target.value);
          if (type === "url") {
            validate(event.target.value);
          }
        }}
        onKeyUp={(event) => {
          if (event.nativeEvent.keyCode === 13) {
            log.debug("clicked");
            onKeyUp && onKeyUp(event);
          }
        }}
        onBlur={(event) => {
          onBlur && onBlur(event.target.value);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        type={type}
        cols={cols}
        rows={rows}
        maxLength={maxlength}
        step={step}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        className={className + " " + classNameAddon}
      ></textarea>
      {errorMessage ? (
        <div className="text-xs px-2 text-white bg-red">{errorMessage}</div>
      ) : (
        <div></div>
      )}
    </>
  );
}

import { React, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import Tabbar from "../../components/Header/Tabbar";
import DefaultButton from "../../components/Button/DefaultButton";
import { WhitePage } from "../../components/Page/WhitePage";
import { getCollection } from "../../services/CollectionService";
import log from "../../utils/Logger";
import { WorksExhibitionImpressum } from "../../utils/Tabs";
import { ImpressumEditBlock } from "../../components/ImpressumEditBlock";

export default function Impressum({
  loggedInUser,
  translationInfo,
  setTranslationInfo,
}) {
  const { t } = useTranslation();
  let { id } = useParams();
  const [collection, setCollection] = useState(undefined);
  const [accountAccess, setAccountAccess] = useState(undefined);
  const [changesSaved, setChangesSaved] = useState(undefined);

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  async function loadData() {
    setLoading(true);
    const res = await getCollection(id);
    setCollection(res.collection);
    log.debug(res.collection);
    setAccountAccess(res.accountAccess);
    setLoading(false);
    const languagesToShow = translationInfo.languagesAvailable?.filter(
      (l) => res.collection.name[l.code]
    );
    setTranslationInfo({
      ...translationInfo,
      languagesToShow,
    });
  }

  useEffect(() => {
    loadData();
  }, []);

  const modules = loggedInUser.user.user.customerModules;

  const subMenu = (
    <Tabbar
      locationToGo="/projects"
      showSearch={false}
      items={WorksExhibitionImpressum(modules)}
      showMultilang={modules.includes("MULTILANG")}
      translationInfo={translationInfo}
      setTranslationInfo={setTranslationInfo}
    ></Tabbar>
  );

  function fillMetaData() {
    let impressumMeta = document.querySelector(".impressumPrimary");
    let metaText = impressumMeta.querySelector(".ProseMirror");
    fillData(metaText);
  }

  function fillData(impressumMeta) {
    log.debug("fill metadata");
    let metaText = "<p></p>";
    collection.collectible.map((x) => {
      metaText += x?.artist?.[translationInfo.collectionLanguage]
        ? "<p><b>" + x.artist[translationInfo.collectionLanguage] + "</b></p>"
        : "<p><b>ohne Namen</b></p>";
      metaText +=
        "<p><b>" +
        (x?.title?.[translationInfo.collectionLanguage] || "ohne Titel") +
        "</b></p>";
      metaText += x.date[translationInfo.collectionLanguage]
        ? "<p>" + x.date[translationInfo.collectionLanguage] + "</p>"
        : "";
      metaText += x.materialTechnique[translationInfo.collectionLanguage]
        ? "<p>" +
          x.materialTechnique[translationInfo.collectionLanguage] +
          "</p>"
        : "";
      metaText += "<p>" + x.height + " x " + x.width + " cm</p>";
      metaText += x.creditline?.[translationInfo.collectionLanguage]
        ? "<p>" + x.creditline[translationInfo.collectionLanguage] + "</p>"
        : "";
      metaText += x.copyright?.[translationInfo.collectionLanguage]
        ? "<p>" + x.copyright[translationInfo.collectionLanguage] + "</p>"
        : "";
      metaText += "<p></p>";
    });
    impressumMeta.innerHTML += metaText;
    impressumMeta.focus();
  }

  return (
    <WhitePage
      subMenu={subMenu}
      title={
        collection?.name?.[translationInfo.collectionLanguage]
          ? t("artworksProjectname") +
            collection?.name?.[translationInfo.collectionLanguage]
          : "Ohne Namen"
      }
      user={loggedInUser && loggedInUser.user.user}
      changesSaved={changesSaved}
    >
      <div className="grid grid-cols-8">
        <div className="col-span-8">
          <div className="p-4 impressumPrimary">
            {accountAccess &&
              collection &&
              translationInfo.collectionLanguage && (
                <ImpressumEditBlock
                  title="Impressum"
                  fieldName="impressum"
                  type="richtext"
                  tooltip={t("impressumTooltip")}
                  collection={collection}
                  setCollection={setCollection}
                  parentInfo={{ setChangesSaved, accountAccess }}
                  multilangField={true}
                  collectionLanguage={translationInfo.collectionLanguage}
                  languageColorClass={
                    modules.includes("MULTILANG")
                      ? translationInfo.languageColorClass
                      : "bg-lightGray"
                  }
                ></ImpressumEditBlock>
              )}
            {accountAccess > 1 ? (
              <div className="w-50vw px-4">
                <DefaultButton
                  text={t("impressumFillMetadata")}
                  onButtonClicked={fillMetaData}
                ></DefaultButton>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="col-span-3">
          <div className="p-4"></div>
        </div>
      </div>
    </WhitePage>
  );
}

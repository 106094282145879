import React, { useState, useEffect } from "react";
import log from "../../utils/Logger";
import InputGeneral from "../InputText";
import {
  getTags,
  addTag,
  deleteTag,
  editTag,
} from "../../services/SettingsService";
import EditTag from "../Form/EditTagForm";
import TagsLayout from "./TagsLayout";
import { ModalErrorAlertInfo } from "../Modal/ModalContent";
import { calculateNewIndexNumber } from "../../utils/drag";

export default function SettingTags({ customerId, setChangesSaved }) {
  const [tags, setTags] = useState([{ id: 1, name: "bla" }]);
  const [message, setMessage] = useState(false);
  const [acceptData, setAcceptData] = useState(undefined);
  const [newTag, setNewTag] = useState("");
  const [openModal, setOpenModal] = useState(undefined);

  const onUpdate = async (tag, tagName) => {
    let result = null;
    result = await editTag(tag, tagName, customerId);
    log.debug("this is the result from the upload", result);
    loadData && loadData();
  };

  const onSave = async () => {
    if (newTag) {
      let result = null;
      log.debug("tagToAdd", newTag);
      result = await addTag(newTag, customerId);
      log.debug("this is the result from the upload", result);
      loadData && loadData();
      setNewTag("");
    }
  };

  const onDelete = async (tagId) => {
    let result = null;
    log.debug("tagToDelete", tagId);
    result = await deleteTag(tagId, customerId);
    log.debug("this is the result from the upload", result);
    loadData && loadData();
  };

  async function loadData() {
    try {
      const tags = await getTags(customerId);
      setTags(tags);
      setChangesSaved("");
    } catch (error) {
      setChangesSaved("Prüfe deine Internetverbindung.");
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  const modal = (
    <EditTag
      menuOpen={openModal}
      setMenuOpen={setOpenModal}
      tag={openModal}
      updateTag={onUpdate}
    ></EditTag>
  );

  const errorMessageElement = (
    <ModalErrorAlertInfo
      message={message}
      setMessage={setMessage}
      acceptData={acceptData}
      setAcceptData={setAcceptData}
      acceptFunction={async () => {
        setMessage(undefined);
        //await deleteAccount(acceptData);
        setAcceptData(undefined);
        loadData();
      }}
    ></ModalErrorAlertInfo>
  );

  async function onDragEnd(result) {
    log.debug("drag end called, ", result);
    const { destination, source, draggableId } = result;

    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }
    const newTags = Array.from(tags);
    const movedTag = newTags[source.index];
    const newIndexNumber = calculateNewIndexNumber(
      source.index,
      destination.index,
      tags
    );
    movedTag.indexNumber = newIndexNumber;
    newTags.splice(source.index, 1);
    newTags.splice(
      destination.index,
      0,
      tags.find((x) => x.id == draggableId)
    );
    setTags(newTags);
    // persist to DB
    try {
      result = await editTag(movedTag, movedTag.name?.de, customerId);
      log.debug("this is the result from the upload", result);
      setChangesSaved("");
    } catch (error) {
      setChangesSaved("Speichern fehlgeschlagen");
      log.error("saveOverlay inputfield error: ", error);
    }
  }

  return (
    <div className="border-b border-middleGray">
      <div className="m-4 text-darkGreen font-bold text-sm flex flex-row ">
        Tags
      </div>
      <TagsLayout
        openModal={openModal}
        setOpenModal={setOpenModal}
        showError={!!message}
        tags={tags}
        modalElement={modal}
        inputElement={
          <InputGeneral
            value={newTag}
            onChange={setNewTag}
            classNameAddon=" w-full "
          />
        }
        errorMessageElement={errorMessageElement}
        onSave={onSave}
        onDelete={onDelete}
        onDragEnd={onDragEnd}
        classNameAddon=" m-4 "
      />
    </div>
  );
}

import { getFromApi, putToApi, postToApi, deleteToApi } from "./APIService";
import log from "../utils/Logger";

export async function getTags(customerId) {
  let res = await getFromApi(`customer/${customerId}/tags`, {}, false);
  log.debug("get tags: ", res);
  return res;
}

export async function editTag(tag, tagName, customerId) {
  log.debug("updateTag with new name", tagName);
  const newTag = {
    ...tag,
    name: { ...tag.name, de: tagName },
  };
  const res = await putToApi(`customer/${customerId}/tags/${tag.id}`, newTag);
  return res;
}

export async function addTag(tagName, customerId) {
  log.debug("addTag...", tagName);
  const tag = {
    name: tagName,
  };

  const res = await postToApi(`customer/${customerId}/tags`, tag);
  return res;
}

export async function deleteTag(tagId, customerId) {
  log.debug("deleteTag ", tagId);
  const res = await deleteToApi(`customer/${customerId}/tags/${tagId}`, {});
  return res;
}

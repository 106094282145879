import { getFromApi, postToApi, putToApi, deleteToApi } from "./APIService";
import log from "../utils/Logger";

export async function getCollectible(collectionid, collectibleid) {
  log.debug(
    "getCollectible collection and collectible ",
    collectionid,
    collectibleid
  );
  const res = await getFromApi(
    `collections/${collectionid}/collectibles/${collectibleid}?multilang=true`,
    {}
  );
  log.debug("accountrights collectible ", res.accountAccess, res);
  return res;
}

export async function addCollectible(collectionid, collectible) {
  log.debug("addCollectible collection and collectible ", collectionid);
  const res = await postToApi(
    `collections/${collectionid}/collectibles`,
    collectible
  );
  return res;
}

export async function addCollectionItem(collectionid, collectibleid) {
  log.debug("addCollectionItem collection and collectible ", collectionid);
  const res = await postToApi(
    `collections/${collectionid}/collectibles/${collectibleid}/collectionitem`
  );
  return res;
}

export async function saveCollectionItem(
  collectionid,
  collectibleid,
  collectionItem
) {
  const res = await putToApi(
    `collections/${collectionid}/collectibles/${collectibleid}/collectionitem/${collectionItem.id}`,
    collectionItem
  );
  return res;
}

export async function saveCollectible(
  collectionid,
  collectibleid,
  collectible
) {
  log.debug(
    "saveCollectible collection and collectible and data ",
    collectionid,
    collectibleid,
    collectible
  );

  if (collectible.width) {
    collectible.width = parseFloat(collectible.width, 10);
  }
  if (collectible.height) {
    collectible.height = parseFloat(collectible.height, 10);
  }
  if (collectible.depth) {
    collectible.depth = parseFloat(collectible.depth, 10);
  }
  delete collectible.collectionItem;

  const res = await putToApi(
    `collections/${collectionid}/collectibles/${collectibleid}`,
    collectible
  );
  return res;
}

export async function deleteCollectible(collectionid, collectibleid) {
  log.debug(
    "deleteCollectible collection and collectible ",
    collectionid,
    collectibleid
  );
  const res = await deleteToApi(
    `collections/${collectionid}/collectibles/${collectibleid}`,
    {}
  );
  return res;
}

export async function moveOrCopyCollectible(
  collectionid,
  collectibleid,
  destinationid,
  move
) {
  log.debug(
    "move or copy Collectible collection and collectible ",
    collectionid,
    collectibleid
  );
  const res = await getFromApi(
    `collections/${collectionid}/collectibles/${collectibleid}/moveorcopy/${destinationid}${
      !move ? "?makeCopy=true" : ""
    }`,
    {}
  );
  log.debug("moved collectible res", res);
  return res;
}

export async function addCollectibleTag(collectionid, collectibleid, tagid) {
  log.debug(
    "deleteCollectibleTag collection and collectible ",
    collectionid,
    collectibleid
  );
  const res = await postToApi(
    `collections/${collectionid}/collectibles/${collectibleid}/tags`,
    { id: tagid }
  );
  return res;
}

export async function deleteCollectibleTag(
  collectionid,
  collectibleid,
  collectibletagid
) {
  log.debug(
    "deleteCollectibleTag collection and collectible ",
    collectionid,
    collectibleid
  );
  const res = await deleteToApi(
    `collections/${collectionid}/collectibles/${collectibleid}/tags/${collectibletagid}`,
    {}
  );
  return res;
}

export function acceptImgOverlay() {
  return {
    "image/png": [".png"],
    "image/jpeg": [".jpg", ".jpeg"],
    "image/gif": [".gif"],
  };
}
export function acceptImgTrigger() {
  return {
    "image/png": [".png"],
    "image/jpeg": [".jpg", ".jpeg"],
    "image/tiff": [".tif, .tiff"],
  };
}

export function acceptVideo() {
  return {
    "video/*": [".mov", ".mp4", ".webm", ".flv", ".avi", ".mkv"],
  };
}

export function acceptAudio() {
  return {
    "audio/*": [".mp3", ".wav", ".m4a", ".wma"],
  };
}

export function acceptExcel() {
  return {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
  };
}

export function acceptObject3D() {
  return {
    "model/gltf-binary": [".glb"],
  };
}

export function acceptOverlayFiles() {
  const acceptImgOverlayFiles = acceptImgOverlay();
  const acceptVideoOverlayFiles = acceptVideo();
  const acceptAudioOverlayFiles = acceptAudio();
  const acceptObject3DFiles = acceptObject3D();
  return {
    ...acceptImgOverlayFiles,
    ...acceptVideoOverlayFiles,
    ...acceptAudioOverlayFiles,
    ...acceptObject3DFiles,
  };
}

export function getNamesFor({ filesToAccept }) {
  const keys = Object.keys(filesToAccept);
  let filesToAcceptNames = "";
  keys?.map((x) =>
    filesToAccept[x]?.map((y) => {
      filesToAcceptNames = filesToAcceptNames.concat(y, ", ");
    })
  );
  filesToAcceptNames = filesToAcceptNames.slice(
    0,
    filesToAcceptNames.lastIndexOf(",")
  );
  return filesToAcceptNames;
}

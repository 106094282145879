import { getFromApi, postToApi, putToApi, deleteToApi } from "./APIService";
import log from "../utils/Logger";

export async function getOverlays(collectionid, collectibleid) {
  log.debug(
    "getOverlays with collectiond and collectibleid ",
    collectionid,
    collectibleid
  );
  const res = await getFromApi(
    `collections/${collectionid}/collectibles/${collectibleid}/overlays?multilang=true`,
    {}
  );
  log.debug("res ", res);
  return res.overlays;
}

export async function addOverlay(collectionid, collectibleid, overlay) {
  log.debug(
    "addOverlay with collectiond and collectibleid ",
    collectionid,
    collectibleid
  );
  const res = await postToApi(
    `collections/${collectionid}/collectibles/${collectibleid}/overlays`,
    overlay
  );
  log.debug("res ", res);
  return res;
}

export async function saveOverlay(
  collectionid,
  collectibleid,
  overlayid,
  overlay,
  languageId
) {
  log.debug(
    "saveOverlay collection and collectible and data ",
    collectionid,
    collectibleid,
    overlayid,
    overlay
  );
  overlay.fadeinDuration = overlay.fadeinDuration
    ? parseFloat(overlay.fadeinDuration, 10)
    : null;
  overlay.size = overlay.size ? parseFloat(overlay.size, 10) : null;

  const res = await putToApi(
    `collections/${collectionid}/collectibles/${collectibleid}/overlays/${overlayid}${
      languageId ? "?mediaLanguageId=" + languageId : ""
    }`,
    overlay
  );
  return res;
}

export async function deleteOverlay(collectionid, collectibleid, overlayid) {
  log.debug(
    "deleteOverlay collection and collectible and data ",
    collectionid,
    collectibleid,
    overlayid
  );
  const res = await deleteToApi(
    `collections/${collectionid}/collectibles/${collectibleid}/overlays/${overlayid}`,
    {}
  );
  return res;
}

export async function getTriggerQuality(collectibleid) {
  const res = await getFromApi(`quality/${collectibleid}`, {});
  log.debug("quality res ", res);
  return res.quality;
}

export async function addOverlayLink(
  collectionid,
  collectibleid,
  overlayid,
  data
) {
  log.debug("addOverlayLink collection and collectible ", collectionid);
  const res = await postToApi(
    `collections/${collectionid}/collectibles/${collectibleid}/overlays/${overlayid}/link`,
    data
  );
  return res;
}

export async function deleteOverlayLink(
  collectionid,
  collectibleid,
  overlayid,
  linkid
) {
  log.debug(
    "deleteOverlayLink collection and collectible and data ",
    collectionid,
    collectibleid,
    overlayid
  );
  const res = await deleteToApi(
    `collections/${collectionid}/collectibles/${collectibleid}/overlays/${overlayid}/link/${linkid}`,
    {}
  );
  return res;
}

import React from "react";
import { BiInfoCircle } from "react-icons/bi";
import { Tooltip as ReactTooltip } from "react-tooltip";

export default function Tooltip({ subtitle, tooltip, tooltipId }) {
  const classAddon = subtitle ? "" : " pl-2";
  return (
    <div className={"col-span-6 font-bold text-sm text-gray" + classAddon}>
      <ReactTooltip html={true} effect="solid" id={tooltipId} />
      <span
        data-tooltip-html={tooltip}
        data-tooltip-id={tooltip ? tooltipId : undefined}
        data-tooltip-place="top"
      >
        {subtitle + " "}
        {tooltip && (
          <span className="inline-block align-text-top">
            <BiInfoCircle className="inline -top-px relative"></BiInfoCircle>
          </span>
        )}
      </span>
    </div>
  );
}

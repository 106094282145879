import { React, useState } from "react";
import { HexAlphaColorPicker, HexColorInput } from "react-colorful";
import DefaultButton from "../Button/DefaultButton";
import FileUpload from "../Modal/FileUpload";
import { EditBlock } from "../EditBlock";
import Loaderfreisicht from "../../utils/Loaderfreisicht";
import ModalContent, { ModalTitle } from "../Modal/ModalContent";
import {
  acceptAudio,
  acceptImgOverlay,
  acceptObject3D,
  acceptVideo,
} from "../../utils/Fileformats";
import { t } from "i18next";
import Media from "../Image/Media";
import { REACT_APP_API_SERVER } from "../../services/APIService";

export default function ChangeOverlayForm({
  overlay,
  updateOverlay,
  loading = false,
  collectionLanguage,
  multilang,
}) {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [overlaytext, setOverlaytext] = useState(
    overlay?.textOverlay?.[collectionLanguage]?.text
  );
  const [overlayColor, setOverlayColor] = useState(
    overlay?.textOverlay?.[collectionLanguage]?.textColor || "#000000"
  );
  const [overlayBackgroundColor, setOverlayBackgroundColor] = useState(
    overlay?.textOverlay?.[collectionLanguage]?.textBackgroundColor || "#FFFFFF"
  );
  const mediaLanguageId = () => {
    if (collectionLanguage == "de") {
      return 1;
    } else if (collectionLanguage == "en") {
      return 2;
    } else {
      return 3;
    }
  };
  const [changeForAllTranslations, setChangeForAllTranslations] =
    useState(false);

  const onButtonClicked = async () => {
    const formData = new FormData();
    const overlayFile =
      selectedFiles && selectedFiles.length > 0 ? selectedFiles[0] : null;
    if (overlayFile) {
      formData.append("overlayFile", overlayFile);
    }
    if (changeForAllTranslations) {
      formData.append("mediaLanguageId", 0);
    } else {
      formData.append("mediaLanguageId", mediaLanguageId());
    }

    if (overlay.type == 4) {
      const textblob = new Blob([overlaytext], { type: "text/plain" });
      const textfile = new File([textblob], "overlaytext.txt");
      formData.append("overlayFile", textfile);
      // TODO: refactor this so not every new prop has to be added here...
      formData.append("textOverlayColor", overlayColor);
      formData.append("textOverlayBackgroundColor", overlayBackgroundColor);
    }

    formData.append("title", overlay.title);
    formData.append("type", overlay.type);
    updateOverlay(formData);
  };

  const filesToAccept =
    overlay.type == 0
      ? acceptImgOverlay()
      : overlay.type == 1
      ? acceptVideo()
      : overlay.type == 2
      ? acceptAudio()
      : acceptObject3D();

  return (
    <>
      <ModalContent>
        <ModalTitle title={t("overlaysChangeOverlay")} />
        {overlay && overlay.type == 4 ? (
          <>
            <div className="col-span-6">
              <EditBlock
                type="richtext"
                title="Textoverlay"
                parentInfo={{ accountAccess: 3 }}
                data={overlaytext}
                onChanged={setOverlaytext}
              ></EditBlock>
            </div>
            <div className="col-span-3 2xl:col-span-2">
              <div className="text-gray pb-2">
                {t("overlaysChangeOverlayTextColor")}
              </div>
              <HexAlphaColorPicker
                color={overlayColor}
                onChange={setOverlayColor}
              />
              <HexColorInput
                color={overlayColor}
                onChange={setOverlayColor}
                alpha={true}
                prefixed={true}
                className="text-gray placeholder-white bg-lightGray text-sm border-2 border-lightGray mt-2"
              />
            </div>
            <div className="col-span-3 2xl:col-span-2">
              <div className="text-gray pb-2">
                {t("overlaysChangeOverlayTextBackgroundColor")}
              </div>
              <HexAlphaColorPicker
                color={overlayBackgroundColor}
                onChange={setOverlayBackgroundColor}
              />
              <HexColorInput
                color={overlayBackgroundColor}
                onChange={setOverlayBackgroundColor}
                alpha={true}
                prefixed={true}
                className="text-gray placeholder-white bg-lightGray text-sm border-2 border-lightGray mt-2"
              />
            </div>
          </>
        ) : overlay && overlay.id ? (
          <Media
            mediaSource={
              REACT_APP_API_SERVER + overlay?.media?.[collectionLanguage]?.url
            }
            type={overlay.type}
          ></Media>
        ) : (
          ""
        )}
        {overlay && overlay.type != 4 && (
          <FileUpload
            filesToAccept={filesToAccept}
            saveFunction={setSelectedFiles}
            maxFiles={1}
          ></FileUpload>
        )}

        {multilang && (
          <div className="col-span-6">
            <EditBlock
              type="checkbox"
              title={t("overlaysChangeAllTranslations")}
              parentInfo={{ accountAccess: 2 }}
              data={changeForAllTranslations}
              onChanged={setChangeForAllTranslations}
              classNameAddon="flex gap-3"
            ></EditBlock>
          </div>
        )}
      </ModalContent>
      {loading ? (
        <Loaderfreisicht></Loaderfreisicht>
      ) : (
        <DefaultButton
          text={t("artworkChangeImageConfirm")}
          onButtonClicked={onButtonClicked}
        ></DefaultButton>
      )}
    </>
  );
}

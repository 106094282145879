import React from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { BiPlus } from "react-icons/bi";
import Tag from "./Tag";

export default function TagsLayout({
  tags,
  accountAccess,
  inputElement,
  modalElement,
  onSave,
  onDelete,
  onDragEnd,
  showError,
  errorMessageElement,
  setOpenModal,
  openModal,
  hideEditButton = " ",
  classNameAddon = " ",
}) {
  const editTagModal = openModal && modalElement;

  const iconstyle =
    "text-freisicht hover:text-darkGreen transition transform hover:scale-105 cursor-pointer";

  return (
    <>
      <div className={"max-w-full grid " + classNameAddon}>
        <div className="md:flex flex-row">
          <div className="flex flex-row max-w-sm">
            <div className="flex-grow">{inputElement}</div>
            <div className={"flex text-3xl p-1 " + iconstyle}>
              <BiPlus
                onClick={async () => {
                  onSave();
                }}
              ></BiPlus>
            </div>
          </div>
          <div className="max-w-sm md:w-1/2 pt-6 md:pt-0">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="tagDroppableZone">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {tags && tags.length ? (
                      tags.map((tag, index) => {
                        return tags[index] ? (
                          <div key={tag.id}>
                            <Draggable
                              draggableId={tags[index].id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                >
                                  <Tag
                                    hideEditButton={hideEditButton}
                                    key={tag.id}
                                    tag={tag}
                                    onSetopenModal={async () => {
                                      setOpenModal && setOpenModal(tag);
                                    }}
                                    onDelete={async () => {
                                      onDelete && onDelete(tag.id);
                                    }}
                                    accountAccess={accountAccess}
                                    provided={provided}
                                  />
                                </div>
                              )}
                            </Draggable>
                          </div>
                        ) : (
                          ""
                        );
                      })
                    ) : (
                      <div className="pl-6 pt-1">noch keine Tags vorhanden</div>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {editTagModal}
          </div>
        </div>
      </div>
      {showError && errorMessageElement}
    </>
  );
}

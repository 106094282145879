import { React } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Menu({ onClose, data, className, style }) {
  const { t, i18n } = useTranslation();
  const menuClassname = "hover:text-lightGray transition text-white";

  function SimpleLink(props) {
    const { to, name } = props;
    return (
      <Link
        onClick={() => {
          onClose && onClose();
        }}
        className={menuClassname}
        to={to}
      >
        {name}
      </Link>
    );
  }

  function LanguageSelection(props) {
    const { languageOptions } = props;

    return (
      <div className="flex">
        <div className={menuClassname}>{t("menuLanguage")}</div>
        {languageOptions.map((dataEl, index) => {
          const elementClassname =
            dataEl == i18n.language?.slice(0, 2)
              ? " px-2 ml-2 border border-freisicht -mt-0.5 text-freisicht"
              : " px-2 ml-2 cursor-pointer text-white hover:text-freisicht transition";
          return (
            <div
              key={index}
              className={elementClassname}
              onClick={() => {
                i18n.changeLanguage(dataEl);
              }}
            >
              {dataEl}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div
      className={className + " p-4 min-h-60 bg-gray flex flex-col gap-4 z-20"}
      style={style}
    >
      {data.map((dataEl, index) => {
        return dataEl.href ? (
          <SimpleLink to={dataEl.href} name={dataEl.title} key={index} />
        ) : dataEl.languageOptions ? (
          <LanguageSelection
            onLanuageSelected={() => {}}
            languageOptions={dataEl.languageOptions}
            key={index}
          />
        ) : (
          <div
            className={menuClassname}
            onClick={() => {
              dataEl.onClick && dataEl.onClick();
              onClose && onClose();
            }}
            key={index}
          >
            {" "}
            {dataEl.title}
          </div>
        );
      })}
    </div>
  );
}

import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultButton from "../Button/DefaultButton";
import log from "../../utils/Logger";
import ModalContent, { ModalTitle } from "../Modal/ModalContent";
import { getCollections } from "../../services/CollectionService";
import Loaderfreisicht from "../../utils/Loaderfreisicht";

export default function CopyMoveCollectibleForm({
  move = false,
  moveOrCopyCollectible,
  loading = false,
  customerDefaultLanguage,
}) {
  const { t } = useTranslation();
  const [collections, setCollections] = useState(undefined);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [result, setResult] = useState(null);

  async function loadData() {
    setCollections(await getCollections());
  }

  useEffect(() => {
    loadData();
  }, []);

  const onButtonClicked = async () => {
    log.debug("move or copy here, to project: ", selectedOption);
    if (selectedOption != null) {
      const res = await moveOrCopyCollectible(selectedOption, move);
      log.debug("result is: ", res);
      setResult(res);
      loadData();
    }
  };

  return (
    <div>
      <ModalContent>
        <ModalTitle
          title={move ? t("artworksMoveWork") : t("artworksCopyWork")}
        />
        {result ? (
          <div className="col-span-5 col-start-1 text-sm">
            {move ? t("artworkMoveSuccess") : t("artworkCopySuccess")}
            <a
              className="text-darkGreen transition hover:text-freisicht"
              href={selectedOption}
            >
              «
              {
                collections.find((x) => x.id == selectedOption).name[
                  customerDefaultLanguage
                ]
              }
              »
            </a>
            .
          </div>
        ) : (
          <>
            <div className="col-span-2 col-start-2 self-center text-sm">
              {move ? t("artworkMoveTo") : t("artworkCopyTo")}
            </div>
            <div className="col-span-3">
              <select
                className="w-full text-gray bg-lightGray text-sm border-none"
                onChange={(event) => {
                  setSelectedOption(event.target.value);
                }}
              >
                <option>{t("artworksChooseProject")}</option>
                {collections
                  ? collections.map((col) => {
                      return (
                        <option key={col.id} value={col.id}>
                          {col.name[customerDefaultLanguage]}
                        </option>
                      );
                    })
                  : "-"}
              </select>
            </div>
          </>
        )}
      </ModalContent>

      {loading ? (
        <Loaderfreisicht></Loaderfreisicht>
      ) : !result ? (
        <DefaultButton
          text={move ? t("artworksMove") : t("artworksCopy")}
          onButtonClicked={onButtonClicked}
        ></DefaultButton>
      ) : (
        ""
      )}
    </div>
  );
}

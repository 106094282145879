import { React } from "react";
import { useNavigate } from "react-router-dom";

export function ListRow({
  data,
  hasLinkAsFirstElement,
  columnWidths = "1",
  rowIndex,
  visibility,
  dataId,
  listName,
}) {
  const navigate = useNavigate();
  const grayOutElement =
    listName === "projects" && typeof data[0] === "function";
  const rowclasspart = "px-1 py-4 md:p-4 self-center col-span-";

  // const cols = hasLinkAsFirstElement ? data.length - 1 : data.length;
  const rowContent = (
    <div
      className={
        (hasLinkAsFirstElement
          ? "hover:bg-lightGreen cursor-pointer border-lightGray px-2"
          : "") + " border-b border-gray grid grid-cols-12 "
      }
    >
      {data
        .slice(hasLinkAsFirstElement ? 1 : 0, data.length)
        .map((cellData, index) => {
          return window.innerWidth > 640 ? (
            <div className={rowclasspart + columnWidths[index]} key={index}>
              {cellData}
            </div>
          ) : visibility && visibility[index] === "invisible" ? (
            " "
          ) : (
            <div
              className={rowclasspart + (columnWidths[index] + 1)}
              key={index}
            >
              {cellData}
            </div>
          );
        })}
    </div>
  );
  return grayOutElement ? (
    <div
      className="opacity-50"
      onClick={() => {
        data[0](dataId);
      }}
    >
      {rowContent}
    </div>
  ) : hasLinkAsFirstElement ? (
    <div
      onClick={() => {
        if (typeof data[0] === "function") {
          data[0](rowIndex);
        } else {
          navigate(data[0]);
        }
      }}
    >
      {rowContent}
    </div>
  ) : (
    rowContent
  );
}
